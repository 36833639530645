<div class="page page--quaternary long-content">
    <div class="page__inner">

        <app-logo-bg></app-logo-bg>

        <a [routerLink]="['/home/settings']" class="page__back page__back--mobile page__back--mobile-text">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">Paper Checks</h1>
        <div class="page__centered">
            <a id="add_address" [routerLink]="['/home/settings/addresses/add']" class="button snake-btn is-notouched">
                ADD address
                <svg><rect></rect></svg>
            </a>
        </div>
        <div class="page__body">
            <div class="cards" #scrolledEl>
                <div *ngFor="let address of addresses" class="cards__item">
                    <div class="cards__left cards__title" (click)="edit(address)">
                        {{address.address}}
                    </div>
                    <div class="cards__right">
                        <button (click)="edit(address)" class="cards__btn cards__btn--edit js_address_save">
                            <svg width="25" height="25">
                                <use xlink:href="../../../../assets/images/sprite.svg#edit"></use>
                            </svg>
                        </button>
                        <button (click)="askDelete(address)" class="cards__btn cards__btn--del js_address_delete">
                            <svg width="17" height="22">
                                <use xlink:href="../../../../assets/images/sprite.svg#delete"></use>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <a [routerLink]="['/home/settings']" class="button page__back page__back--desktop" >
            Back
            <svg><rect></rect></svg>
        </a>
    </div>
</div>
