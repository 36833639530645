<div class="games-payment"
     [class.is-active]="showPopup && imageLoaded"
     *ngIf="showPopup">

    <img class="find-prize__bg" (load)="onBgLoaded()" src="../../../../../assets/images/scratchOff/game-bg-2.png" alt="">

    <button (click)="onActionBtnClick()"
            class="games-payment__btn"
            [ngClass]="isWelcomePopup() ? 'games-payment__btn--continue' : 'games-payment__btn--500-credits'"></button>



    <div class="games-payment__inner">
        <img class="find-prize__decor find-prize__decor--pig" src="../../../../../assets/images/scratchOff/piggy_2.png" alt="">
        <img class="find-prize__decor find-prize__decor--money" src="../../../../../assets/images/scratchOff/bags_2.png" alt="">

        <div class="rules">
            <h2 class="rules__title">Rules</h2>
            <ol class="rules__list">
                <li class="rules__item" *ngFor="let rule of rules">{{rule}}</li>
            </ol>

            <img *ngIf="isWelcomePopup()" class="rules__qr-code" src="../../../../../assets/images/ebox/qrcode_playebox.eboxenterprises.com.png" alt="eboxenterprises.com QR code">
        </div>

    </div>

</div>
