import * as tslib_1 from "tslib";
import { ElementRef, OnInit, QueryList } from '@angular/core';
import { POPUP_TYPE } from "./mascot-sliders.config";
import { FormBuilder } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { SCRATCH_OFF_POPUP_TYPE } from "../../games/scratch-off/scratch-off-rules/scratch-off-rules.config";
var MascotSlidersComponent = /** @class */ (function () {
    function MascotSlidersComponent(mascotService, scratchOffService, loaderService, gameService, popupComponent, spinnerService, sanitizer, dateService, router, activatedRoute, formBuilder, fb, authenticationService, partnerService, fullScreenService, deviceDetectionService) {
        var _this = this;
        this.mascotService = mascotService;
        this.scratchOffService = scratchOffService;
        this.loaderService = loaderService;
        this.gameService = gameService;
        this.popupComponent = popupComponent;
        this.spinnerService = spinnerService;
        this.sanitizer = sanitizer;
        this.dateService = dateService;
        this.router = router;
        this.activatedRoute = activatedRoute;
        this.formBuilder = formBuilder;
        this.fb = fb;
        this.authenticationService = authenticationService;
        this.partnerService = partnerService;
        this.fullScreenService = fullScreenService;
        this.deviceDetectionService = deviceDetectionService;
        this.showPopup = false;
        this.bodyAdditionalClass = 'is-mascot';
        this.singlePage = false;
        this.popupLoading = false;
        this.POPUP_TYPE = POPUP_TYPE;
        this.showGameFindPrize = false;
        this.showGamePayment = false;
        this.games = [];
        this.slider1 = [];
        this.slider2 = [];
        this.slidesPerView = 2;
        this.slideWidth = 285;
        this.sliderAnimationDuration = 2000;
        this.sliderAnimationFunction = 'cubic-bezier(0.65, 0, 0.35, 1)';
        this.sliderAnimationDurationOnClick = 400;
        this.slideResolution = 1.458;
        this.slideHeightRate = .423;
        this.numOfDigits = 4;
        this.balance = {
            freeRounds: 0,
            win: 0,
            jackpot: 0,
            balance: 0
        };
        this.jackpotDynamicText = new BehaviorSubject(null);
        this.mascotService.balanceUpdated.subscribe(function (val) {
            if (val) {
                _this.getBalance();
            }
        });
        this.jackpotDynamicText.subscribe(function (val) {
            if (_this.text !== undefined && _this.textData !== undefined) {
                setTimeout(function () {
                    _this.text.nativeElement.innerHTML = _this.textData.nativeElement.innerHTML;
                });
            }
        });
    }
    MascotSlidersComponent.prototype.onResize = function (event) {
        var _this = this;
        this.setSlideWidth();
        setTimeout(function () {
            if (_this.newSwiper) {
                _this.newSwiper.update({ perView: _this.slidesPerView, autoplay: false });
            }
            if (_this.newSwiper2) {
                _this.newSwiper2.update({ perView: _this.slidesPerView, autoplay: false });
            }
        });
    };
    MascotSlidersComponent.prototype.onMouseMove = function (event) {
        this.newSwiper.update({ autoplay: false, animationDuration: this.sliderAnimationDurationOnClick });
        this.newSwiper2.update({ autoplay: false, animationDuration: this.sliderAnimationDurationOnClick });
        this.onMouseMove = function () { };
    };
    MascotSlidersComponent.prototype.onOrientationChange = function (event) {
        var _this = this;
        this.setSlideWidth();
        setTimeout(function () {
            if (_this.newSwiper) {
                _this.newSwiper.update({ perView: _this.slidesPerView, autoplay: false });
            }
            if (_this.newSwiper2) {
                _this.newSwiper2.update({ perView: _this.slidesPerView, autoplay: false });
            }
        });
    };
    MascotSlidersComponent.prototype.ngOnInit = function () {
        this.setBodyMascotClass();
        this.initGame();
        this.checkSinglePage();
        this.setAndroidSettings();
    };
    MascotSlidersComponent.prototype.ngOnDestroy = function () {
        this.removeBodyMascotClass();
    };
    MascotSlidersComponent.prototype.setBodyMascotClass = function () {
        var _this = this;
        document.body.classList.add(this.bodyAdditionalClass);
        setTimeout(function () {
            _this.popupComponent.loadPopupImages();
        });
    };
    MascotSlidersComponent.prototype.removeBodyMascotClass = function () {
        document.body.classList.remove(this.bodyAdditionalClass);
    };
    MascotSlidersComponent.prototype.checkSinglePage = function () {
        this.getBalance();
    };
    MascotSlidersComponent.prototype.setJackpot = function (value) {
        this.balance.jackpot = value;
        this.jackpotDynamicText.next(value);
    };
    MascotSlidersComponent.prototype.initGame = function () {
        this.getData();
        this.setSlideWidth();
    };
    MascotSlidersComponent.prototype.setSlideWidth = function () {
        var docElement = document.documentElement;
        // const docElementWidth = document.documentElement.clientWidth;
        // const docElementHeight = document.documentElement.clientHeight;
        // New
        var docElementWidth = window.innerWidth;
        var docElementHeight = window.innerHeight;
        var isPortraitOrientation = docElementWidth < docElementHeight;
        var viewportHeight = isPortraitOrientation ? docElementWidth : docElementHeight;
        var viewportWidth = isPortraitOrientation ? docElementHeight : docElementWidth;
        var slideHeight = viewportHeight * this.slideHeightRate;
        if (slideHeight) {
            this.slideWidth = slideHeight / this.slideResolution;
        }
        this.slidesPerView = viewportWidth / this.slideWidth;
    };
    MascotSlidersComponent.prototype.fullScreen = function () {
        if (!this.isAndroid()) {
            this.fullScreenService.toggleFullScreen();
        }
    };
    MascotSlidersComponent.prototype.detectClickOnGame = function () {
        var _this = this;
        document.querySelectorAll('.mascot-slider__slide').forEach(function (el) {
            el.addEventListener('click', function (e) {
                var id = e.currentTarget.getAttribute('data-id');
                _this.openGame(id);
            });
        });
    };
    MascotSlidersComponent.prototype.getBalance = function () {
        var _this = this;
        if (this.authenticationService.isUserLogged()) {
            this.mascotService.getBalance().subscribe(function (data) {
                _this.setBalance(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain('bordered');
            }, function () {
                _this.loaderService.hide();
            });
        }
    };
    MascotSlidersComponent.prototype.getData = function () {
        var _this = this;
        if (!this.games.length) {
            this.mascotService.getIcons().subscribe(function (data) {
                _this.setData(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain('bordered');
            }, function () {
                _this.loaderService.hide();
            });
        }
    };
    MascotSlidersComponent.prototype.setData = function (data) {
        var _a, _b;
        var _this = this;
        try {
            this.games = data.result;
            var middle = Math.round(this.games.length / 2);
            this.slider1 = this.games.slice(0, middle);
            this.slider2 = this.games.slice(middle);
            (_a = this.slider1).push.apply(_a, tslib_1.__spread(this.slider1));
            (_b = this.slider2).push.apply(_b, tslib_1.__spread(this.slider2));
            this.spinnerData = data.wheel;
            this.setJackpot(data.jackpot);
            setTimeout(function () {
                _this.detectClickOnGame();
            });
        }
        catch (e) {
        }
    };
    MascotSlidersComponent.prototype.setBalance = function (data) {
        if (data && data.status) {
            this.balance.win = data.wins;
            this.balance.balance = data.balance;
            this.balance.freeRounds = data.freerounds;
            this.setJackpot(data.jackpot);
        }
        else {
            if (this.jackpotDynamicText.value === null) {
                this.setJackpot(0);
            }
            //this.popupComponent.showPopupError({text: data.message}, 'btn-ok')
        }
    };
    MascotSlidersComponent.prototype.openGame = function (id) {
        this.popupLoading = true;
        if (id) {
            var checkedGame = this.games.find(function (el) { return +el.id === +id; });
            this.popupType = POPUP_TYPE.play;
            this.popupGameImage = checkedGame.image1600;
            this.popupGameId = checkedGame.id;
        }
        else {
            this.setPayment();
        }
        this.showPopup = true;
    };
    MascotSlidersComponent.prototype.setPayment = function () {
        this.popupType = POPUP_TYPE.buy;
        this.popupLoading = true;
        this.showGamePayment = true;
        this.rulesPopupType = SCRATCH_OFF_POPUP_TYPE.pigRules;
    };
    MascotSlidersComponent.prototype.isPaymentReady = function (value) {
        if (value) {
            this.popupLoading = false;
        }
        else {
            this.showGamePayment = false;
            this.showPopup = false;
        }
    };
    MascotSlidersComponent.prototype.missPayment = function () {
        this.showGame();
    };
    MascotSlidersComponent.prototype.closeGame = function () {
        this.popupGameURL = null;
        this.showPopup = false;
        this.popupGameImage = null;
        this.showGameFindPrize = false;
        this.showGamePayment = false;
        this.mascotService.updateBalance();
    };
    MascotSlidersComponent.prototype.playGame = function (id) {
        var _this = this;
        if (id) {
            this.popupGameImage = null;
            this.popupLoading = true;
            this.gameService.getGameUrl(id).subscribe(function (data) {
                _this.checkSubmitResult(data);
            }, function () {
                _this.loaderService.hideAndShowTryAgain('bordered');
            }, function () {
                _this.loaderService.hide();
            });
        }
        else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }
        // this.popupGameImage = null;
        // this.popupLoading = true;
        // setTimeout(() => {
        //     this.popupGameURL = this.getLink('https://kxys05e7341auuy.mascot.games');
        //     this.popupLoading = false;
        // }, 2000);
    };
    MascotSlidersComponent.prototype.getLink = function (url) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    };
    MascotSlidersComponent.prototype.checkSubmitResult = function (responce) {
        if (responce.status === true) {
            this.popupGameURL = this.getLink(responce.result);
            this.popupLoading = false;
        }
        else if (responce.status === false) {
            this.popupComponent.showPopupError({ text: responce.message }, 'btn-ok');
        }
        else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }
    };
    MascotSlidersComponent.prototype.imageLoaded = function () {
        this.popupLoading = false;
    };
    MascotSlidersComponent.prototype.showGame = function () {
        this.showGamePayment = false;
        this.showGameFindPrize = true;
        // this.popupLoading = false;
        this.popupLoading = true;
    };
    MascotSlidersComponent.prototype.onRulesActionBtnClick = function () {
        if (this.isWelcomeRulesPopup()) {
            this.closeGame();
        }
        else {
            this.showGame();
        }
    };
    MascotSlidersComponent.prototype.isWelcomeRulesPopup = function () {
        return this.rulesPopupType === SCRATCH_OFF_POPUP_TYPE.welcome;
    };
    MascotSlidersComponent.prototype.closeScratchOffGame = function () {
        this.showGamePayment = true;
        this.showGameFindPrize = false;
    };
    MascotSlidersComponent.prototype.logout = function () {
        this.router.navigate(['/home/settings']);
    };
    MascotSlidersComponent.prototype.onLogout = function () {
        this.logout();
    };
    MascotSlidersComponent.prototype.frameClick = function () {
        // console.log('frameClick')
    };
    MascotSlidersComponent.prototype.clearBalance = function () {
        this.balance.freeRounds = 0;
        this.balance.balance = 0;
        this.balance.win = 0;
    };
    MascotSlidersComponent.prototype.isIphone = function () {
        return this.deviceDetectionService.isIphone();
    };
    MascotSlidersComponent.prototype.isIpad = function () {
        return this.deviceDetectionService.isIpad();
    };
    MascotSlidersComponent.prototype.isAndroid = function () {
        return this.deviceDetectionService.isAndroid();
    };
    MascotSlidersComponent.prototype.setAndroidSettings = function () {
        if (this.isAndroid()) {
            document.addEventListener('click', this.fullScreenService.setFullScreen);
        }
    };
    MascotSlidersComponent.prototype.goToPreviousSlide = function (slider) {
        slider.go('<');
    };
    MascotSlidersComponent.prototype.goToNextSlide = function (slider) {
        slider.go('>');
    };
    return MascotSlidersComponent;
}());
export { MascotSlidersComponent };
