import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateService {
    days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

    constructor() {
    }

    getWeekDayName(date?) {
        let d;
        if (date) {
            d = new Date(date);
        } else {
            d = new Date();
        }
        return  this.days[d.getDay()];
    }

    getDateSafariSupport(date) {
        if (date) {
            return date.replace(/\s/g, 'T');
        } else {
            return '';
        }
    }
}
