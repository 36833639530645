import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class NftLevelsService {
    constructor() {
    }

    groupLevels(levels) {
        let nftItems = [];
        levels.forEach(item => {
            nftItems.push({level: item.level, type: `Level ${item.level}`, price: item.price, subtotal: item.price * item.qty, quantity: item.qty});
        });
        nftItems.sort((a, b) => a.level - b.level);
        return nftItems;
    }

}
