import {Component, OnInit, ViewChild} from '@angular/core';
import {slideToLeft, slideToRight} from "../../animations/router.animations";
import {StylingService} from "../../service/styling.service";
import {InvestmentsService} from "../../service/investments/investments.service";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {ActivatedRoute, Route} from "@angular/router";
import {NFTService} from "../../service/nft/nft.service";
import {CopyToBufferService} from "../../service/dom/copyToBuffer.service";
import {NgxGlideComponent} from "ngx-glide";
import {NFT_POPUP_TYPE} from "./investments-nft-popup/investments-nft-popup.config";

@Component({
    selector: 'app-investments',
    templateUrl: './investments.component.html',
    styleUrls: ['./investments.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': ''} : {'[@slideToLeft]': ''}
})
export class InvestmentsComponent implements OnInit {
    nftData: {wallet: string, qrCode: string, contractAddress: string, library: any, items?: any} = {
        wallet: '',
        qrCode: '',
        contractAddress: '',
        library: []
    };

    nftResponseKeys = {
        wallet: 'wallet',
        qrCode: 'walletQrcodeLink',
        contractAddress: 'contractAddress',
        library: 'library'
    };

    showNFTPopup = false;
    NFTPopupData;
    NFTLibraryMaxSlides = 3;

    @ViewChild('librarySlider', {static: false}) librarySlider: NgxGlideComponent;

    constructor(private stylingService: StylingService,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private copyToBufferService: CopyToBufferService,
                private activateRoute: ActivatedRoute,
                private investmentsService: InvestmentsService,
                private NFTService: NFTService) {
    }

    ngOnInit() {
        this.getData();
        this.handleLibrarySlidesClick();
    }

    handleLibrarySlidesClick() {
        document.body.addEventListener( 'click', ( event ) => {
            if ((event.target as HTMLElement).classList.contains('js-library-slide')) {
                const id = (<HTMLElement>event.target).getAttribute('data-id');
                const slideInfo = this.nftData.items.find(el => el.uuid === id);

                if (slideInfo) {
                    this.onShowNFTPopup(slideInfo);
                }
            }
        } );
    }

    getData() {
        this.investmentsService.getInfo().subscribe(data => {
          this.setInfo(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setInfo(data) {
        try {
            this.setNFTData(data);
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    setNFTData(data) {
        if (data.nft) {
            const nftData = data.nft;
            this.nftData.wallet = nftData[this.nftResponseKeys.wallet] ? nftData[this.nftResponseKeys.wallet] : '';
            this.nftData.qrCode = nftData[this.nftResponseKeys.qrCode] ? nftData[this.nftResponseKeys.qrCode] : '';
            this.nftData.contractAddress = nftData[this.nftResponseKeys.contractAddress] ? nftData[this.nftResponseKeys.contractAddress] : '';

            this.nftData.library = [];
            setTimeout(() => {
                this.nftData.items = nftData[this.nftResponseKeys.library] ? nftData[this.nftResponseKeys.library] : [];

                this.nftData.library = [];
                this.nftData.items.forEach((img) => {
                    const currentLevel = this.nftData.library.find(nft => nft.level === img.level);
                    if (currentLevel) {
                      currentLevel.items.push(img);
                    } else {
                      this.nftData.library.push({level: img.level, type: `Level ${img.level}`, items: [img]});
                    }

                });
                this.nftData.library.sort((a,b) => a.level - b.level);
            });
        }
    }

    onPopupNFTClose(needReload) {
        this.showNFTPopup = false;
        if (needReload) {
            this.getData();
        }
    }

    generateWallet() {
        this.NFTService.generateWallet().subscribe(data => {
            this.updateWallet(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    updateWallet(data) {
        try {
            if (data.status === true) {
                const result = {
                    nft: {
                        [this.nftResponseKeys.wallet]: data[this.nftResponseKeys.wallet],
                        [this.nftResponseKeys.qrCode]: data[this.nftResponseKeys.qrCode],
                        [this.nftResponseKeys.contractAddress]: data[this.nftResponseKeys.contractAddress]
                    }
                };
                this.setNFTData(result);
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    copyToBuffer() {
        const value = this.nftData.wallet;
        const message = 'Wallet address has been successfully copied!';

        this.copyToBufferService.copyToBuffer(value, true, message);
    }

    onShowNFTPopup(slide) {
        this.showNFTPopup = true;
        this.NFTPopupData = {
            type: NFT_POPUP_TYPE.info,
            data: {
                element: slide
            }
        }
    }
}
