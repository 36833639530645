import {Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MascotService} from "../../../service/mascot/mascot.service";
import {LoaderService} from "../../../service/loader.service";
import {GameService} from "../../../service/games/gameService.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {DomSanitizer} from "@angular/platform-browser";
import {FullScreenService} from "../../../service/fullScreen.service";
import {POPUP_TYPE} from "./mascot-card.config";
import {SpinnerService} from "../../../service/games/spinner.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";
import {BehaviorSubject} from "rxjs";
import {PartnerService} from "../../../service/partner.service";
import {DeviceDetectionService} from "../../../service/deviceDetection.service";

@Component({
    selector: 'app-mascot-card',
    templateUrl: './mascot-card.component.html',
    styleUrls: ['./mascot-card.component.scss']
})
export class MascotCardComponent implements OnInit {
    showPopup = false;
    bodyAdditionalClass = 'is-mascot';

    popupGameImage;
    popupGameId;
    popupGameURL;
    popupType: number;
    popupLoading = false;
    POPUP_TYPE = POPUP_TYPE;

    showGameFindPrize = false;
    showGamePayment = false;
    rulesPopupType: string;

    games = [];

    spinnerData;
    balance = {
        freeRounds: 0,
        win: 0,
        jackpot: 0,
        balance: 0
    };

    jackpotDynamicText = new BehaviorSubject(null);

    @ViewChild('textData', {static: false}) textData: ElementRef;
    @ViewChild('text', {static: false}) text: ElementRef;

    constructor(
        private mascotService: MascotService,
        private loaderService: LoaderService,
        private gameService: GameService,
        private popupComponent: PopupComponent,
        private spinnerService: SpinnerService,
        public sanitizer: DomSanitizer,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private partnerService: PartnerService,
        private fullScreenService: FullScreenService,
        private deviceDetectionService: DeviceDetectionService,
    ) {

        this.mascotService.balanceUpdated.subscribe(val => {
           if (val) {
               this.getBalance();
           }
        });

        this.jackpotDynamicText.subscribe(val => {
            if (this.text !== undefined && this.textData !== undefined) {
                setTimeout(() => {
                    this.text.nativeElement.innerHTML = this.textData.nativeElement.innerHTML;
                });
            }
        });
    }


    ngOnInit() {
        this.setBodyMascotClass();

        this.initGame();
        this.getBalance();

        this.setAndroidSettings();
    }

    ngOnDestroy(){
        this.removeBodyMascotClass();
    }

    setBodyMascotClass() {
        document.body.classList.add(this.bodyAdditionalClass);
        setTimeout(() => {
            this.popupComponent.loadPopupImages();
        });
    }

    removeBodyMascotClass() {
        document.body.classList.remove(this.bodyAdditionalClass);
    }

    setJackpot(value) {
        this.balance.jackpot = value;
        this.jackpotDynamicText.next(value);
    }

    initGame() {
        this.getData();
    }

    fullScreen() {
        if (!this.isAndroid()) {
            this.fullScreenService.toggleFullScreen();
        }
    }

    getBalance() {
        this.mascotService.getBalance().subscribe(data => {
            this.setBalance(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain('bordered');
        }, () => {
            this.loaderService.hide();
        });
    }

    getData() {
        if (!this.games.length) {
            this.mascotService.getIcons().subscribe(data => {
                this.setData(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain('bordered');
            }, () => {
                this.loaderService.hide();
            });
        }

    }

    setData(data) {
        try {
            this.games = data.result;
            this.spinnerData = data.wheel;

            this.setJackpot(data.jackpot);
        } catch (e) {}
    }

    setBalance(data) {
        if (data && data.status) {
            this.balance.win = data.wins;
            this.balance.balance = data.balance;
            this.balance.freeRounds = data.freerounds;
            this.setJackpot(data.jackpot);
        } else {
            if (this.jackpotDynamicText.value === null) {
                this.setJackpot(0);
            }
        }
    }

    openGame(id?) {
        if (id) {
            this.popupLoading = true;
            const checkedGame = this.games.find(el => +el.id === +id);
            this.popupType = POPUP_TYPE.play;
            this.popupGameImage = checkedGame.image1600;
            this.popupGameId = checkedGame.id;
            this.showPopup = true;
        } else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }
    }

    closeGame() {
        this.popupGameURL = null;
        this.showPopup = false;
        this.popupGameImage = null;

        this.mascotService.updateBalance();
    }

    playGame(id) {
        if (id) {
            this.popupGameImage = null;
            this.popupLoading = true;
            this.gameService.getGameUrl(id).subscribe(data => {
                this.checkSubmitResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain('bordered');
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }

        // this.popupGameImage = null;
        // this.popupLoading = true;
        // setTimeout(() => {
        //     this.popupGameURL = this.getLink('https://kxys05e7341auuy.mascot.games');
        //     this.popupLoading = false;
        // }, 2000);
    }

    getLink(url){
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    checkSubmitResult(responce) {
        if(responce.status === true){
            this.popupGameURL = this.getLink(responce.result);
            this.popupLoading = false;
        }else if (responce.status === false) {
            this.popupComponent.showPopupError({text: responce.message}, 'btn-ok');
        } else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }
    }

    imageLoaded() {
        this.popupLoading = false;
    }

    touchCapable() {
        return (
          'ontouchstart' in window ||
          ((window as any).DocumentTouch &&
            document instanceof (window as any).DocumentTouch) ||
          navigator.maxTouchPoints > 0 ||
          (window as any).navigator.msMaxTouchPoints > 0
        );
    }

    onLogout() {
        this.router.navigate(['/home/settings']);
    }

    clearBalance() {
        this.balance.freeRounds = 0;
        this.balance.balance = 0;
        this.balance.win = 0;
    }

    isIphone() {
        return this.deviceDetectionService.isIphone();
    }

    isIpad() {
        return this.deviceDetectionService.isIpad();
    }

    isAndroid() {
        return this.deviceDetectionService.isAndroid();
    }

    setAndroidSettings() {
        if (this.isAndroid()) {
            document.addEventListener('click', this.fullScreenService.setFullScreen);
        }
    }
}
