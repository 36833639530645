import * as i0 from "@angular/core";
var NftLevelsService = /** @class */ (function () {
    function NftLevelsService() {
    }
    NftLevelsService.prototype.groupLevels = function (levels) {
        var nftItems = [];
        levels.forEach(function (item) {
            nftItems.push({ level: item.level, type: "Level " + item.level, price: item.price, subtotal: item.price * item.qty, quantity: item.qty });
        });
        nftItems.sort(function (a, b) { return a.level - b.level; });
        return nftItems;
    };
    NftLevelsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NftLevelsService_Factory() { return new NftLevelsService(); }, token: NftLevelsService, providedIn: "root" });
    return NftLevelsService;
}());
export { NftLevelsService };
