import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {MainComponent} from './main/main.component';
import {LoginComponent} from "./login/login.component";
import {AuthGuard} from '../modules/auth/_guards/auth.guard';
import {RewardsComponent} from "./rewards/rewards.component";
import {PrizesComponent} from "./prizes/prizes.component";
import {SettingsComponent} from "./settings/settings.component";
import {CardComponent} from "./settings/card/card.component";
import {NewCardComponent} from "./settings/card/new-card/new-card.component";
import {CardMenuComponent} from "./settings/card/card-menu/card-menu.component";
import {EditCardComponent} from "./settings/card/edit-card/edit-card.component";
import {VerifyCardComponent} from "./settings/card/verify-card/verify-card.component";
import {AddressesSettingsComponent} from "./settings/addresses-settings/addresses-settings.component";
import {AddAddressComponent} from "./settings/addresses-settings/add-address/add-address.component";
import {FundsComponent} from "./settings/funds/funds.component";
import {RewardsFormComponent} from "./rewards/rewards-form/rewards-form.component";
import {ContactInfoComponent} from "./settings/contact-info/contact-info.component";
import {InvestmentsComponent} from "./investments/investments.component";
import {EditAddressComponent} from "./settings/addresses-settings/edit-address/edit-address.component";
import {TermsAndConditionsComponent} from "./terms-and-conditions/terms-and-conditions.component";
import {MascotSlidersComponent} from "./gamesProviders/mascot-sliders/mascot-sliders.component";
import {MascotCardComponent} from "./gamesProviders/mascot-card/mascot-card.component";
import {HelpSettingsComponent} from "./settings/help-settings/help-settings.component";
import {TransactionProcessingComponent} from "./transactions/transaction-processing/transaction-processing.component";
import {PaypalPaymentsSuccessComponent} from "./paypal/payments/success/paypal-payments-success.component";
import {PaypalPaymentsUnsuccessComponent} from "./paypal/payments/unsuccess/paypal-payments-unsuccess.component";

const routes: Routes = [
    {
        path: '', component: MainComponent, children: [
            {path: '', component: LoginComponent},
            {path: 'login', component: LoginComponent},
            {path: 'mascot', canActivate: [AuthGuard], component: MascotSlidersComponent},
            {path: 'mascot-card', canActivate: [AuthGuard], component: MascotCardComponent},
            {path: 'paypal/execute/success', component: PaypalPaymentsSuccessComponent},
            {path: 'paypal/execute/unsuccess', component: PaypalPaymentsUnsuccessComponent},
            {path: 'threedsecure', component: TransactionProcessingComponent},
            {path: 'home', component: HomeComponent, canActivate: [AuthGuard], children: [
            // {path: 'home', component: HomeComponent, children:
                    {path: 'terms-and-conditions', component: TermsAndConditionsComponent},
                    {path: 'rewards', component: RewardsComponent},
                    {path: 'rewards/form', component: RewardsFormComponent},
                    {path: 'prizes', component: PrizesComponent},
                    {path: 'investments', component: InvestmentsComponent},
                    {path: 'settings', component: SettingsComponent},
                    {path: 'settings/help', component: HelpSettingsComponent},
                    {path: 'settings/card', component: CardComponent},
                    {path: 'settings/cashapp', component: CardComponent},
                    {path: 'settings/prepaid', component: CardComponent},
                    {path: 'settings/card/new-card', component: NewCardComponent},
                    {path: 'settings/cashapp/new-card', component: NewCardComponent},
                    {path: 'settings/prepaid/new-card', component: NewCardComponent},
                    {path: 'settings/card/card-menu/:id', component: CardMenuComponent},
                    {path: 'settings/cashapp/card-menu/:id', component: CardMenuComponent},
                    {path: 'settings/prepaid/card-menu/:id', component: CardMenuComponent},
                    {path: 'settings/card/edit/:id', component: EditCardComponent},
                    {path: 'settings/cashapp/edit/:id', component: EditCardComponent},
                    {path: 'settings/prepaid/edit/:id', component: EditCardComponent},
                    {path: 'settings/cashapp/verify/:id', component: VerifyCardComponent},
                    {path: 'settings/card/verify/:id', component: VerifyCardComponent},
                    {path: 'settings/addresses', component: AddressesSettingsComponent},
                    {path: 'settings/addresses/add', component: AddAddressComponent},
                    {path: 'settings/addresses/edit/:id', component: EditAddressComponent},
                    {path: 'settings/funds', component: FundsComponent},
                    {path: 'settings/contacts', component: ContactInfoComponent},
                ]},

            {path: '**', redirectTo: 'login', pathMatch: 'full'}
        ]
    }

];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesModule {
}
