import {Component, HostListener, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from "@angular/router";
import {PhoneService} from "./service/phone.service";
import {InstallService} from "./service/install.service";
import {PrizesService} from "./service/prizes/prizes.service";
import {ConnectionService} from "ng-connection-service";
import {PopupComponent} from "./shared/popup/popup.component";
import {AuthenticationService} from "./modules/auth/_services/authentication.service";
import {PROJECT_CONFIG} from "./config/project";
import {AppVersionrService} from "./service/app-version/appVersionr.service";
import {UsersPointService} from "./service/users/users-point.service";
import {AppTestService} from "./service/app/appTest.service";
import {AppPromptService} from "./service/app/appPrompt.service";
import {DOCUMENT} from "@angular/common";
import {HeroService} from "./service/hero.service";
import {FrontendTestSettingsService} from "./service/testing/frontendTestSettings.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    canListen = false;
    deferredPrompt: any;

    userLoggedStatus = false;

    noInternetConnection = false;
    isConnected = true;

    PROJECT_CONFIG = PROJECT_CONFIG;

    @HostListener('click', ['$event'])
    clickInside(event) {
        if  (this.canListen) {
            const snakeBtns = document.querySelectorAll('.is-notouched');
            snakeBtns.forEach(el => {
                el.classList.remove('is-notouched');
            });
        };
    }

    @HostListener('window:beforeinstallprompt', ['$event'])
    onbeforeinstallprompt(e) {
        e.preventDefault();
        // store install avaliable event
        this.deferredPrompt = e;

        // show button with display:block;
        this.appPromptService.deferredPrompt = this.deferredPrompt;
    }

    constructor(private router: Router,
                private phoneService:PhoneService,
                private appVersionrService:AppVersionrService,
                private installService: InstallService,
                private prizesService: PrizesService,
                private connectionService: ConnectionService,
                private popupComponent: PopupComponent,
                private auth:AuthenticationService,
                private activatedRoute: ActivatedRoute,
                private usersPointService: UsersPointService,
                private appTestService: AppTestService,
                private appPromptService: AppPromptService,
                private heroService: HeroService,
                private frontendTestSettingsService: FrontendTestSettingsService,
                @Inject(DOCUMENT) private document: Document) {

        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                //console.log('Navigation Start')
                this.canListen = false;
            }

            if (event instanceof NavigationEnd) {
                //console.log('Navigation End')
                if (event.url.indexOf('/home/prizes/paypal')) {
                    this.prizesService.temporaryEmail.next('');
                }

                if (event.url.indexOf('/home/prizes/mail-check')) {
                    this.prizesService.temporaryAddress.next('');
                }

                const snakeBtns = document.querySelectorAll('.snake-btn');
                snakeBtns.forEach(el => {
                    (<HTMLElement>el).classList.add('is-notouched');
                });

                setTimeout(()  => {
                    this.canListen = true;
                }, 0);

                this.appHeight();
            }
        });

        if (this.frontendTestSettingsService.isHideForTesting()) {
            this.networkResponse();
        }
    }

    networkResponse(){
        this.connectionService.monitor().subscribe(isConnected => {
            this.isConnected = isConnected;
            if (this.isConnected) {
                this.noInternetConnection = false;
                this.popupComponent.closePopup();
            } else {
                this.noNetwork();
            }
        });
    }

    networkStatus = true;

    noNetwork(){
        if(this.networkStatus) {
            this.networkStatus = false;

            this.noInternetConnection = true;
            this.popupComponent.showPopupError({text: 'No Internet Connection: please, try to connect to the Internet', closeBtn: false});
            this.auth.logout();
        }
    }

    userLogged(){
        this.auth.userLoggedStatusChanged.subscribe(val => {
            if(this.userLoggedStatus !== val && val === false){
                this.userLoggedStatus = val;
                this.router.navigate(['/']);
            }else{
                this.userLoggedStatus = val;
            }
        });
    }

    ngOnInit() {
        this.userLogged();

        if (this.heroService.isCryptoVersion()) {
            this.document.body.classList.add('v-crypto');
        }

        this.phoneService.init();

        this.setPointHash();
        this.setWebTest();

        this.activatedRoute.queryParams.subscribe(params => {
            this.detectBrowser();
        });

        this.refreshToken();

        this.appVersion();

        this.visibilitychange();

        window.addEventListener('resize', this.appHeight);
        window.addEventListener('orientationchange', this.appHeight);
        this.appHeight();
    }

    ngOnDestroy() {}


    visibilitychange(){
        this.auth.setStamp();

        document.addEventListener('visibilitychange', () => {
            if (document.hidden == false){
                this.auth.checkStamp();
            } else {

            }
        });
    }

    setPointHash(){
        this.activatedRoute.queryParams.subscribe(params => {
            try {
                if(params['point']){
                    if(params['point'] != ''){
                        this.usersPointService.saveHashInStorage(params['point']);
                    }
                }
            }catch (e) {

            }
        });
    }

    setWebTest(){
        this.activatedRoute.queryParams.subscribe(params => {
            try {
                if(params['iswebtest']){
                    if(params['iswebtest'] != ''){
                        this.appTestService.saveInStorage(params['iswebtest']);
                    }
                }else{
                    this.appTestService.clearStorage();
                }
            }catch (e) {

            }
        });
    }

    appVersion(){
        this.appVersionrService.checkVersion();
    }

    refreshToken(){
        let $this = this;
        setTimeout(function(){
            $this.auth.refreshToken();
        },60*5*1000);
    }

    detectBrowser() {
        const isLoginPage = location.pathname === '/' || location.pathname === '';

        if (window.matchMedia('(display-mode: standalone)').matches || window.innerWidth >= 600 || !isLoginPage) {
            this.installService.appInstalled.next(true);
            this.installService.hide();
        } else {
            this.installService.show();
        }
    }

    appHeight() {
        setTimeout(() => {
            const doc = document.documentElement;
            doc.style.setProperty('--app-height', `${window.innerHeight}px`);
            doc.style.setProperty('--app-width', `${window.innerWidth}px`);
        }, 0);
    }
}
