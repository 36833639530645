export const DOMAIN_DEMO_NAME = 'app.dev.mynftking.com';
export const DOMAIN_DEMO_NAME_API = 'api.dev.eboxenterprises.com';
export const DOMAIN_DEMO_API = 'https://'+DOMAIN_DEMO_NAME_API;

export const DOMAIN_INTERIM_NAME = 'user.studionovi.com';
export const DOMAIN_INTERIM_NAME_MASCOT = 'user.mascot.studionovi.com';
export const DOMAIN_INTERIM_NAME_MASCOT1 = 'user-mascot.studionovi.com';
export const DOMAIN_INTERIM_NAME_TERMINAL = 'terminal.studionovi.com';
export const DOMAIN_INTERIM_NAME_API = 'api.studionovi.com';
export const DOMAIN_INTERIM_API = 'https://'+DOMAIN_INTERIM_NAME_API;
