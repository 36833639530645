<div class="mascot" [class.is-iphone]="isIphone()" [class.is-disabled]="showPopup" [class.is-station-mascot]="isStationMascot" [class.is-blurred]="showPopup" (dblclick)="fullScreen()">
    <div class="mascot-slider">
        <ngx-glide data-glide-el="track" [autoplay]="true" [hoverpause]="false" [animationDuration]="sliderAnimationDuration" [animationTimingFunc]="sliderAnimationFunction" [focusAt]="5" [startAt]="5" #newSwiper *ngIf="slider1.length" type="carousel" [perView]="slidesPerView" [showArrows]="false" [showBullets]="false" [gap]="0">
            <div [attr.data-id]="slide.id" *ngFor="let slide of slider1" class="slide mascot-slider__slide">
                <img class="slide mascot-slider__img" src="{{ slide.image360 }}" alt="" width="100%">
            </div>
        </ngx-glide>

        <div class="mascot-slider__btns">
            <button class="mascot-slider__btn mascot-slider__btn--left" (click)="goToPreviousSlide(newSwiper)">
                <img src="./assets/images/mascot/arrows_left.png" title="Previous Slide" alt="Previous Slide">
            </button>
            <button class="mascot-slider__btn mascot-slider__btn--right" (click)="goToNextSlide(newSwiper)">
                <img src="./assets/images/mascot/arrows_right.png" title="Next Slide" alt="Next Slide">
            </button>
        </div>
    </div>

    <div class="mascot-slider">
        <ngx-glide #newSwiper2 *ngIf="slider2.length" [hoverpause]="false" [autoplay]="true" [direction]="'rtl'" [animationDuration]="sliderAnimationDuration" [animationTimingFunc]="sliderAnimationFunction" [focusAt]="5" [startAt]="5" type="carousel" [perView]="slidesPerView" [showArrows]="false" [showBullets]="false" [gap]="0">
            <div [attr.data-id]="slide.id" *ngFor="let slide of slider2" class="slide mascot-slider__slide mascot-slider__slide--transform">
                <img class="slide mascot-slider__img" src="{{ slide.image360 }}" alt="" width="100%">
            </div>
        </ngx-glide>

        <div class="mascot-slider__btns">
            <button class="mascot-slider__btn mascot-slider__btn--right" (click)="goToPreviousSlide(newSwiper2)">
                <img src="./assets/images/mascot/arrows_right.png" title="Next Slide" alt="Next Slide">
            </button>
        </div>
    </div>


<!--    <img class="mascot__aphrodite" src="./assets/images/mascot-slider/aphrodite.png" alt="">-->

    <img class="mascot__coins mascot__coins--left" src="./assets/images/mascot-slider/coins-left.png" alt="">
<!--    <img class="mascot__coins mascot__coins&#45;&#45;center" src="./assets/images/mascot-slider/coins-center.png" alt="">-->
    <img class="mascot__coins mascot__coins--right" src="./assets/images/mascot-slider/coins-right.png" alt="">
    <img class="mascot__coins-bottom" src="./assets/images/mascot-slider/cents.png" alt="">

<!--    <img (click)="openGame()" class="mascot__text-img" src="./assets/images/mascot-slider/text.png" alt="">-->
    <button (click)="openGame()" class="mascot__scratch-off-btn">
        <img src="./assets/images/mascot-slider/piggy_icon.png" alt="">
    </button>

    <div class="balance-wrapper balance-wrapper--rounds">
        {{balance.freeRounds | number: '.2'}}
    </div>

    <div class="balance-wrapper balance-wrapper--win">
        {{balance.win | number: '.2'}}
    </div>

    <div class="balance-wrapper balance-wrapper--balance">
        {{balance.balance | number: '.2'}}
    </div>

    <div class="balance-wrapper balance-wrapper--jackpot">
        <span style="visibility: hidden" #textData>{{balance.jackpot | number: '.2'}}</span>

        <svg _ngcontent-trq-c4="" viewBox="0 0 70 70" width="100%">
            <path _ngcontent-trq-c4="" d="M 15,35m-35,60a23,23 0 1,1 110,0a23,23 0 1,1 -46,0" fill="transparent" id="tophalf"></path>
            <text _ngcontent-trq-c4="" style="text-align: center; font-size: 6px;" width="100%" fill="#f7c837">
                <textPath #text _ngcontent-trq-c4="" xlink:href="#tophalf" text-anchor="middle" startOffset="35.5%"></textPath>
            </text>
        </svg>



    </div>

    <button (click)="onLogout()" class="mascot__btn-settings">
        <img class="mascot__img-settings" src="./assets/images/mascot-slider/logout.png" alt="">
    </button>


    <!--    <img class="mascot__spinner" src="./assets/images/mascot-slider/spinner-img.png" alt="">-->
    <app-spinner [data]="spinnerData"></app-spinner>
</div>

<div [class.is-visible]='showPopup'
     [class.is-iphone]="isIphone()"
     [class.is-ipad]="isIpad()"
     [class.is-ipad-iframe]="isIpad() && popupGameURL"
     *ngIf="showPopup"
     class="mascot-popup"
     [ngStyle]="{backgroundImage: popupType === POPUP_TYPE.play ? 'none' : 'url(' + popupGameImage + ')'}">
    <button (click)="closeGame()"
            class="mascot-popup__close"></button>
    <button (click)="playGame(popupGameId)"
            *ngIf="!popupGameURL && popupType === POPUP_TYPE.play"
            class="mascot-popup__btn mascot-popup__btn--play"></button>

    <img *ngIf="popupGameImage" class="mascot-popup__img" [src]="popupGameImage" (load)="imageLoaded()" alt="">
    <iframe *ngIf="popupGameURL" (mousemove)="frameClick()" width="100%" height="100%" [src]="popupGameURL" frameborder="0"></iframe>

    <app-pig [showPopup]="showGameFindPrize"
             *ngIf="showGameFindPrize"
             (isElementReady)="isPaymentReady($event)"
             (close)="closeScratchOffGame()"></app-pig>

    <app-scratch-off-rules
            (isPaymentForbidden)="missPayment()"
            (isElementReady)="isPaymentReady($event)"
            (isPaymentSuccess)="onRulesActionBtnClick()"
            [popupType]="rulesPopupType"
            [showPopup]="showGamePayment"></app-scratch-off-rules>

<!--    <app-games-payment-->
<!--            (isPaymentForbidden)="missPayment()"-->
<!--            (isElementReady)="isPaymentReady($event)"-->
<!--            (isPaymentSuccess)="showGame()"-->
<!--            [showPopup]="showGamePayment"></app-games-payment>-->


    <div class="loading" *ngIf="popupLoading">
        <div class="bar bar1"></div>
        <div class="bar bar2"></div>
        <div class="bar bar3"></div>
        <div class="bar bar4"></div>
        <div class="bar bar5"></div>
        <div class="bar bar6"></div>
        <div class="bar bar7"></div>
        <div class="bar bar8"></div>
    </div>
</div>

<app-rotate-popup></app-rotate-popup>
