import {Component, NgZone, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {PrizesService} from "../../../../service/prizes/prizes.service";
import {Router} from "@angular/router";
import {UserCardService} from "../../../../service/settings/userCard.service";
import {RouterPaths} from "../../../../config/router.paths.model";
import {PROJECT_MESSAGES} from "../../../../config/project";
import {CashAppService} from "../../../../service/settings/cashApp.service";
import {StylingService} from "../../../../service/styling.service";
import {StatesService} from "../../../../service/states/states.service";
import {StatesModel} from "../../../../models/states/states.model";

@Component({
    selector: 'app-new-card',
    templateUrl: './new-card.component.html',
    styleUrls: ['./new-card.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class NewCardComponent implements OnInit {
    isCashApp = false;
    isPrepaid = false;
    newCardForm: FormGroup;

    selectedState;
    states: Array<StatesModel> = [];
    notification: string;
    constructor(private formBuilder: FormBuilder,
                private userCardService: UserCardService,
                private loaderService: LoaderService,
                private router: Router,
                private ngZone: NgZone,
                private cashAppService: CashAppService,
                private statesService: StatesService,
                private stylingService: StylingService,
                private popupComponent: PopupComponent,) {

    }

    ngOnInit() {
        this.newCardForm = this.formBuilder.group({
            first_name: [null, Validators.required],
            last_name: [null, Validators.required],
            card_number: [null, Validators.required],
            exp: [null, Validators.required],
            cvv: [null, Validators.required],
            city: [null, Validators.required],
            state: [null, Validators.required],
            billing_address: [null, Validators.required],
            billing_zip: [null, Validators.required],
            card_nickname: [null, Validators.required]
        });
        this.isCashApp = this.cashAppService.checkIfCashApp();
        // this.isPrepaid = this.cashAppService.isPrepaid();

        this.getStatesList();
    }

    getStatesList() {
        this.statesService.getStatesList().subscribe(data => {
            this.setStatesList(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setStatesList(data) {
        try {
            if (data.status) {
                if (data.states.length) {
                    this.states = data.states;
                    this.selectedState = this.states[0];
                    this.newCardForm.controls.state.setValue(this.selectedState);
                }
                if (data.notification) {
                    this.notification = data.notification;
                }
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    onSubmit() {
        const number = this.getInputValue('card_number');
        const exp = this.getInputValue('exp');
        const cvv = this.getInputValue('cvv');
        const nickname = this.getInputValue('card_nickname');
        const firstName = this.getInputValue('first_name');
        const lastName = this.getInputValue('last_name');
        const billingAddress = this.getInputValue('billing_address');
        const billingZip = this.getInputValue('billing_zip');
        const city = this.getInputValue('city');
        const stateId = this.newCardForm.controls.state.value.id;

        if (number && exp && cvv && nickname) {

            let expMonth = exp.substr(0, 2);
            let expYear = exp.substr(2, 3);

            if(expMonth != '' && expYear != '' && +expMonth && +expYear && billingAddress != '' && billingZip != '' && firstName != '' && lastName != ''){
                const type = this.isCashApp ? 1 : 0;

                this.userCardService.addCard(type, number, expMonth, expYear, cvv, nickname, firstName, lastName, billingAddress, billingZip, city, stateId).subscribe(data => {
                    this.checkSubmitResult(data);
                }, (error) => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                });
            } else {
                this.popupComponent.showPopupError({text: PROJECT_MESSAGES.incorrectDataFormat});
            }
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }
    }

    checkSubmitResult(data) {
        try {
            if (data.status === true) {
                this.popupComponent.showPopupSuccess({text: data.message});
                this.goBack();
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }

    }

    onInput($event) {
        this.newCardForm.get('card_nickname').patchValue( $event.target.value.trim(), {emitEvent: false} );
    }

    getInputValue(field) {
        if (this.newCardForm.controls[field]) {
            return this.newCardForm.controls[field].value
        }
        return null;
    }

    getBackLink() {
        if (this.isCashApp) {
            return RouterPaths.settings.cashapp.main;
        }
        return RouterPaths.settings.card.main;
    }

    goBack() {
        this.newCardForm.reset();
        if (this.isCashApp) {
            this.router.navigate([RouterPaths.settings.cashapp.main]);
        } else if (this.isPrepaid) {
            this.router.navigate([RouterPaths.settings.prepaid.main]);
        } else {
            this.router.navigate([RouterPaths.settings.card.main]);
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }
}
