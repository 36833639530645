import {Inject, Injectable, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {LoaderService} from "./loader.service";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";
import {FrontendTestSettingsService} from "./testing/frontendTestSettings.service";

@Injectable({
    providedIn: 'root'
})
export class PartnerService {
    currentPartnerTemplate = new BehaviorSubject('');
    private storage = sessionStorage;
    constructor(private ngZone: NgZone,
                private route: ActivatedRoute,
                private loaderService: LoaderService,
                private http: HttpClient,
                private frontendTestSettingsService: FrontendTestSettingsService) {}

    getLogo() {
        return '../../../assets/images/mynftking.png'
    }

    storageGetCashapTag(){
        return this.storage.getItem('currentPartnerCashappTag');
    }

    getShortName(){
        // if (this.storage.getItem('currentPartnerShortName')) {
        //     return this.storage.getItem('currentPartnerShortName');
        // }

        return 'default';
    }

    getBusinessName(){
        // if (this.storage.getItem('currentPartnerBusinessName')) {
        //     return this.storage.getItem('currentPartnerBusinessName');
        // }
        return 'NFT';
    }

    getPartnerDomain(){
        // if (this.storage.getItem('currentPartnerDomain')) {
        //     return this.storage.getItem('currentPartnerDomain');
        // }
        return '';
    }

    getName() {
        //currentPartner.name
        return 'NFT';
    }
}
