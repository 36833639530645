import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {LoaderService} from "../../../../service/loader.service";
import {PrizesService} from "../../../../service/prizes/prizes.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {UserCardService} from "../../../../service/settings/userCard.service";
import {RouterPaths} from "../../../../config/router.paths.model";
import {CashAppService} from "../../../../service/settings/cashApp.service";
import {StylingService} from "../../../../service/styling.service";

@Component({
    selector: 'app-card-menu',
    templateUrl: './card-menu.component.html',
    styleUrls: ['./card-menu.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class CardMenuComponent implements OnInit {
    card;
    cardId;
    isCashApp = false;
    isPrepaid = false;
    constructor(private userCardService: UserCardService,
                private loaderService: LoaderService,
                private route: ActivatedRoute,
                private router: Router,
                private cashAppService: CashAppService,
                private stylingService: StylingService,
                private popupComponent: PopupComponent,) {

    }

    ngOnInit() {
        this.isCashApp = this.cashAppService.checkIfCashApp();
        this.isPrepaid = this.cashAppService.isPrepaid();
        this.route.params.subscribe((params: Params) => {
            this.cardId = params.id;

            this.getCardInfo(this.cardId);
        });
    }

    getCardInfo(id: string) {
        if (id) {
            this.userCardService.getCardInfo(id).subscribe(data => {
                this.getCardResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getCardResult(data) {
        try {
            if (data.status) {
                this.card = data.result;
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text: data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }

    }

    askDelete(id: string) {
        if (id && this.card && this.card.last_four) {
            const message = this.card.description && this.card.description.trim() ?
              `<b class="js-popup-title">Please confirm deleting</b> <br><br> <span class="js-popup-subtitle">${this.card.description} - ${this.card.last_four}</span>` :
              `<b class="js-popup-title">Please confirm deleting card</b> <br><br> <span class="js-popup-subtitle">ending in ${this.card.last_four}</span>`;

            this.popupComponent.showPopupAgreement({text: message}, (status) => {
                if (status) {
                    this.deleteCard(id);
                }
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    deleteCard(id: string) {
        if (id) {
            this.userCardService.deleteCard(id).subscribe(data => {
                this.deleteCardResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    deleteCardResult(data) {
        if(data.status){
            this.popupComponent.showPopupSuccess({text: data.message});
            if (this.isCashApp) {
                this.router.navigate([RouterPaths.settings.cashapp.main]);
            } else if (this.isPrepaid) {
                this.router.navigate([RouterPaths.settings.prepaid.main]);
            } else {
                this.router.navigate([RouterPaths.settings.card.main]);
            }
        }  else if (data.status === false) {
            this.popupComponent.showPopupError({text: data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getBackLink() {
        if (this.isCashApp) {
            return RouterPaths.settings.cashapp.main;
        }
        if (this.isPrepaid) {
            return RouterPaths.settings.prepaid.main;
        }
        return RouterPaths.settings.card.main;
    }

    getEditLink() {
        if (this.isCashApp) {
            return [RouterPaths.settings.cashapp.edit, this.cardId];
        }
        if (this.isPrepaid) {
            return [RouterPaths.settings.prepaid.edit, this.cardId];
        }
        return [RouterPaths.settings.card.edit, this.cardId];
    }

    getVerifyLink() {
        if (this.isCashApp) {
            return [RouterPaths.settings.cashapp.verify, this.cardId];
        }
        return [RouterPaths.settings.card.verify, this.cardId];
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }
}
