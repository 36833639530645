import * as i0 from "@angular/core";
var RememberUserService = /** @class */ (function () {
    function RememberUserService() {
    }
    RememberUserService.prototype.checkSaveUser = function (phoneControl, rememberControl) {
        if (localStorage.phone && this.isUserSaved()) {
            phoneControl.setValue(localStorage.phone);
            rememberControl.setValue(!!this.isUserSaved());
        }
    };
    RememberUserService.prototype.setSaveUser = function (phone, country, remember) {
        localStorage.phone = remember ? phone : '';
        localStorage.country = remember ? country : '';
        localStorage.remember_user = remember;
    };
    RememberUserService.prototype.isUserSaved = function () {
        return localStorage.remember_user;
    };
    RememberUserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function RememberUserService_Factory() { return new RememberUserService(); }, token: RememberUserService, providedIn: "root" });
    return RememberUserService;
}());
export { RememberUserService };
