import {Component, OnInit} from '@angular/core';
import {slideToLeft, slideToRight} from "../../animations/router.animations";
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {UserStatusService} from "../../service/userStatus.service";
import {USER_STATUS} from "../../config/user_status";
import {RouterPaths} from "../../config/router.paths.model";
import {GamesProviderService} from "../../service/games/gamesProvider.service";

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': '','[class.is-menu]': 'menu',} : {'[@slideToLeft]': '', '[class.is-menu]': 'menu',}
})
export class SettingsComponent implements OnInit {
    userStatus;
    USER_STATUS = USER_STATUS;

    menu = false;

    navigation = [];

    gameVendorName = this.gamesProviderService.getActiveProviderName();
    subjectLimitationTxt: string;

    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private userStatusService: UserStatusService,
                private popupComponent: PopupComponent,
                private gamesProviderService: GamesProviderService,
                private router: Router) {
    }


    ngOnInit() {
        this.getUserStatus();

        this.navigation = [
            {
                title: 'cash app card',
                link: RouterPaths.settings.cashapp.main,
                id: 'cashapp',
            },
            {
                title: 'CREDIT/DEBIT CARD',
                link: RouterPaths.settings.card.main,
                id: 'credit_card',
            },
            {
                title: 'PAPER CHECKS',
                link: RouterPaths.settings.addresses.main,
                id: 'mail_check',
            },
            {
                title: 'Contact info',
                link: RouterPaths.settings.contacts,
                id: 'contact_info',
            },
        ];
    }

    getUserStatus() {
        this.userStatusService.getSettingsData().subscribe(data => {
            this.setUserStatus(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setUserStatus(data) {
        if (data.status === true) {
            this.userStatus = data.userStatus;
            this.subjectLimitationTxt = data.subjectLimitationTxt;
        } else if (data.status === false){
            this.popupComponent.showPopupError({text: data.message});
        } else if (data.status === undefined){
            this.loaderService.hideAndShowTryAgain();
        }
    }

    askDeleteUser() {
        this.popupComponent.showPopupAgreement({text: `Do you really want to delete the user?`,
            confirmButtonText: 'DELETE ACCOUNT<svg><rect></rect></svg>',
            style: 'swal-agreement--error'}, (status) => {
            if (status) {
                this.deleteUser();
            }
        });
    }

    deleteUser() {
        this.userStatusService.delete().subscribe(data => {
            this.updateUserChangingStatus(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        });
    }

    updateUserChangingStatus(data) {
        if(data.status === true || data.status === "true"){
            this.popupComponent.showPopupSuccess({text:data.message});
            this.menu = false;
            this.getUserStatus();
        } else if (data.status === false || data.status === "false") {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    background(id){
        if(id == 'credit_card'){
            //return 'card_background';
        }

        return '';
    }

    isAvailble(id,link){
        // if(id == 'credit_card' || id == 'cashapp'){
        //     this.popupComponent.showPopupError({
        //         text:'Performing maintenance. Temporarily unavailable. Please check back later.',
        //         confirmButtonText:'OK'
        //     });
        //
        //     return;
        // }

        this.router.navigate([link]);
    }

    getLinkToFunds() {
        return RouterPaths.settings.funds;
    }
}
