import {TERMS_AND_CONDITIONS_CONTENT_TYPES} from "./terms-and-conditions.config";

export const getTermsAndConditions = (businessName: string, domain) => {
  return {
    title: 'TERMS AND CONDITIONS',
      subtitle: 'Last updated August 30, 2021',
    parts: [
    {
      title: 'AGREEMENT TO TERMS',
      paragraphs: [
        `These Terms of Use constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”) and Iris Key Sloutions, LLC, doing business as ${businessName} (<span class="bold">"${businessName}"</span>, <span class="bold">“we”</span>, <span class="bold">“us”</span>, or <span class="bold">“our”</span>), concerning your access to and use of the ${domain} website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”). We are registered in North Carolina, United States and have our registered office at 314 A Hay Street, Fayetteville, NC 28301. You agree that by accessing the Site, you have read, understood, and agreed to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE USE IMMEDIATELY.`,
        'Supplemental terms and conditions or documents that may be posted on the Site from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms of Use at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of these Terms of Use, and you waive any right to receive specific notice of each such change. Please ensure that you check the applicable Terms every time you use our Site so that you understand which Terms apply. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms of Use by your continued use of the Site after the date such revised Terms of Use are posted.',
        'The information provided on the Site is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.',
        'The Site is not tailored to comply with industry-specific regulations (Health Insurance Portability and Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions would be subjected to such laws, you may not use this Site. You may not use the Site in a way that would violate the Gramm-Leach-Bliley Act (GLBA).',
        'The Site is intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Site.',
      ]
    },
    {
      title: 'INTELLECTUAL PROPERTY RIGHTS',
      paragraphs: [
        'Unless otherwise indicated, the Site is our proprietary property and all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics on the Site (collectively, the “Content”) and the trademarks, service marks, and logos contained therein (the “Marks”) are owned or controlled by us or licensed to us, and are protected by copyright and trademark laws and various other intellectual property rights and unfair competition laws of the United States, international copyright laws, and international conventions. The Content and the Marks are provided on the Site “AS IS” for your information and personal use only. Except as expressly provided in these Terms of Use, no part of the Site and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purpose whatsoever, without our express prior written permission.',
        'Provided that you are eligible to use the Site, you are granted a limited license to access and use the Site and to download or print a copy of any portion of the Content to which you have properly gained access solely for your personal, non-commercial use. We reserve all rights not expressly granted to you in and to the Site, the Content and the Marks.',
      ]
    },
    {
      title: 'USER REPRESENTATIONS',
      paragraphs: [
        'By using the Site, you represent and warrant that: (1) all registration information you submit will\n' +
        'be true, accurate, current, and complete; (2) you will maintain the accuracy of such information\n' +
        'and promptly update such registration information as necessary; (3) you have the legal capacity\n' +
        'and you agree to comply with these Terms of Use; (4) you are not a minor in the jurisdiction in\n' +
        'which you reside; (5) you will not access the Site through automated or non-human means,\n' +
        'whether through a bot, script or otherwise; (6) you will not use the Site for any illegal or\n' +
        'unauthorized purpose; and (7) your use of the Site will not violate any applicable law or\n' +
        'regulation',

        'If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the\n' +
        'right to suspend or terminate your account and refuse any and all current or future use of the\n' +
        'Site (or any portion thereof).',
      ]
    },
    {
      title: 'USER REGISTRATION',
      paragraphs: [
        'You may be required to register with the Site. You agree to keep your password confidential and\n' +
        'will be responsible for all use of your account and password. We reserve the right to remove,\n' +
        'reclaim, or change a username you select if we determine, in our sole discretion, that such\n' +
        'username is inappropriate, obscene, or otherwise objectionable.',
      ]
    },
    {
      title: 'PRODUCTS',
      paragraphs: [
        'We make every effort to display as accurately as possible the colors, features, specifications,\n' +
        'and details of the products available on the Site. However, we do not guarantee that the colors,\n' +
        'features, specifications, and details of the products will be accurate, complete, reliable, current,\n' +
        'or free of other errors, and your electronic display may not accurately reflect the actual colors\n' +
        'and details of the products. All products are subject to availability, and we cannot guarantee that\n' +
        'items will be in stock. We reserve the right to discontinue any products at any time for any\n' +
        'reason. Prices for all products are subject to change.',
      ]
    },
    {
      title: 'PURCHASES AND PAYMENT',
      paragraphs: [
        'We accept the following forms of payment:',
        {
          type: TERMS_AND_CONDITIONS_CONTENT_TYPES.unorderedList,
          items: [
            'Visa',
            'Mastercard',
            'American Express',
            'Discover',
          ]
        },
        'You agree to provide current, complete, and accurate purchase and account information for all\n' +
        'purchases made via the Site. You further agree to promptly update account and payment\n' +
        'information, including email address, payment method, and payment card expiration date, so\n' +
        'that we can complete your transactions and contact you as needed. Sales tax will be added to\n' +
        'the price of purchases as deemed required by us. We may change prices at any time. All\n' +
        'payments shall be in U.S. dollars.',

        'You agree to pay all charges at the prices then in effect for your purchases and any applicable\n' +
        'shipping fees, and you authorize us to charge your chosen payment provider for any such\n' +
        'amounts upon placing your order. We reserve the right to correct any errors or mistakes in\n' +
        'pricing, even if we have already requested or received payment.',

        'We reserve the right to refuse any order placed through the Site. We may, in our sole discretion,\n' +
        'limit or cancel quantities purchased per person, per household, or per order. These restrictions\n' +
        'may include orders placed by or under the same customer account, the same payment method,\n' +
        'and/or orders that use the same billing or shipping address. We reserve the right to limit or\n' +
        'prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or\n' +
        'distributors.',
      ]
    },
    {
      title: 'RETURN/REFUNDS POLICY',
      paragraphs: [
        'All sales are final and no refund will be issued.'
      ]
    },
    {
      title: 'PROHIBITED ACTIVITIES',
      paragraphs: [
        'You may not access or use the Site for any purpose other than that for which we make the Site\n' +
        'available. The Site may not be used in connection with any commercial endeavors except those\n' +
        'that are specifically endorsed or approved by us.',

        'As a user of the Site, you agree not to:',

        {
          type: TERMS_AND_CONDITIONS_CONTENT_TYPES.orderedList,
          items: [
            'Systematically retrieve data or other content from the Site to create or compile, directly or\n' +
            'indirectly, a collection, compilation, database, or directory without written permission from us.',

            'Make any unauthorized use of the Site, including collecting usernames and/or email\n' +
            'addresses of users by electronic or other means for the purpose of sending unsolicited email, or\n' +
            'creating user accounts by automated means or under false pretenses.',

            'Use a buying agent or purchasing agent to make purchases on the Site.',

            'Use the Site to advertise or offer to sell goods and services.',

            'Circumvent, disable, or otherwise interfere with security-related features of the Site, including\n' +
            'features that prevent or restrict the use or copying of any Content or enforce limitations on the\n' +
            'use of the Site and/or the Content contained therein.',

            'Engage in unauthorized framing of or linking to the Site.',

            'Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive\n' +
            'account information such as user passwords.',

            'Make improper use of our support services or submit false reports of abuse or misconduct.',

            'Engage in any automated use of the system, such as using scripts to send comments or\n' +
            'messages, or using any data mining, robots, or similar data gathering and extraction tools.',

            'Interfere with, disrupt, or create an undue burden on the Site or the networks or services\n' +
            'connected to the Site.',

            'Attempt to impersonate another user or person or use the username of another user.',

            'Use any information obtained from the Site in order to harass, abuse, or harm another\n' +
            'person.',

            'Sell or otherwise transfer your profile.',

            'Use the Site as part of any effort to compete with us or otherwise use the Site and/or the\n' +
            'Content for any revenue-generating endeavor or commercial enterprise.',

            'Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in\n' +
            'any way making up a part of the Site.',

            'Attempt to bypass any measures of the Site designed to prevent or restrict access to the\n' +
            'Site, or any portion of the Site.',

            'Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing\n' +
            'any portion of the Site to you.',

            'Delete the copyright or other proprietary rights notice from any Content.',

            'Copy or adapt the Site’s software, including but not limited to Flash, PHP, HTML, JavaScript,\n' +
            'or other code.',

            'Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other\n' +
            'material, including excessive use of capital letters and spamming (continuous posting of\n' +
            'repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Site or\n' +
            'modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or\n' +
            'maintenance of the Site.',

            'Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive\n' +
            'or active information collection or transmission mechanism, including without limitation, clear \n' +
            'graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices\n' +
            '(sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).',

            'Except as may be the result of standard search engine or Internet browser usage, use,\n' +
            'launch, develop, or distribute any automated system, including without limitation, any spider,\n' +
            'robot, cheat utility, scraper, or offline reader that accesses the Site, or using or launching any\n' +
            'unauthorized script or other software.',

            'Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.',

            'Use the Site in a manner inconsistent with any applicable laws or regulations.'
          ]
        }
      ]
    },
    {
      title: 'USER GENERATED CONTRIBUTIONS',
      paragraphs: [
        'The Site does not offer users to submit or post content. We may provide you with the opportunity\n' +
        'to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and\n' +
        'materials to us or on the Site, including but not limited to text, writings, video, audio, photographs,\n' +
        'graphics, comments, suggestions, or personal information or other material (collectively,\n' +
        '"Contributions"). Contributions may be viewable by other users of the Site and through third-party\n' +
        'websites. As such, any Contributions you transmit may be treated in accordance with the Site\n' +
        'Privacy Policy. When you create or make available any Contributions, you thereby represent and\n' +
        'warrant that:',

        {
          type: TERMS_AND_CONDITIONS_CONTENT_TYPES.orderedList,
          items: [
            'The creation, distribution, transmission, public display, or performance, and the accessing,\n' +
            'downloading, or copying of your Contributions do not and will not infringe the proprietary rights,\n' +
            'including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any\n' +
            'third party.',

            'You are the creator and owner of or have the necessary licenses, rights, consents, releases,\n' +
            'and permissions to use and to authorize us, the Site, and other users of the Site to use your\n' +
            'Contributions in any manner contemplated by the Site and these Terms of Use.',

            'You have the written consent, release, and/or permission of each and every identifiable\n' +
            'individual person in your Contributions to use the name or likeness of each and every such\n' +
            'identifiable individual person to enable inclusion and use of your Contributions in any manner\n' +
            'contemplated by the Site and these Terms of Use.',

            'Your Contributions are not false, inaccurate, or misleading.',

            'Your Contributions are not unsolicited or unauthorized advertising, promotional materials,\n' +
            'pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.',

            'Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous,\n' +
            'slanderous, or otherwise objectionable (as determined by us).',

            'Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.',

            'Your Contributions are not used to harass or threaten (in the legal sense of those terms) any\n' +
            'other person and to promote violence against a specific person or class of people.',

            'Your Contributions do not violate any applicable law, regulation, or rule.',

            'Your Contributions do not violate the privacy or publicity rights of any third party.',

            'Your Contributions do not violate any applicable law concerning child pornography, or\n' +
            'otherwise intended to protect the health or well-being of minors;',

            'Your Contributions do not include any offensive comments that are connected to race,\n' +
            'national origin, gender, sexual preference, or physical handicap.',

            'Your Contributions do not otherwise violate, or link to material that violates, any provision of\n' +
            'these Terms of Use, or any applicable law or regulation.'
          ]
        },
        'Any use of the Site or the Marketplace Offerings in violation of the foregoing violates these Terms\n' +
        'of Use and may result in, among other things, termination or suspension of your rights to use the\n' +
        'Site and the Marketplace Offerings.'
      ]
    },
    {
      title: 'CONTRIBUTION LICENSE',
      paragraphs: [
        'You and Site agree that we may access, store, process, and use any information and personal\n' +
        'data that you provide following the terms of the Privacy Policy and your choices (including\n' +
        'settings).',

        'By submitting suggestions or other feedback regarding the Site, you agree that we can use and\n' +
        'share such feedback for any purpose without compensation to you.',

        'We do not assert any ownership over your Contributions. You retain full ownership of all of your\n' +
        'Contributions and any intellectual property rights or other proprietary rights associated with your\n' +
        'Contributions. We are not liable for any statements or representations in your Contributions\n' +
        'provided by you in any area on the Site. You are solely responsible for your Contributions to the\n' +
        'Site and you expressly agree to exonerate us from any and all responsibility and to refrain from\n' +
        'any legal action against us regarding your Contributions.'
      ]
    },
    {
      title: 'MOBILE APPLICATION LICENSE',
      paragraphs: [
        {
          type: TERMS_AND_CONDITIONS_CONTENT_TYPES.innerBlock,
          title: 'Use License',
          text: [
            'If you access the Marketplace Offerings via a mobile application, then we grant you a revocable,\n' +
            'non-exclusive, non-transferable, limited right to install and use the mobile application on\n' +
            'wireless electronic devices owned or controlled by you, and to access and use the mobile\n' +
            'application on such devices strictly in accordance with the terms and conditions of this mobile\n' +
            'application license contained in these Terms of Use. You shall not: (1) except as permitted by\n' +
            'applicable law, decompile, reverse engineer, disassemble, attempt to derive the source code of,\n' +
            'or decrypt the application; (2) make any modification, adaptation, improvement, enhancement,\n' +
            'translation, or derivative work from the application; (3) violate any applicable laws, rules, or\n' +
            'regulations in connection with your access or use of the application; (4) remove, alter, or\n' +
            'obscure any proprietary notice (including any notice of copyright or trademark) posted by us or\n' +
            'the licensors of the application; (5) use the application for any revenue generating endeavor,\n' +
            'commercial enterprise, or other purpose for which it is not designed or intended; (6) make the\n' +
            'application available over a network or other environment permitting access or use by multiple\n' +
            'devices or users at the same time; (7) use the application for creating a product, service, or\n' +
            'software that is, directly or indirectly, competitive with or in any way a substitute for the\n' +
            'application; (8) use the application to send automated queries to any website or to send any \n' +
            'unsolicited commercial e-mail; or (9) use any proprietary information or any of our interfaces or\n' +
            'our other intellectual property in the design, development, manufacture, licensing, or distribution\n' +
            'of any applications, accessories, or devices for use with the application.',
          ]
        },
        {
          type: TERMS_AND_CONDITIONS_CONTENT_TYPES.innerBlock,
          title: 'Apple and Android Devices',
          text: [
            'The following terms apply when you use a mobile application obtained from either the Apple\n' +
            'Store or Google Play (each an “App Distributor”) to access the Marketplace Offerings: (1) the\n' +
            'license granted to you for our mobile application is limited to a non-transferable license to use\n' +
            'the application on a device that utilizes the Apple iOS or Android operating systems, as\n' +
            'applicable, and in accordance with the usage rules set forth in the applicable App Distributor’s\n' +
            'terms of service; (2) we are responsible for providing any maintenance and support services\n' +
            'with respect to the mobile application as specified in the terms and conditions of this mobile\n' +
            'application license contained in these Terms of Use or as otherwise required under applicable\n' +
            'law, and you acknowledge that each App Distributor has no obligation whatsoever to furnish any\n' +
            'maintenance and support services with respect to the mobile application; (3) in the event of any\n' +
            'failure of the mobile application to conform to any applicable warranty, you may notify the\n' +
            'applicable App Distributor, and the App Distributor, in accordance with its terms and policies,\n' +
            'may refund the purchase price, if any, paid for the mobile application, and to the maximum\n' +
            'extent permitted by applicable law, the App Distributor will have no other warranty obligation\n' +
            'whatsoever with respect to the mobile application; (4) you represent and warrant that (i) you are\n' +
            'not located in a country that is subject to a U.S. government embargo, or that has been\n' +
            'designated by the U.S. government as a “terrorist supporting” country and (ii) you are not listed\n' +
            'on any U.S. government list of prohibited or restricted parties; (5) you must comply with\n' +
            'applicable third-party terms of agreement when using the mobile application, e.g., if you have a\n' +
            'VoIP application, then you must not be in violation of their wireless data service agreement\n' +
            'when using the mobile application; and (6) you acknowledge and agree that the App Distributors\n' +
            'are third-party beneficiaries of the terms and conditions in this mobile application license\n' +
            'contained in these Terms of Use, and that each App Distributor will have the right (and will be\n' +
            'deemed to have accepted the right) to enforce the terms and conditions in this mobile\n' +
            'application license contained in these Terms of Use against you as a third-party beneficiary\n' +
            'thereof.',
          ]
        },
      ]
    },
    {
      title: 'SUBMISSIONS',
      paragraphs: [
        'You acknowledge and agree that any questions, comments, suggestions, ideas, feedback, or\n' +
        'other information regarding the Site or the Marketplace Offerings ("Submissions") provided by\n' +
        'you to us are non-confidential and shall become our sole property. We shall own exclusive\n' +
        'rights, including all intellectual property rights, and shall be entitled to the unrestricted use and\n' +
        'dissemination of these Submissions for any lawful purpose, commercial or otherwise, without\n' +
        'acknowledgment or compensation to you. You hereby waive all moral rights to any such\n' +
        'Submissions, and you hereby warrant that any such Submissions are original with you or that\n' +
        'you have the right to submit such Submissions. You agree there shall be no recourse against us\n' +
        'for any alleged or actual infringement or misappropriation of any proprietary right in your \n' +
        'Submissions.'
      ]
    },
    {
      title: 'SITE MANAGEMENT',
      paragraphs: [
        'We reserve the right, but not the obligation, to: (1) monitor the Site for violations of these Terms\n' +
        'of Use; (2) take appropriate legal action against anyone who, in our sole discretion, violates the\n' +
        'law or these Terms of Use, including without limitation, reporting such user to law enforcement\n' +
        'authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the\n' +
        'availability of, or disable (to the extent technologically feasible) any of your Contributions or any\n' +
        'portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove\n' +
        'from the Site or otherwise disable all files and content that are excessive in size or are in any\n' +
        'way burdensome to our systems; and (5) otherwise manage the Site in a manner designed to\n' +
        'protect our rights and property and to facilitate the proper functioning of the Site and the\n' +
        'Marketplace Offerings.'
      ]
    },
    {
      title: 'PRIVACY POLICY',
      paragraphs: [
        'We care about data privacy and security. Please review our Privacy\n' +
        `Policy: <a href="${domain}/privacy"><span class="bold">${domain}/privacy</span></a>. By using the Site or the Marketplace Offerings, you agree\n` +
        'to be bound by our Privacy Policy, which is incorporated into these Terms of Use. Please be\n' +
        'advised the Site and the Marketplace Offerings are hosted in the United States. If you access\n' +
        'the Site or the Marketplace Offerings from any other region of the world with laws or other\n' +
        'requirements governing personal data collection, use, or disclosure that differ from applicable\n' +
        'laws in the United States, then through your continued use of the Site, you are transferring your\n' +
        'data to the United States, and you expressly consent to have your data transferred to and\n' +
        'processed in the United States.'
      ]
    },
    {
      title: 'TERM AND TERMINATION',
      paragraphs: [
        'These Terms of Use shall remain in full force and effect while you use the Site. WITHOUT\n' +
        'LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO, IN\n' +
        'OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF\n' +
        'THE SITE AND THE MARKETPLACE OFFERINGS (INCLUDING BLOCKING CERTAIN IP\n' +
        'ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT\n' +
        'LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT\n' +
        'CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE\n' +
        'MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE AND THE MARKETPLACE\n' +
        'OFFERINGS OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU\n' +
        'POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.',

        'If we terminate or suspend your account for any reason, you are prohibited from registering and\n' +
        'creating a new account under your name, a fake or borrowed name, or the name of any third\n' +
        'party, even if you may be acting on behalf of the third party. In addition to terminating or \n' +
        'suspending your account, we reserve the right to take appropriate legal action, including without\n' +
        'limitation pursuing civil, criminal, and injunctive redress.',

      ]
    },
    {
      title: 'MODIFICATIONS AND INTERRUPTIONS',
      paragraphs: [
        'We reserve the right to change, modify, or remove the contents of the Site at any time or for any\n' +
        'reason at our sole discretion without notice. However, we have no obligation to update any\n' +
        'information on our Site. We also reserve the right to modify or discontinue all or part of the\n' +
        'Marketplace Offerings without notice at any time. We will not be liable to you or any third party\n' +
        'for any modification, price change, suspension, or discontinuance of the Site or the Marketplace\n' +
        'Offerings.',

        'We cannot guarantee the Site and the Marketplace Offerings will be available at all times. We\n' +
        'may experience hardware, software, or other problems or need to perform maintenance related\n' +
        'to the Site, resulting in interruptions, delays, or errors. We reserve the right to change, revise,\n' +
        'update, suspend, discontinue, or otherwise modify the Site or the Marketplace Offerings at any\n' +
        'time or for any reason without notice to you. You agree that we have no liability whatsoever for\n' +
        'any loss, damage, or inconvenience caused by your inability to access or use the Site or the\n' +
        'Marketplace Offerings during any downtime or discontinuance of the Site or the Marketplace\n' +
        'Offerings. Nothing in these Terms of Use will be construed to obligate us to maintain and\n' +
        'support the Site or the Marketplace Offerings or to supply any corrections, updates, or releases\n' +
        'in connection therewith.'
      ]
    },
    {
      title: 'GOVERNING LAW',
      paragraphs: [
        'These Terms of Use and your use of the Site and the Marketplace Offerings are governed by\n' +
        'and construed in accordance with the laws of the State of North Carolina applicable to\n' +
        'agreements made and to be entirely performed within the State of North Carolina, without\n' +
        'regard to its conflict of law principles.'
      ]
    },
    {
      title: 'DISPUTE RESOLUTION',
      paragraphs: [
        {
          type: TERMS_AND_CONDITIONS_CONTENT_TYPES.innerBlock,
          title: 'Informal Negotiations',
          text: [
            'To expedite resolution and control the cost of any dispute, controversy, or claim related to these\n' +
            'Terms of Use (each "Dispute" and collectively, the “Disputes”) brought by either you or us\n' +
            '(individually, a “Party” and collectively, the “Parties”), the Parties agree to first attempt to\n' +
            'negotiate any Dispute (except those Disputes expressly provided below) informally for at\n' +
            'least thirty (30) days before initiating arbitration. Such informal negotiations commence upon\n' +
            'written notice from one Party to the other Party.'
          ]
        },
        {
          type: TERMS_AND_CONDITIONS_CONTENT_TYPES.innerBlock,
          title: 'Binding Arbitration',
          text: [
            'If the Parties are unable to resolve a Dispute through informal negotiations, the Dispute (except\n' +
            'those Disputes expressly excluded below) will be finally and exclusively resolved by binding\n' +
            'arbitration. YOU UNDERSTAND THAT WITHOUT THIS PROVISION, YOU WOULD HAVE THE\n' +
            'RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The arbitration shall be commenced and\n' +
            'conducted under the Commercial Arbitration Rules of the American Arbitration Association\n' +
            '("AAA") and, where appropriate, the AAA’s Supplementary Procedures for Consumer Related\n' +
            'Disputes ("AAA Consumer Rules"), both of which are available at the AAA website www.adr.org.\n' +
            'Your arbitration fees and your share of arbitrator compensation shall be governed by the AAA\n' +
            'Consumer Rules and, where appropriate, limited by the AAA Consumer Rules. The arbitration\n' +
            'may be conducted in person, through the submission of documents, by phone, or online. The\n' +
            'arbitrator will make a decision in writing, but need not provide a statement of reasons unless\n' +
            'requested by either Party. The arbitrator must follow applicable law, and any award may be\n' +
            'challenged if the arbitrator fails to do so. Except where otherwise required by the applicable\n' +
            'AAA rules or applicable law, the arbitration will take place in Cumberland, North Carolina. Except\n' +
            'as otherwise provided herein, the Parties may litigate in court to compel arbitration, stay\n' +
            'proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award\n' +
            'entered by the arbitrator.',

            'If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute shall be\n' +
            'commenced or prosecuted in the state and federal courts located in Cumberland, North\n' +
            'Carolina, and the Parties hereby consent to, and waive all defenses of lack of personal\n' +
            'jurisdiction, and forum non conveniens with respect to venue and jurisdiction in such state and\n' +
            'federal courts. Application of the United Nations Convention on Contracts for the International\n' +
            'Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) are excluded\n' +
            'from these Terms of Use.',

            'In no event shall any Dispute brought by either Party related in any way to the Site be\n' +
            'commenced more than one (1) years after the cause of action arose. If this provision is found to\n' +
            'be illegal or unenforceable, then neither Party will elect to arbitrate any Dispute falling within\n' +
            'that portion of this provision found to be illegal or unenforceable and such Dispute shall be\n' +
            'decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and\n' +
            'the Parties agree to submit to the personal jurisdiction of that court.'
          ]
        },
        {
          type: TERMS_AND_CONDITIONS_CONTENT_TYPES.innerBlock,
          title: 'Restrictions',
          text: [
            'The Parties agree that any arbitration shall be limited to the Dispute between the Parties\n' +
            'individually. To the full extent permitted by law, (a) no arbitration shall be joined with any other\n' +
            'proceeding; (b) there is no right or authority for any Dispute to be arbitrated on a class-action\n' +
            'basis or to utilize class action procedures; and (c) there is no right or authority for any Dispute\n' +
            'to be brought in a purported representative capacity on behalf of the general public or any other\n' +
            'persons.'
          ]
        },
        {
          type: TERMS_AND_CONDITIONS_CONTENT_TYPES.innerBlock,
          title: 'Exceptions to Informal Negotiations and Arbitration',
          text: [
            'The Parties agree that the following Disputes are not subject to the above provisions\n' +
            'concerning informal negotiations binding arbitration: (a) any Disputes seeking to enforce or\n' +
            'protect, or concerning the validity of, any of the intellectual property rights of a Party; (b) any\n' +
            'Dispute related to, or arising from, allegations of theft, piracy, invasion of privacy, or \n' +
            'unauthorized use; and (c) any claim for injunctive relief. If this provision is found to be illegal or\n' +
            'unenforceable, then neither Party will elect to arbitrate any Dispute falling within that portion of\n' +
            'this provision found to be illegal or unenforceable and such Dispute shall be decided by a court\n' +
            'of competent jurisdiction within the courts listed for jurisdiction above, and the Parties agree to\n' +
            'submit to the personal jurisdiction of that court.'
          ]
        },
      ]
    },
    {
      title: 'CORRECTIONS',
      paragraphs: [
        'There may be information on the Site that contains typographical errors, inaccuracies, or\n' +
        'omissions that may relate to the Marketplace Offerings, including descriptions, pricing,\n' +
        'availability, and various other information. We reserve the right to correct any errors,\n' +
        'inaccuracies, or omissions and to change or update the information on the Site at any time,\n' +
        'without prior notice.'
      ]
    },
    {
      title: 'DISCLAIMER',
      paragraphs: [
        'THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT\n' +
        'YOUR USE OF THE SITE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST\n' +
        'EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,\n' +
        'IN CONNECTION WITH THE SITE AND YOUR USE THEREOF, INCLUDING, WITHOUT\n' +
        'LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A\n' +
        'PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR\n' +
        'REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S\n' +
        'CONTENT OR THE CONTENT OF ANY WEBSITES LINKED TO THIS SITE AND WE WILL\n' +
        'ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR\n' +
        'INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY\n' +
        'DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND\n' +
        'USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE\n' +
        'SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL\n' +
        'INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF\n' +
        'TRANSMISSION TO OR FROM THE SITE, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR\n' +
        'THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY THIRD\n' +
        'PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS\n' +
        'OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF\n' +
        'ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE\n' +
        'SITE. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY\n' +
        'FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY\n' +
        'THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE\n' +
        'APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT\n' +
        'BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY\n' +
        'TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR\n' +
        'SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY\n' +
        'MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND\n' +
        'EXERCISE CAUTION WHERE APPROPRIATE.'
      ]
    },
    {
      title: 'LIMITATIONS OF LIABILITY',
      paragraphs: [
        'IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO\n' +
        'YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,\n' +
        'EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST\n' +
        'PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR\n' +
        'USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH\n' +
        'DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,\n' +
        'OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE\n' +
        'FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT PAID, IF ANY,\n' +
        'BY YOU TO US DURING THE THREE (3) MONTH PERIOD PRIOR TO ANY CAUSE OF\n' +
        'ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW\n' +
        'LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN\n' +
        'DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR\n' +
        'LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.'
      ]
    },
    {
      title: 'INDEMNIFICATION',
      paragraphs: [
        'You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and\n' +
        'all of our respective officers, agents, partners, and employees, from and against any loss,\n' +
        'damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made\n' +
        'by any third party due to or arising out of: (1) use of the Site; (2) breach of these Terms of Use;\n' +
        '(3) any breach of your representations and warranties set forth in these Terms of Use; (4) your\n' +
        'violation of the rights of a third party, including but not limited to intellectual property rights; or\n' +
        '(5) any overt harmful act toward any other user of the Site with whom you connected via the\n' +
        'Site. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the\n' +
        'exclusive defense and control of any matter for which you are required to indemnify us, and you\n' +
        'agree to cooperate, at your expense, with our defense of such claims. We will use reasonable\n' +
        'efforts to notify you of any such claim, action, or proceeding which is subject to this\n' +
        'indemnification upon becoming aware of it.'
      ]
    },
    {
      title: 'USER DATA',
      paragraphs: [
        'We will maintain certain data that you transmit to the Site for the purpose of managing the\n' +
        'performance of the Site, as well as data relating to your use of the Site. Although we perform\n' +
        'regular routine backups of data, you are solely responsible for all data that you transmit or that\n' +
        'relates to any activity you have undertaken using the Site. You agree that we shall have no\n' +
        'liability to you for any loss or corruption of any such data, and you hereby waive any right of\n' +
        'action against us arising from any such loss or corruption of such data.'
      ]
    },
    {
      title: 'ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES',
      paragraphs: [
        'Visiting the Site, sending us emails, and completing online forms constitute electronic\n' +
        'communications. You consent to receive electronic communications, and you agree that all\n' +
        'agreements, notices, disclosures, and other communications we provide to you electronically, \n' +
        'via email and on the Site, satisfy any legal requirement that such communication be in writing.\n' +
        'YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS,\n' +
        'ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES,\n' +
        'POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR\n' +
        'VIA THE SITE. You hereby waive any rights or requirements under any statutes, regulations,\n' +
        'rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery\n' +
        'or retention of non-electronic records, or to payments or the granting of credits by any means\n' +
        'other than electronic means.'
      ]
    },
    {
      title: 'CALIFORNIA USERS AND RESIDENTS',
      paragraphs: [
        'If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance\n' +
        'Unit of the Division of Consumer Services of the California Department of Consumer Affairs in\n' +
        'writing at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone\n' +
        'at <a href="tel:8009525210">(800) 952-5210</a> or <a href="tel:9164451254">(916) 445-1254</a>.'
      ]
    },
    {
      title: 'MISCELLANEOUS',
      paragraphs: [
        'These Terms of Use and any policies or operating rules posted by us on the Site or in respect to\n' +
        'the Site constitute the entire agreement and understanding between you and us. Our failure to\n' +
        'exercise or enforce any right or provision of these Terms of Use shall not operate as a waiver of\n' +
        'such right or provision. These Terms of Use operate to the fullest extent permissible by law. We\n' +
        'may assign any or all of our rights and obligations to others at any time. We shall not be\n' +
        'responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond\n' +
        'our reasonable control. If any provision or part of a provision of these Terms of Use is\n' +
        'determined to be unlawful, void, or unenforceable, that provision or part of the provision is\n' +
        'deemed severable from these Terms of Use and does not affect the validity and enforceability of\n' +
        'any remaining provisions. There is no joint venture, partnership, employment or agency\n' +
        'relationship created between you and us as a result of these Terms of Use or use of the Site.\n' +
        'You agree that these Terms of Use will not be construed against us by virtue of having drafted\n' +
        'them. You hereby waive any and all defenses you may have based on the electronic form of\n' +
        'these Terms of Use and the lack of signing by the parties hereto to execute these Terms of Use.'
      ]
    },
    {
      title: 'CONTACT US',
      paragraphs: [
        'In order to resolve a complaint regarding the Site or to receive further information regarding use\n' +
        'of the Site, please contact us at:',
        {
          type: TERMS_AND_CONDITIONS_CONTENT_TYPES.footer,
          items: [
            'Iris Key Solutions, LLC',
            '301 Green St Ste 200 #1403',
            'Fayetteville, NC 28301, USA',
            'Phone: <a href="tel:9197923807">(919) 792-3807</a>',
            '<a href="mailto:info@iriskeysolutions.com">info@iriskeysolutions.com</a>'
          ]
        }
      ]
    },
  ]
  }
}
