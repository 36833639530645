import {Component, HostListener, OnInit} from '@angular/core';
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {PartnerService} from "../../service/partner.service";
import {AppVersionrService} from "../../service/app-version/appVersionr.service";
import {AppPromptService} from "../../service/app/appPrompt.service";
import {PopupComponent} from "../../shared/popup/popup.component";
import {StylingService} from "../../service/styling.service";
import {RouterPaths} from "../../config/router.paths.model";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    isApp = +localStorage.getItem('isApp');
    menu = false;
    appInstalled = false;

    deferredPrompt: any;
    ROUTER_PATHS = RouterPaths;

    constructor(
        private auth:AuthenticationService,
        private router: Router,
        private appVersionrService: AppVersionrService,
        private appPromptService: AppPromptService,
        private partnerService: PartnerService,
        private stylingService: StylingService,
        private popupComponent: PopupComponent
    ) {


    }


    ngOnInit() {
        window.addEventListener('appinstalled', (evt) => {
            this.appInstalled = true;
            this.popupComponent.showPopupSuccess({text: 'The App was installed successful.'})
        });
    }

    upgradeApp(){
        this.appVersionrService.upgradeApp();
    }

    logout(){
        this.auth.logout();
        this.router.navigate(['/'], {queryParams: {logout: true}});
    }

    showMenu(status) {
        this.menu = !this.menu;
    }

    getInvestmentURL() {
        return ['/home/investments'];
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    installApp() {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then(choiceResult => {
            if (choiceResult.outcome === 'accepted') {
            }
            //this.deferredPrompt = null;
            this.menu = false;
        });
    }

    isAndroid(){
        if(/Android/i.test(navigator.userAgent)){
            return true
        }
        else{
            return false
        }
    }

    isAppUse() {
        if (window.matchMedia('(display-mode: standalone)').matches) {
            return true;
        }
        return false;
    }

    needInstallOnAndroid() {
        this.deferredPrompt = this.appPromptService.deferredPrompt;
        if (!this.deferredPrompt) {
            this.appInstalled = true;
        }

        if (this.isAndroid() && !this.isAppUse() && !this.isApp && !this.appInstalled) {
            return true;
        }
        return false;
    }

    disableMenu() {
        return this.router.url === '/home/rewards/form';
    }

    goToTermsAndConditions() {
        this.router.navigate([this.ROUTER_PATHS.termsAndConditions.main]);
        this.showMenu(false);
    }
}
