<div class="page page--primary rewards" [class.is-blurred]="showSupportForm">
    <app-logo-bg></app-logo-bg>

    <div class="page__inner">
        <h1 class="page__title">REWARDS</h1>

        <div class="rewards__ad rewards__ad--no-mt">
            <div class="ad-simple">
                <h2 class="ad-simple__title gl-title">LIMITED SWEEPS OFFER</h2>
                <p class="ad-simple__text gl-subtitle">For a limited time, we offer dollar-per-dollar sweepstakes entries on all purchases.</p>
            </div>
        </div>

        <div class="games-selection" *ngIf="providers.length > 1">
            <form [formGroup]="providersForm" class="form-simple form-simple--shrink form-simple--primary">
                <div class="form-simple__item">
                    <mat-form-field>
<!--                        <mat-label>GET</mat-label>-->
                        <mat-select #providersSelect
                                    formControlName="providers"
                                    (valueChange)="onProviderChange($event)"
                                    [(value)]="selectedProvider" panelClass="my-select-panel-border-yellow">
                            <mat-select-trigger>
                                {{providersSelect?.value?.name}}
                            </mat-select-trigger>
                            <mat-option *ngFor="let provider of providers" [value]="provider">
                                {{provider.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </div>

        <div *ngIf="riverView" class="rewards__info">
            <div *ngIf="userPin" class="rewards__pin">
                <div id="rewards_code" class="rewards__code">{{userPin}}</div>
            </div>
        </div>


        <div *ngIf="goldenDragonView" class="rewards__info rewards__info--mb">
            <div class="rewards__row">
                <div class="rewards__col">
                    Mobile ID:
                </div>
                <div class="rewards__col">
                    {{gd_mobile_id}}
                </div>
            </div>

            <div class="rewards__row">
                <div class="rewards__col">
                    Password:
                </div>
                <div class="rewards__col">
                    {{gd_password}}
                </div>
            </div>
        </div>

        <div class="page__body">
            <div class="game" *ngIf="activeProvider">
                <div class="game__item">
                    <img [src]="activeProvider.image_link"
                         alt=""
                         class="game__img"
                         (click)="onSweeps(true)">

                    <div class="game__content">
                        <div class="game__description" [innerHTML]="activeProvider.description"></div>
                        <button (click)="onSweeps(true)" class="game__btn">PLAY</button>

                        <button (click)="onShowSupportForm()" class="game__btn">Support</button>
                    </div>
                </div>

                <div class="rewards__footer">
                    <div class="rewards__btns">
                        <a [routerLink]="getLinkToNFT()" class="navigation__button navigation__button--rounded is-notouched">
                            <span class="navigation__button-text">MINT <br>NFT</span>
                            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M85 43C85 19.804 66.196 1 43 1M1 43C1 66.196 19.804 85 43 85C54.6712 85 65.2305 80.2394 72.8421 72.5542" stroke-width="2"/>
                            </svg>
                        </a>
                        <a [routerLink]="getLinkToFunds()" class="navigation__button navigation__button--rounded is-notouched">
                            <span class="navigation__button-text">ADD <br>FUNDS</span>
                            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M85 43C85 19.804 66.196 1 43 1M1 43C1 66.196 19.804 85 43 85C54.6712 85 65.2305 80.2394 72.8421 72.5542" stroke-width="2"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-support [showSupport]="showSupportForm" (close)="onSupportClose($event)"></app-support>
