import {HERO_VERSIONS} from "./hero_versions.model";
import {RouterPaths} from "./router.paths.model";

export const PROJECT_CONFIG = {
    defaultAvatar: '../../../../assets/images/default_user.png',
    defaultWomanAvatar: '../../../../assets/images/woman-avatar.jpg',
    defaultImage: '../../../../assets/images/default_image.png',
    defaultImage2: '../../../../assets/images/default/no-image.jpeg',
    defaultCryptoIcon: 'cryptocurrency.png',
    transactionsTypeDebit: '1',
    transactionsTypeCredit: '2',
    postPaymentTypeFree: 1,
    postPaymentTypePay: 0,
    paymentMethodCard: 1,
    paymentMethodPaypal: 2,
    defaultDomain: 'user.eboxenterprises.com',
    pointSubdomainEbox: 'user',
    pointSubdomainEboxDemo: 'user.dev',
    heroVersion: HERO_VERSIONS.crypto,
};

export const PROJECT_MESSAGES = {
    unknownError: 'Sorry there was an error try again later.',
    notAllFieldsAreFilled: 'Please, fill all required fields.',
    incorrectDataFormat: 'Incorrect data format.',
    askLoginForTrade: 'Please login to start traiding.'
}

export const PROJECT_CLASSES = {
    hidden: 'is-hidden'
}

export const PROVIDERS_GAME_STATUS = {
    notAvailable: 2
}

export const PROVIDERS_TEMPLATES = {
    mascotSingle: 'mascotSingle'
}

export const TIMERS_CONFIG = {
    popupHideAfter: 5000
}

export const PAGES_WITHOUT_LOGIN = [
  RouterPaths.transactionProcessing,
  RouterPaths.massSms,
  RouterPaths.promo777
]
