import {Component, ElementRef, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MascotService} from "../../../service/mascot/mascot.service";
import {LoaderService} from "../../../service/loader.service";
import {GameService} from "../../../service/games/gameService.service";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {DomSanitizer} from "@angular/platform-browser";
import {DateService} from "../../../service/date.service";
import {FullScreenService} from "../../../service/fullScreen.service";
import {NgxGlideComponent} from "ngx-glide";
import {POPUP_TYPE} from "./mascot-sliders.config";
import {ScratchOffService} from "../../../service/games/scratchOff.service";
import {SpinnerService} from "../../../service/games/spinner.service";
import {ActivatedRoute, Router} from "@angular/router";
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PHONE_MASK} from "../../../config/country_phone_mask";
import {AuthenticationService} from "../../../modules/auth/_services/authentication.service";
import {BehaviorSubject} from "rxjs";
import {PartnerService} from "../../../service/partner.service";
import {UsersPointService} from "../../../service/users/users-point.service";
import {DeviceDetectionService} from "../../../service/deviceDetection.service";
import {SCRATCH_OFF_POPUP_TYPE} from "../../games/scratch-off/scratch-off-rules/scratch-off-rules.config";
import {FrontendTestSettingsService} from "../../../service/testing/frontendTestSettings.service";

@Component({
    selector: 'app-mascot-sliders',
    templateUrl: './mascot-sliders.component.html',
    styleUrls: ['./mascot-sliders.component.scss']
})
export class MascotSlidersComponent implements OnInit {
    showPopup = false;
    isStationMascot;

    bodyAdditionalClass = 'is-mascot';

    singlePage = false;

    popupGameImage;
    popupGameId;
    popupGameURL;
    popupType: number;
    popupLoading = false;
    POPUP_TYPE = POPUP_TYPE;

    showGameFindPrize = false;
    showGamePayment = false;
    rulesPopupType: string;

    games = [];
    slider1 = [];
    slider2 = [];

    slidesPerView = 2;
    slideWidth = 285;
    sliderAnimationDuration = 2000;
    sliderAnimationFunction = 'cubic-bezier(0.65, 0, 0.35, 1)';
    sliderAnimationDurationOnClick = 400;

    slideResolution = 1.458;
    slideHeightRate = .423;

    spinnerData;

    numOfDigits = 4;

    balance = {
        freeRounds: 0,
        win: 0,
        jackpot: 0,
        balance: 0
    };

    jackpotDynamicText = new BehaviorSubject(null);

    @ViewChild('newSwiper', {static: false}) newSwiper: NgxGlideComponent;
    @ViewChild('newSwiper2', {static: false}) newSwiper2: NgxGlideComponent;

    @ViewChild('textData', {static: false}) textData: ElementRef;
    @ViewChild('text', {static: false}) text: ElementRef;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.setSlideWidth();

        setTimeout(() => {
            if (this.newSwiper) {
                this.newSwiper.update({perView: this.slidesPerView, autoplay: false});
            }
            if (this.newSwiper2) {
                this.newSwiper2.update({perView: this.slidesPerView, autoplay: false});
            }
        });
    }

    @HostListener('mousemove', ['$event'])
    onMouseMove(event) {
        this.newSwiper.update({autoplay: false, animationDuration: this.sliderAnimationDurationOnClick});
        this.newSwiper2.update({autoplay: false, animationDuration: this.sliderAnimationDurationOnClick});
        this.onMouseMove = () => {};
    }

    @HostListener('window:orientationchange', ['$event'])
    onOrientationChange(event) {

        this.setSlideWidth();

        setTimeout(() => {
            if (this.newSwiper) {
                this.newSwiper.update({perView: this.slidesPerView, autoplay: false});
            }
            if (this.newSwiper2) {
                this.newSwiper2.update({perView: this.slidesPerView, autoplay: false});
            }
        });

    }

    constructor(
        private mascotService: MascotService,
        private scratchOffService: ScratchOffService,
        private loaderService: LoaderService,
        private gameService: GameService,
        private popupComponent: PopupComponent,
        private spinnerService: SpinnerService,
        public sanitizer: DomSanitizer,
        private dateService: DateService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder,
        private fb: FormBuilder,
        private authenticationService: AuthenticationService,
        private partnerService: PartnerService,
        private fullScreenService: FullScreenService,
        private deviceDetectionService: DeviceDetectionService,
    ) {

        this.mascotService.balanceUpdated.subscribe(val => {
           if (val) {
               this.getBalance();
           }
        });

        this.jackpotDynamicText.subscribe(val => {
            if (this.text !== undefined && this.textData !== undefined) {
                setTimeout(() => {
                    this.text.nativeElement.innerHTML = this.textData.nativeElement.innerHTML;
                });
            }
        });
    }


    ngOnInit() {
        this.setBodyMascotClass();
        this.initGame();

        this.checkSinglePage();

        this.setAndroidSettings();
    }

    ngOnDestroy(){
        this.removeBodyMascotClass();
    }

    setBodyMascotClass() {
        document.body.classList.add(this.bodyAdditionalClass);
        setTimeout(() => {
            this.popupComponent.loadPopupImages();
        });
    }

    removeBodyMascotClass() {
        document.body.classList.remove(this.bodyAdditionalClass);
    }

    checkSinglePage() {
        this.getBalance();
    }

    setJackpot(value) {
        this.balance.jackpot = value;
        this.jackpotDynamicText.next(value);
    }

    initGame() {
        this.getData();
        this.setSlideWidth();
    }

    setSlideWidth() {
        const docElement = document.documentElement;
        // const docElementWidth = document.documentElement.clientWidth;
        // const docElementHeight = document.documentElement.clientHeight;

        // New
        const docElementWidth = window.innerWidth;
        const docElementHeight = window.innerHeight;

        const isPortraitOrientation = docElementWidth < docElementHeight;
        const viewportHeight =  isPortraitOrientation ? docElementWidth : docElementHeight;
        const viewportWidth =  isPortraitOrientation ? docElementHeight : docElementWidth;
        const slideHeight = viewportHeight * this.slideHeightRate;

        if (slideHeight) {
            this.slideWidth = slideHeight / this.slideResolution;
        }

        this.slidesPerView = viewportWidth / this.slideWidth;
    }

    fullScreen() {
        if (!this.isAndroid()) {
            this.fullScreenService.toggleFullScreen();
        }
    }

    detectClickOnGame() {
        document.querySelectorAll('.mascot-slider__slide').forEach(el => {
            el.addEventListener('click', (e) => {
                const id = (<HTMLElement>e.currentTarget).getAttribute('data-id');
                this.openGame(id);
            })
        })
    }

    getBalance() {
        if(this.authenticationService.isUserLogged()){
            this.mascotService.getBalance().subscribe(data => {
                this.setBalance(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain('bordered');
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    getData() {
        if (!this.games.length) {
            this.mascotService.getIcons().subscribe(data => {
                this.setData(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain('bordered');
            }, () => {
                this.loaderService.hide();
            });
        }

    }

    setData(data) {
        try {
            this.games = data.result;
            const middle = Math.round(this.games.length / 2);

            this.slider1 = this.games.slice(0,middle);
            this.slider2 = this.games.slice(middle);

            this.slider1.push(...this.slider1);
            this.slider2.push(...this.slider2);

            this.spinnerData = data.wheel;

            this.setJackpot(data.jackpot);
            setTimeout(() => {
                this.detectClickOnGame();
            });
        } catch (e) {
        }

    }

    setBalance(data) {
        if (data && data.status) {
            this.balance.win = data.wins;
            this.balance.balance = data.balance;
            this.balance.freeRounds = data.freerounds;
            this.setJackpot(data.jackpot);
        } else {
            if (this.jackpotDynamicText.value === null) {
                this.setJackpot(0);
            }

            //this.popupComponent.showPopupError({text: data.message}, 'btn-ok')
        }
    }

    openGame(id?) {
        this.popupLoading = true;
        if (id) {
            const checkedGame = this.games.find(el => +el.id === +id);
            this.popupType = POPUP_TYPE.play;
            this.popupGameImage = checkedGame.image1600;
            this.popupGameId = checkedGame.id;
        } else {
            this.setPayment();
        }
        this.showPopup = true;
    }

    setPayment() {
        this.popupType = POPUP_TYPE.buy;
        this.popupLoading = true;
        this.showGamePayment = true;
        this.rulesPopupType = SCRATCH_OFF_POPUP_TYPE.pigRules;
    }

    isPaymentReady(value) {
        if (value) {
            this.popupLoading = false;
        } else {
            this.showGamePayment = false;
            this.showPopup = false;

        }
    }

    missPayment() {
        this.showGame();
    }

    closeGame() {
        this.popupGameURL = null;
        this.showPopup = false;
        this.popupGameImage = null;
        this.showGameFindPrize = false;
        this.showGamePayment = false;

        this.mascotService.updateBalance();
    }

    playGame(id) {
        if (id) {
            this.popupGameImage = null;
            this.popupLoading = true;
            this.gameService.getGameUrl(id).subscribe(data => {
                this.checkSubmitResult(data);
            }, () => {
                this.loaderService.hideAndShowTryAgain('bordered');
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }

        // this.popupGameImage = null;
        // this.popupLoading = true;
        // setTimeout(() => {
        //     this.popupGameURL = this.getLink('https://kxys05e7341auuy.mascot.games');
        //     this.popupLoading = false;
        // }, 2000);
    }

    getLink(url){
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    checkSubmitResult(responce) {
        if(responce.status === true){
            this.popupGameURL = this.getLink(responce.result);
            this.popupLoading = false;
        }else if (responce.status === false) {
            this.popupComponent.showPopupError({text: responce.message}, 'btn-ok');
        } else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }
    }

    imageLoaded() {
        this.popupLoading = false;
    }


    showGame() {
        this.showGamePayment = false;
        this.showGameFindPrize = true;
        // this.popupLoading = false;
        this.popupLoading = true;
    }

    onRulesActionBtnClick() {
        if (this.isWelcomeRulesPopup()) {
            this.closeGame();
        } else {
            this.showGame();
        }
    }

    isWelcomeRulesPopup() {
        return this.rulesPopupType === SCRATCH_OFF_POPUP_TYPE.welcome;
    }

    closeScratchOffGame() {
        this.showGamePayment = true;
        this.showGameFindPrize = false;
    }

    logout() {
        this.router.navigate(['/home/settings']);
    }

    onLogout() {
        this.logout();
    }

    frameClick() {
        // console.log('frameClick')
    }

    clearBalance() {
        this.balance.freeRounds = 0;
        this.balance.balance = 0;
        this.balance.win = 0;
    }

    isIphone() {
        return this.deviceDetectionService.isIphone();
    }

    isIpad() {
        return this.deviceDetectionService.isIpad();
    }

    isAndroid() {
        return this.deviceDetectionService.isAndroid();
    }

    setAndroidSettings() {
        if (this.isAndroid()) {
            document.addEventListener('click', this.fullScreenService.setFullScreen);
        }
    }

    goToPreviousSlide(slider) {
        slider.go('<');
    }

    goToNextSlide(slider) {
        slider.go('>');
    }
}
