import * as i0 from "@angular/core";
var UsersPointService = /** @class */ (function () {
    function UsersPointService() {
        this.storage = sessionStorage;
        this.keyName = 'userPoint';
        this.keyPointHash = 'pointHash';
    }
    UsersPointService.prototype.saveHashInStorage = function (hash) {
        this.storage.setItem(this.keyPointHash, hash);
    };
    UsersPointService.prototype.getHash = function () {
        if (sessionStorage.getItem(this.keyPointHash)) {
            try {
                return sessionStorage.getItem(this.keyPointHash);
            }
            catch (e) {
            }
        }
        return '';
    };
    UsersPointService.prototype.getSubdomain = function () {
        var domain = window.location.hostname;
        var parts = domain.split('.');
        parts.pop();
        parts.pop();
        //alert(this.usersPointService.getSubdomain());
        return parts.join('.');
    };
    UsersPointService.prototype.setUserPointId = function (id) {
        this.storage.setItem('userPointId', id);
    };
    UsersPointService.prototype.getUserPointId = function () {
        return this.storage.getItem('userPointId');
    };
    UsersPointService.prototype.setPointPlayersEarnIncome = function (id) {
        this.storage.setItem('pointPlayersEarnIncome', id);
    };
    UsersPointService.prototype.setAccount = function (data) {
        this.storage.setItem(this.keyName, JSON.stringify(data));
    };
    UsersPointService.prototype.isPointPlayersEarnIncome = function () {
        if (sessionStorage.getItem('pointPlayersEarnIncome')) {
            try {
                var pointPlayersEarnIncome = sessionStorage.getItem('pointPlayersEarnIncome');
                if (pointPlayersEarnIncome === '0') {
                    return false;
                }
            }
            catch (e) { }
        }
        return true;
    };
    UsersPointService.prototype.getId = function () {
        if (sessionStorage.getItem(this.keyName)) {
            try {
                var currentUser = JSON.parse(sessionStorage.getItem(this.keyName));
                return currentUser.id;
            }
            catch (e) {
            }
        }
        return '';
    };
    UsersPointService.prototype.getPointName = function () {
        if (sessionStorage.getItem(this.keyName)) {
            try {
                var currentUser = JSON.parse(sessionStorage.getItem(this.keyName));
                return currentUser.point_name;
            }
            catch (e) {
            }
        }
        return '';
    };
    UsersPointService.prototype.setGameVendorShortName = function (name) {
        this.storage.setItem('gameVendorShortName', name);
    };
    UsersPointService.prototype.setGroupId = function (id) {
        this.storage.setItem('groupId', id);
    };
    UsersPointService.prototype.getGroupId = function () {
        return this.storage.getItem('groupId');
    };
    UsersPointService.prototype.getGameVendor = function () {
        return this.storage.getItem('gameVendorShortName');
    };
    UsersPointService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UsersPointService_Factory() { return new UsersPointService(); }, token: UsersPointService, providedIn: "root" });
    return UsersPointService;
}());
export { UsersPointService };
