<form *ngIf="!cashappVerified && !isPaypal" [formGroup]="cashappForm" (ngSubmit)="onSubmitCashappForm()" class="form-simple form-simple--primary">
    <div class="form-simple__item">
        <input formControlName="address"
               placeholder="Enter CashApp Address"
               [type]="'text'"
               class="form-simple__input"
               autocomplete="off">
    </div>

    <div class="form-simple__item">
        <input formControlName="retype"
               placeholder="Retype CashApp Address"
               [type]="'text'"
               class="form-simple__input"
               autocomplete="off">
    </div>

    <!--<h4 class="form-simple__subtitle">For your security only one cashapp address is accepted per active account</h4>-->

    <div class="form-simple__btns">
        <button
                class="navigation__button button snake-btn is-notouched"
                [disabled]="cashappForm.invalid || cashappForm.controls.address.value !== cashappForm.controls.retype.value">
            Verify Address
            <svg><rect></rect></svg>
        </button>
    </div>
</form>

<form *ngIf="!cashappVerified && isPaypal" [formGroup]="paypalForm" (ngSubmit)="onSubmitPaypalForm()" class="form-simple form-simple--primary">
    <div class="form-simple__item">
        <input formControlName="email"
               placeholder="Email"
               [type]="'text'"
               email="true"
               class="form-simple__input"
               autocomplete="off">
    </div>

    <div class="form-simple__item">
        <input formControlName="retype"
               placeholder="Retype Email"
               [type]="'text'"
               email="true"
               class="form-simple__input"
               autocomplete="off">
    </div>

    <!--<h4 class="form-simple__subtitle">For your security only one email address is accepted per active account</h4>-->

    <div class="form-simple__btns">
        <button
                class="navigation__button button snake-btn is-notouched"
                [disabled]="paypalForm.invalid || paypalForm.controls.email.value !== paypalForm.controls.retype.value">
            Verify Email
            <svg><rect></rect></svg>
        </button>
    </div>
</form>
