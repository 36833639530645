import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SettingsLoaderService} from "../settingsLoader.service";
import {LoaderService} from "../loader.service";
import {GAMES_CONFIG} from "../../config/api";

@Injectable({
    providedIn: 'root'
})
export class GameService extends SettingsLoaderService{
    private config = GAMES_CONFIG;

    constructor(private http: HttpClient, public loaderService: LoaderService) {
        super(loaderService);
    }

    getGameUrl(id) {
        this.showLoader();
        let post = this.http.post<any>(this.config.getGameUrl, {id});
        return post;
    }
}
