import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {UserIdleService} from '../user-idle/user-idle.service';
import {Router} from '@angular/router';
import {AuthConfig} from './auth-config';
import {AUTH_CONFIG} from '../../../config/api';
import {BehaviorSubject, Subject} from 'rxjs';
import {UsersPointService} from '../../../service/users/users-point.service';
import {SettingsLoaderService} from "../../../service/settingsLoader.service";
import {LoaderService} from "../../../service/loader.service";
import {__await} from "tslib";
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends SettingsLoaderService  {
    private config = AUTH_CONFIG;

    private storage = sessionStorage;
    roles: string[];
    rolesObservable = new EventEmitter<any>();
    userLoggedIn = new EventEmitter<any>();
    private authStamp;

    userLoggedStatusChanged = new BehaviorSubject(this.isUserLogged());

    constructor(@Inject(AuthConfig) private authConfig: AuthConfig,
                private http: HttpClient,
                private userIdle: UserIdleService,
                private usersPointService: UsersPointService,
                private router: Router,
                public loaderService: LoaderService) {
        super(loaderService);
    }

    isUserLogged() {
        return !!this.storage.getItem('currentUser');
    }

    sendPin(phone: string){
        return this.http.post<any>(this.config.sendPin, {phone});
    }

    checkUser(phone: string,userName: string){
        return this.http.post<any>(this.config.checkUser, {phone:phone,userName:userName});
    }

    checkPhoneAndSendPin(phone: string, country: string){
        this.showLoader();
        let pointHash = this.usersPointService.getHash();

        return this.http.post<any>(this.config.checkPhoneAndSendPin, {phone:phone, country: country,pointHash:pointHash});
    }

    registerTerminal(login, password){
        this.showLoader();
        return this.http.post<any>(this.config.registerTerminal, {login, password});
    }

    // register(name: string, email: string, phone: string, zip: string, requests: string){
    //     return this.http.post<any>(this.config.register, {name:name, email:email, phone:phone, zip:zip,requests:requests}).pipe(map((res: any) => {
    //         if (res.status) {
    //             //this.setData(res.result);
    //         }
    //
    //         return res;
    //     }));
    // }

    register(phone: string, pin: string, country: string,accountId:string=''){
        let pointHash = this.usersPointService.getHash();

        return this.http.post<any>(this.config.register, {phone:phone, pin:pin, country: country,pointHash:pointHash,accountId:accountId});
    }

    newCustomer(phone: string, country: string){
        return this.http.post<any>(this.config.newCustomer, {phone:phone, country: country});
    }

    newCustomerVirtual(phone: string, country: string){
        return this.http.post<any>(this.config.newCustomerVirtual, {phone:phone, country: country});
    }

    findByAccountId(accountId: string, phone: string, country: string){
        let pointHash = this.usersPointService.getHash();
        return this.http.post<any>(this.config.findByAccountId, {accountId: accountId, pointHash:pointHash, phone:phone, country: country});
    }

    logoutRequest(){
        return this.http.post<any>(this.config.logout, {});
    }


    login(phone: string, pin: string, country: string) {
        this.showLoader();

        this.storage = sessionStorage;
        return this.http.post<any>(this.config.login, {phone, pin, country})
            .pipe(map((res: any) => {
                if (res.status) {
                    // todo change to res.token
                    if (environment.frontEndTest && res.result) {
                        this.setData(res.result.token);
                    } else {
                        this.setData(res.token);
                    }
                    this.userLoggedIn.emit(true);
                }

                return res;
            }));
    }

    setData(result: object){
        this.storage = sessionStorage;
        this.storage.setItem('currentUser', JSON.stringify({token: result}));
        this.userLoggedIn.emit(true);
    }

    async logout() {
        if (this.storage.getItem('currentUser')) {
            this.logoutRequest().toPromise();
        }

        this.clearStorage();

        this.userLoggedIn.emit(false);
        console.log('userLoggedStatusChanged 4')
        this.userLoggedStatusChanged.next(false);

        await this.checkSession();
    }

    clearStorage(){
        this.storage.removeItem('currentUser');
        this.storage.removeItem('userPoint');
        this.storage.removeItem('userPointId');
    }

    setStamp(){
        var now = Date.now();

        this.authStamp = now;

        //console.log(this.authStamp+'=1')

        let $this = this;
        setTimeout(function(){
            $this.setStamp();
        },1000);
    }

    checkStamp(){
        if (this.storage.getItem('currentUser')) {
            var now = Date.now();

            if(this.authStamp){
                if(now > (this.authStamp+3000000)){
                //if(now > (this.authStamp+60000)){
                    this.logout();

                    return;
                }else{
                    this.refreshTokenNow();
                }
            }else{
                this.refreshTokenNow();
            }
        }
    }

    refreshTokenNow(){
        if (this.storage.getItem('currentUser')) {
            this.http.post<any>(this.config.refresh, {}).subscribe(res => {
                if (res.status) {
                    this.setData(res.result.token);
                }else{
                    console.log('refreshTokenNow');

                    this.router.navigate(['/']);
                }

                return res;
            });
        }

        return false;
    }

    refreshToken() {
        let $this = this;
        if (this.storage.getItem('currentUser')) {
            this.http.post<any>(this.config.refresh, {}).subscribe(res => {
                if (res.status) {
                    this.setData(res.result.token);
                }else{
                    console.log('refreshToken');

                    this.router.navigate(['/']);
                }

                setTimeout(function(){
                    console.log("test_test: 2");
                    $this.refreshToken();
                },60*1*1000);
            });
        }else{
            setTimeout(function(){
                console.log("test_test: 3");
                $this.refreshToken();
            },60*1*1000);
        }
    }

    async checkSession() {

        // this.storage = sessionStorage;
        // if (this.storage.getItem('currentUser')) {
        //
        //     this.refreshToken();
        // } else {
        //     this.router.navigate([this.authConfig.loginRoute],
        //         {
        //             queryParams: this.authConfig.loginRoute !== location.pathname ?
        //                 {
        //                     returnUrl:
        //                     location.pathname
        //                 } : null
        //         });
        //
        // }
    }

    getCurrentUser(){
        if (sessionStorage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

                return currentUser;
            }catch (e) {

            }
        }

        return false;
    }

    getToken(){
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(this.storage.getItem('currentUser'));

                return currentUser.token;
            }catch (e) {

            }

        }

        return '';
    }

    getUserRiverCode(){
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(this.storage.getItem('currentUser'));

                return currentUser.river_pay_account_id;
            }catch (e) {

            }

        }

        return '';
    }

    getRole(){
        this.storage = sessionStorage;
        if (this.storage.getItem('currentUser')) {
            try {
                const currentUser = JSON.parse(this.storage.getItem('currentUser'));

                return currentUser.role_id;
            }catch (e) {

            }

        }

        return '';
    }


}
