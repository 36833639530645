<div class="page page--tertiary page--scrolled long-content" [class.is-blurred]="showNFTPopup">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>

        <h1 class="page__title">NFT</h1>

        <div class="page__body" #scrolledEl>
            <div class="dropdown">
                <div class="dropdown__head">
                    <div class="dropdown__title">
                        NFT WALLET
                    </div>
                    <div class="dropdown__total">
                        <svg class="dropdown__icon dropdown__icon--coin">
                            <use [attr.xlink:href]="'../../../../assets/images/sprite.svg#polygonscan'"></use>
                        </svg>
                    </div>
                </div>
                <div class="dropdown__body">
                    <div class="wallet" [class.wallet--flex]="!nftData.wallet">

                        <div class="wallet__info" *ngIf="nftData.wallet">
                            <img [src]="nftData.qrCode" alt="" *ngIf="nftData.qrCode" class="wallet__qr-code">

                            <div class="wallet__content">
                                <div class="wallet__address">{{nftData.wallet}}</div>

                                <div class="wallet__row">
                                    <button class="button button--default button--small"
                                            (click)="copyToBuffer()">COPY</button>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div class="dropdown" *ngFor="let item of nftData.library">
                <div class="dropdown__head">
                    <div class="dropdown__title">
                        Level {{item.level}}
                    </div>
                </div>
                <div class="dropdown__body" *ngIf="nftData.library.length">
                    <div class="library">
                        <ngx-glide #librarySlider
                                   perView="3"
                                   [showBullets]="false"
                                   [showArrows]="false"
                                   [type]="item.items.length > NFTLibraryMaxSlides ? 'carousel' : 'slider'"
                                   [dragThreshold]="item.items.length > NFTLibraryMaxSlides"
                                   [swipeThreshold]="item.items.length > NFTLibraryMaxSlides">
                            <button [attr.data-id]="slide.uuid"
                                    *ngFor="let slide of item.items"
                                    class="slide library__slide js-library-slide">
                                <img class="slide library__slide-img" src="{{ slide.image_link }}" alt="" width="100%">
                            </button>
                        </ngx-glide>

                        <div class="library__controls" *ngIf="item.items.length > NFTLibraryMaxSlides">
                            <button class="library__control library__control--prev" aria-label="Previous" (click)="librarySlider.go('<')" >
                                <
                            </button>
                            <button class="library__control library__control--next" aria-label="Next" (click)="librarySlider.go('>')">
                                >
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-investments-nft-popup  *ngIf="showNFTPopup && NFTPopupData"
                            [popupData]="NFTPopupData.data"
                            [popupType]="NFTPopupData.type"
                            (close)="onPopupNFTClose($event)"></app-investments-nft-popup>
