import { OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { NFT_POPUP_TYPE } from "./investments-nft-popup/investments-nft-popup.config";
var InvestmentsComponent = /** @class */ (function () {
    function InvestmentsComponent(stylingService, loaderService, popupComponent, copyToBufferService, activateRoute, investmentsService, NFTService) {
        this.stylingService = stylingService;
        this.loaderService = loaderService;
        this.popupComponent = popupComponent;
        this.copyToBufferService = copyToBufferService;
        this.activateRoute = activateRoute;
        this.investmentsService = investmentsService;
        this.NFTService = NFTService;
        this.nftData = {
            wallet: '',
            qrCode: '',
            contractAddress: '',
            library: []
        };
        this.nftResponseKeys = {
            wallet: 'wallet',
            qrCode: 'walletQrcodeLink',
            contractAddress: 'contractAddress',
            library: 'library'
        };
        this.showNFTPopup = false;
        this.NFTLibraryMaxSlides = 3;
    }
    InvestmentsComponent.prototype.ngOnInit = function () {
        this.getData();
        this.handleLibrarySlidesClick();
    };
    InvestmentsComponent.prototype.handleLibrarySlidesClick = function () {
        var _this = this;
        document.body.addEventListener('click', function (event) {
            if (event.target.classList.contains('js-library-slide')) {
                var id_1 = event.target.getAttribute('data-id');
                var slideInfo = _this.nftData.items.find(function (el) { return el.uuid === id_1; });
                if (slideInfo) {
                    _this.onShowNFTPopup(slideInfo);
                }
            }
        });
    };
    InvestmentsComponent.prototype.getData = function () {
        var _this = this;
        this.investmentsService.getInfo().subscribe(function (data) {
            _this.setInfo(data);
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    InvestmentsComponent.prototype.setInfo = function (data) {
        try {
            this.setNFTData(data);
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    InvestmentsComponent.prototype.setNFTData = function (data) {
        var _this = this;
        if (data.nft) {
            var nftData_1 = data.nft;
            this.nftData.wallet = nftData_1[this.nftResponseKeys.wallet] ? nftData_1[this.nftResponseKeys.wallet] : '';
            this.nftData.qrCode = nftData_1[this.nftResponseKeys.qrCode] ? nftData_1[this.nftResponseKeys.qrCode] : '';
            this.nftData.contractAddress = nftData_1[this.nftResponseKeys.contractAddress] ? nftData_1[this.nftResponseKeys.contractAddress] : '';
            this.nftData.library = [];
            setTimeout(function () {
                _this.nftData.items = nftData_1[_this.nftResponseKeys.library] ? nftData_1[_this.nftResponseKeys.library] : [];
                _this.nftData.library = [];
                _this.nftData.items.forEach(function (img) {
                    var currentLevel = _this.nftData.library.find(function (nft) { return nft.level === img.level; });
                    if (currentLevel) {
                        currentLevel.items.push(img);
                    }
                    else {
                        _this.nftData.library.push({ level: img.level, type: "Level " + img.level, items: [img] });
                    }
                });
                _this.nftData.library.sort(function (a, b) { return a.level - b.level; });
            });
        }
    };
    InvestmentsComponent.prototype.onPopupNFTClose = function (needReload) {
        this.showNFTPopup = false;
        if (needReload) {
            this.getData();
        }
    };
    InvestmentsComponent.prototype.generateWallet = function () {
        var _this = this;
        this.NFTService.generateWallet().subscribe(function (data) {
            _this.updateWallet(data);
        }, function (error) {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    InvestmentsComponent.prototype.updateWallet = function (data) {
        var _a;
        try {
            if (data.status === true) {
                var result = {
                    nft: (_a = {},
                        _a[this.nftResponseKeys.wallet] = data[this.nftResponseKeys.wallet],
                        _a[this.nftResponseKeys.qrCode] = data[this.nftResponseKeys.qrCode],
                        _a[this.nftResponseKeys.contractAddress] = data[this.nftResponseKeys.contractAddress],
                        _a)
                };
                this.setNFTData(result);
            }
            else if (data.status === false) {
                this.popupComponent.showPopupError({ text: data.message });
            }
            else {
                this.loaderService.hideAndShowTryAgain();
            }
        }
        catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    };
    InvestmentsComponent.prototype.copyToBuffer = function () {
        var value = this.nftData.wallet;
        var message = 'Wallet address has been successfully copied!';
        this.copyToBufferService.copyToBuffer(value, true, message);
    };
    InvestmentsComponent.prototype.onShowNFTPopup = function (slide) {
        this.showNFTPopup = true;
        this.NFTPopupData = {
            type: NFT_POPUP_TYPE.info,
            data: {
                element: slide
            }
        };
    };
    return InvestmentsComponent;
}());
export { InvestmentsComponent };
