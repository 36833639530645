/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./addresses-settings.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/logo-bg/logo-bg.component.ngfactory";
import * as i3 from "../../../shared/logo-bg/logo-bg.component";
import * as i4 from "../../../service/partner.service";
import * as i5 from "@angular/router";
import * as i6 from "@angular/common";
import * as i7 from "./addresses-settings.component";
import * as i8 from "../../../shared/popup/popup.component";
import * as i9 from "../../../service/loader.service";
import * as i10 from "../../../service/styling.service";
import * as i11 from "../../../service/settings/addresses.service";
var styles_AddressesSettingsComponent = [i0.styles];
var RenderType_AddressesSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddressesSettingsComponent, data: { "animation": [{ type: 7, name: "slideToLeft", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { position: "fixed" }, offset: null }, options: undefined }, { type: 0, name: "*", styles: { type: 6, styles: { position: "fixed" }, offset: null }, options: undefined }, { type: 1, expr: ":enter", animation: [{ type: 6, styles: { transform: "translateX(100%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(0%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 6, styles: { transform: "translateX(0%)" }, offset: null }, { type: 4, styles: { type: 6, styles: { transform: "translateX(100%)" }, offset: null }, timings: "0.5s ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_AddressesSettingsComponent as RenderType_AddressesSettingsComponent };
function View_AddressesSettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "cards__item"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "cards__left cards__title"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "cards__right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "button", [["class", "cards__btn cards__btn--edit js_address_save"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, ":svg:svg", [["height", "25"], ["width", "25"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#edit"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [["class", "cards__btn cards__btn--del js_address_delete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.askDelete(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, ":svg:svg", [["height", "22"], ["width", "17"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, ":svg:use", [[":xlink:href", "../../../../assets/images/sprite.svg#delete"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.address; _ck(_v, 2, 0, currVal_0); }); }
export function View_AddressesSettingsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { scrolledEl: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 27, "div", [["class", "page page--quaternary long-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 26, "div", [["class", "page__inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-logo-bg", [], null, null, null, i2.View_LogoBgComponent_0, i2.RenderType_LogoBgComponent)), i1.ɵdid(4, 114688, null, 0, i3.LogoBgComponent, [i4.PartnerService], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 4, "a", [["class", "page__back page__back--mobile page__back--mobile-text"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "visually-hidden"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Back"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "h1", [["class", "page__title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Paper Checks"])), (_l()(), i1.ɵeld(12, 0, null, null, 6, "div", [["class", "page__centered"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 5, "a", [["class", "button snake-btn is-notouched"], ["id", "add_address"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(15, 1), (_l()(), i1.ɵted(-1, null, [" ADD address "])), (_l()(), i1.ɵeld(17, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 3, "div", [["class", "page__body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, [[1, 0], ["scrolledEl", 1]], null, 2, "div", [["class", "cards"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AddressesSettingsComponent_1)), i1.ɵdid(22, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(23, 0, null, null, 5, "a", [["class", "button page__back page__back--desktop"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 24).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(24, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i6.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(25, 1), (_l()(), i1.ɵted(-1, null, [" Back "])), (_l()(), i1.ɵeld(27, 0, null, null, 1, ":svg:svg", [], null, null, null, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 0, ":svg:rect", [], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; _ck(_v, 4, 0); var currVal_2 = _ck(_v, 7, 0, "/home/settings"); _ck(_v, 6, 0, currVal_2); var currVal_5 = _ck(_v, 15, 0, "/home/settings/addresses/add"); _ck(_v, 14, 0, currVal_5); var currVal_6 = _co.addresses; _ck(_v, 22, 0, currVal_6); var currVal_9 = _ck(_v, 25, 0, "/home/settings"); _ck(_v, 24, 0, currVal_9); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).target; var currVal_1 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_3 = i1.ɵnov(_v, 14).target; var currVal_4 = i1.ɵnov(_v, 14).href; _ck(_v, 13, 0, currVal_3, currVal_4); var currVal_7 = i1.ɵnov(_v, 24).target; var currVal_8 = i1.ɵnov(_v, 24).href; _ck(_v, 23, 0, currVal_7, currVal_8); }); }
export function View_AddressesSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-addresses-settings", [], [[40, "@slideToLeft", 0]], null, null, View_AddressesSettingsComponent_0, RenderType_AddressesSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i7.AddressesSettingsComponent, [i8.PopupComponent, i5.Router, i9.LoaderService, i10.StylingService, i11.AddressesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
var AddressesSettingsComponentNgFactory = i1.ɵccf("app-addresses-settings", i7.AddressesSettingsComponent, View_AddressesSettingsComponent_Host_0, {}, {}, []);
export { AddressesSettingsComponentNgFactory as AddressesSettingsComponentNgFactory };
