export const GAME_VENDORS = {
  river: 'river',
  mascot: 'mascot',
  mascotCard: 'baccarat',
  mascotKeno: 'keno',
  mascotCasino: 'mascot_casino',
  goldenDragon: 'gd',
  ebox: 'ebox'
};

export const GAME_VENDOR_LINK = {
  river: 'https://river777.net',
  goldenDragon: 'https://web.goldendragoncity.com'
};

export const GAME_VENDORS_RIVER = GAME_VENDORS.river;
export const GAME_VENDORS_MASCOT = GAME_VENDORS.mascot;
export const GAME_VENDORS_MASCOT_CARD = GAME_VENDORS.mascotCard;
export const GAME_VENDORS_MASCOT_KENO = GAME_VENDORS.mascotKeno;
export const GAME_VENDORS_MASCOTCASINO = GAME_VENDORS.mascotCasino;
export const GAME_VENDORS_GOLDENDRAGON = GAME_VENDORS.goldenDragon;
export const GAME_VENDORS_EBOX = GAME_VENDORS.ebox;
