import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LoaderService} from "../../service/loader.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PRIZES_METHODS} from "../../pages/prizes/prizes.config";

@Component({
    selector: 'app-popup-prizes-confirm',
    templateUrl: './popup-prizes-confirm.component.html',
    styleUrls: ['./popup-prizes-confirm.component.scss']
})
export class PopupPrizesConfirmComponent implements OnInit {
    @Input() popupInfo;
    @Output() close: EventEmitter<boolean> = new EventEmitter();
    @Output() changeHandle: EventEmitter<{type: string, handle: string}> = new EventEmitter();
    @Output() confirm: EventEmitter<any> = new EventEmitter();

    form: FormGroup;
    moreThenAvailablePayout = false;
    selectedMethod;

    PRIZES_METHODS = PRIZES_METHODS;
    constructor(private loaderService: LoaderService,
                private formBuilder: FormBuilder) {}

    ngOnInit() {
        this.form = this.formBuilder.group({
            handle: [null],
            amount: [null, Validators.required]
        });

    }

    ngOnChanges(changes) {
        if (!changes.popupInfo.currentValue.show && this.form) {
            this.form.controls.amount.setValue(null);
            this.form.controls.handle.setValue(null);
        }
        if (changes.popupInfo.currentValue.show) {
            this.selectedMethod = this.popupInfo.providers[0];
            this.form.controls.handle.setValue(this.selectedMethod);
        }
    }

    setCashappTagVerified($event) {
        this.popupInfo.cashappVerified = true;
        this.updateHandle(this.popupInfo.type, $event);
    }

    setPaypalVerified($event) {
        this.popupInfo.paypalAddress = true;
        this.updateHandle(this.popupInfo.type, $event);
    }

    updateHandle(type, handle) {
        this.form.controls.handle.setValue(handle);
        this.changeHandle.emit({type: type, handle: handle});
    }

    closePopup() {
        this.close.emit(true);
    }

    onSubmitForm() {
        if (this.popupInfo.type === this.PRIZES_METHODS.games) {
            this.updateHandle(this.PRIZES_METHODS.games, this.form.controls.handle.value)
        }
        this.confirm.emit({amount: this.form.controls.amount.value, type: this.popupInfo.type});
    }

    onInputAmount() {
        const avaliable = typeof this.popupInfo.totalDebit === 'number' ? this.popupInfo.totalDebit : Number.parseFloat(this.popupInfo.totalDebit);
        const entered = Number.parseFloat(this.form.controls.amount.value);

        if (avaliable < entered) {
            this.form.controls.amount.setValue(String(avaliable));
            this.moreThenAvailablePayout = true;
        } else {
            this.moreThenAvailablePayout = false;
        }
    }
}
