/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./shared/loader/loader.component.ngfactory";
import * as i4 from "./shared/loader/loader.component";
import * as i5 from "./service/loader.service";
import * as i6 from "./pages/install/install.component.ngfactory";
import * as i7 from "./pages/install/install.component";
import * as i8 from "./service/install.service";
import * as i9 from "./service/partner.service";
import * as i10 from "./app.component";
import * as i11 from "./service/phone.service";
import * as i12 from "./service/app-version/appVersionr.service";
import * as i13 from "./service/prizes/prizes.service";
import * as i14 from "ng-connection-service";
import * as i15 from "./shared/popup/popup.component";
import * as i16 from "./modules/auth/_services/authentication.service";
import * as i17 from "./service/users/users-point.service";
import * as i18 from "./service/app/appTest.service";
import * as i19 from "./service/app/appPrompt.service";
import * as i20 from "./service/hero.service";
import * as i21 from "./service/testing/frontendTestSettings.service";
import * as i22 from "@angular/common";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-loader", [], null, null, null, i3.View_LoaderComponent_0, i3.RenderType_LoaderComponent)), i1.ɵdid(3, 114688, null, 0, i4.LoaderComponent, [i5.LoaderService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-install", [], null, [["window", "beforeinstallprompt"]], function (_v, en, $event) { var ad = true; if (("window:beforeinstallprompt" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onbeforeinstallprompt($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_InstallComponent_0, i6.RenderType_InstallComponent)), i1.ɵdid(5, 114688, null, 0, i7.InstallComponent, [i8.InstallService, i9.PartnerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 3, 0); _ck(_v, 5, 0); }, null); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [[null, "click"], ["window", "beforeinstallprompt"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).clickInside($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:beforeinstallprompt" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onbeforeinstallprompt($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 245760, null, 0, i10.AppComponent, [i2.Router, i11.PhoneService, i12.AppVersionrService, i8.InstallService, i13.PrizesService, i14.ConnectionService, i15.PopupComponent, i16.AuthenticationService, i2.ActivatedRoute, i17.UsersPointService, i18.AppTestService, i19.AppPromptService, i20.HeroService, i21.FrontendTestSettingsService, i22.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i10.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };
