<div class="investments-popup investments-popup--pay" [class.is-active]="popupInfo.show">
    <div class="investments-popup__inner">
        <button class="investments-popup__close" (click)="closePopup()"></button>
<!--        <div class="investments-popup__header investments-popup__header&#45;&#45;centered">Money has been deposited into the terminal</div>-->

        <div class="investments-popup__title-pay">
            <p *ngIf="popupInfo.type === PRIZES_METHODS.cashapp && !popupInfo.cashappVerified">
                Please enter your CashApp tag. Only ONE CashApp tag is allowed per active account.
            </p>
            <p *ngIf="popupInfo.type === PRIZES_METHODS.cashapp && popupInfo.cashappVerified">
                Daily banking hours transfers are done via CashApp. To change your CashApp Tag, contact support.
            </p>

            <p *ngIf="popupInfo.type === PRIZES_METHODS.paypal && !popupInfo.paypalAddress">
                Please enter the email account registered with your PayPal. Only ONE PayPal email is allowed per active account.
            </p>
            <p *ngIf="popupInfo.type === PRIZES_METHODS.paypal && popupInfo.paypalAddress">
                Afterhours transfers are done via PayPal. To change your PayPal email, contact support.
            </p>
        </div>

        <div class="page__form group-bordered-inner" *ngIf="popupInfo.type === PRIZES_METHODS.cashapp && !popupInfo.cashappVerified">
            <app-verify-cashapp-tag [verifyStatus]="popupInfo.cashappVerified" (onVerify)="setCashappTagVerified($event)"></app-verify-cashapp-tag>
        </div>

        <div class="page__form group-bordered-inner" *ngIf="popupInfo.type === PRIZES_METHODS.paypal && !popupInfo.paypalAddress">
            <app-verify-cashapp-tag [isPaypal]="true"  [verifyStatus]="popupInfo.paypalAddress" (onVerify)="setPaypalVerified($event)"></app-verify-cashapp-tag>
        </div>

        <form [formGroup]="form"
              (ngSubmit)="onSubmitForm()"
              *ngIf="(popupInfo.type === PRIZES_METHODS.cashapp && popupInfo.cashappVerified) || (popupInfo.type === PRIZES_METHODS.paypal && popupInfo.paypalAddress) || popupInfo.type === PRIZES_METHODS.games"
              class="form-simple form-simple--shrink form-simple--primary">
            <div class="form-simple__item" *ngIf="popupInfo.type !== PRIZES_METHODS.games">
                <input formControlName="handle"
                       placeholder="Handle"
                       disabled="true"
                       [type]="'text'"
                       [value]="popupInfo.handle"
                       class="form-simple__input"
                       autocomplete="off">
            </div>

            <div class="form-simple__item" *ngIf="popupInfo.type === PRIZES_METHODS.games">
                <mat-form-field>
                    <mat-select #methodsSelect
                                formControlName="handle"
                                [(value)]="selectedMethod" panelClass="my-select-panel-border-yellow">
                        <mat-select-trigger>
                            {{methodsSelect?.value?.name}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let method of popupInfo.providers" [value]="method">
                            {{method.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-simple__item form-simple__item--amount">
                <input mask="separator.0"
                       thousandSeparator=","
                       (input)="onInputAmount()"
                       formControlName="amount"
                       placeholder="Amount"
                       [type]="'tel'"
                       class="form-simple__input"
                       autocomplete="off">
                <span>.00</span>
                <div class="page__form-note" [class.is-active]="moreThenAvailablePayout">
                    Available {{popupInfo.totalDebit | currency : 'USD' : 'symbol'}}
                </div>
            </div>

            <div class="form-simple__btns">
                <button class="button snake-btn is-notouched"
                        [disabled]="+form.controls.amount.value <= 0">
                    Transfer
                    <svg><rect></rect></svg>
                </button>
            </div>
        </form>
    </div>
</div>
