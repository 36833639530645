<div class="page page--quaternary" *ngIf="card">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>

        <a id="credit_card_back_mobile" [routerLink]="getBackLink()" class="page__back page__back--mobile page__back--mobile-text">
            <span class="visually-hidden">Back</span>
        </a>
        <h1 class="page__title">{{card.description || ''}}</h1>

        <div class="page__body">

            <div class="navigation">
                <a id="credit_card_edit" [routerLink]="getEditLink()" class="navigation__button button snake-btn is-notouched">
                    EDIT CARD
                    <svg><rect></rect></svg>
                </a>
                <a id="credit_card_delete" (click)="askDelete(card.uuid)" class="js-delete navigation__button navigation__button--secondary button snake-btn is-notouched">
                    DELETE
                    <svg><rect></rect></svg>
                </a>
                <a id="credit_card_verify"
                   [routerLink]="getVerifyLink()"
                   *ngIf="card.processor_type !== 1"
                   class="navigation__button navigation__button--secondary button snake-btn is-notouched">
                    VERIFY
                    <svg><rect></rect></svg>
                </a>
            </div>

        </div>
        <a id="credit_card_back" [routerLink]="getBackLink()" class="button page__back page__back--desktop" >
            CANCEL
            <svg><rect></rect></svg>
        </a>
    </div>
    <svg [ngStyle]="{fill: getActiveColor()}" class="page-wrapper__decor-bottom page-wrapper__decor-bottom--square" width="324" height="12" viewBox="0 0 324 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="317.999" cy="6.00002" rx="5.99998" ry="5.99998" transform="rotate(-180 317.999 6.00002)"/>
        <circle cx="284.999" cy="5.99996" r="5.99998" transform="rotate(-180 284.999 5.99996)"/>
        <circle cx="251.999" cy="5.99996" r="5.99998" transform="rotate(-180 251.999 5.99996)"/>
        <circle cx="6.00002" cy="5.99996" r="5.99998" transform="rotate(-180 6.00002 5.99996)"/>
        <line [ngStyle]="{stroke: getActiveColor()}" x1="227.999" y1="7.50003" x2="62.999" y2="7.50002" stroke-width="3"/>
    </svg>
</div>
