import { APP_DATE_FORMATS } from '../shared/shared.module';
export var DEFAULT_SWIPER_CONFIG = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    loop: true,
    loopedSlides: 100,
    observer: true,
    observeParents: true,
    preventClicks: false,
    initialSlide: 0,
    simulateTouch: true,
    resistanceRatio: 0.6,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    watchOverflow: true,
};
var ɵ0 = APP_DATE_FORMATS;
var ChatQuizModule = /** @class */ (function () {
    function ChatQuizModule() {
    }
    return ChatQuizModule;
}());
export { ChatQuizModule };
export { ɵ0 };
