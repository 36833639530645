import * as tslib_1 from "tslib";
import { SettingsLoaderService } from "../settingsLoader.service";
import { GAMES_PROVIDER_CONFIG } from "../../config/api";
import { GAME_PROVIDER_ERRORS_CODE } from "./gameProviderErrorsCode.config";
import * as i0 from "@angular/core";
import * as i1 from "../../shared/popup/popup.component";
import * as i2 from "@angular/common/http";
import * as i3 from "../loader.service";
import * as i4 from "../../modules/auth/_services/authentication.service";
import * as i5 from "../users/users-point.service";
var GamesProviderService = /** @class */ (function (_super) {
    tslib_1.__extends(GamesProviderService, _super);
    function GamesProviderService(popupComponent, http, loaderService, auth, usersPointService) {
        var _this = _super.call(this, loaderService) || this;
        _this.popupComponent = popupComponent;
        _this.http = http;
        _this.loaderService = loaderService;
        _this.auth = auth;
        _this.usersPointService = usersPointService;
        _this.config = GAMES_PROVIDER_CONFIG;
        return _this;
    }
    GamesProviderService.prototype.chooseGame = function (groupId, gameProviderId) {
        this.showLoader();
        var post = this.http.post(this.config.chooseGame, { groupId: groupId, gameProviderId: gameProviderId });
        return post;
    };
    GamesProviderService.prototype.getGamesList = function (pointId) {
        this.showLoader();
        var post = this.http.post(this.config.gamesList, { pointId: pointId });
        return post;
    };
    GamesProviderService.prototype.setProviders = function (games) {
        sessionStorage.setItem('gamesProviders', JSON.stringify(games));
    };
    GamesProviderService.prototype.getProviders = function () {
        return JSON.parse(sessionStorage.getItem('gamesProviders'));
    };
    GamesProviderService.prototype.getActiveProvider = function (providers) {
        var _this = this;
        var providersList = providers ? providers : this.getProviders();
        return providersList.find(function (el) { return el.short_name === _this.usersPointService.getGameVendor(); });
    };
    GamesProviderService.prototype.getActiveProviderName = function () {
        var provider = this.getActiveProvider();
        return provider ? provider.name : '';
    };
    GamesProviderService.prototype.getActiveProviderShortName = function () {
        var provider = this.getActiveProvider();
        return provider ? provider.short_name : '';
    };
    GamesProviderService.prototype.onGameChosen = function (game, callback) {
        var _this = this;
        var gameId = game.id;
        this.chooseGame(this.usersPointService.getGroupId(), gameId).subscribe(function (data) {
            callback(data, gameId);
            if (data.status === true) {
                if (data.code && data.code === GAME_PROVIDER_ERRORS_CODE.gameDisabled) {
                }
                else {
                    _this.loginUser(data.userPointId, data.gameVendorShortName, _this.usersPointService.getGroupId());
                }
            }
            else {
                _this.popupComponent.showPopupError({ text: data.message });
            }
        }, function () {
            _this.loaderService.hideAndShowTryAgain();
        }, function () {
            _this.loaderService.hide();
        });
    };
    GamesProviderService.prototype.loginUser = function (pointId, gameVendorShortName, groupId) {
        this.usersPointService.setUserPointId(pointId);
        this.usersPointService.setGameVendorShortName(gameVendorShortName);
        this.usersPointService.setGroupId(groupId);
        this.auth.userLoggedStatusChanged.next(true);
    };
    GamesProviderService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GamesProviderService_Factory() { return new GamesProviderService(i0.ɵɵinject(i1.PopupComponent), i0.ɵɵinject(i2.HttpClient), i0.ɵɵinject(i3.LoaderService), i0.ɵɵinject(i4.AuthenticationService), i0.ɵɵinject(i5.UsersPointService)); }, token: GamesProviderService, providedIn: "root" });
    return GamesProviderService;
}(SettingsLoaderService));
export { GamesProviderService };
