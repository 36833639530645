import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {LoaderService} from "../../service/loader.service";
import {PopupComponent} from "../popup/popup.component";
import {SettingsService} from "../../service/settings/settings.service";

@Component({
    selector: 'app-verify-cashapp-tag',
    templateUrl: './verify-cashapp-tag.component.html',
    styleUrls: ['./verify-cashapp-tag.component.scss']
})
export class VerifyCashappTagComponent implements OnInit {
    @Input() isPaypal = false;
    @Input() set verifyStatus(val:boolean) {
        this.cashappVerified = val;
    };
    @Output('onVerify')
    onVerify: EventEmitter<any> = new EventEmitter();
    cashappForm: FormGroup;
    paypalForm: FormGroup;
    cashappVerified = false;

    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private settingsService: SettingsService) {}

    ngOnInit() {
        this.cashappForm = this.formBuilder.group({
            address: [null, Validators.required],
            retype: [null, Validators.required],
        });
        this.paypalForm = this.formBuilder.group({
            email: [null, Validators.required],
            retype: [null, Validators.required],
        });
    }

    onSubmitCashappForm() {
        const address = this.cashappForm.controls.address.value;
        const retype = this.cashappForm.controls.retype.value;

        this.popupComponent.showPopupConfirmCancel({text: `The CashApp address ${address} will be now associated with your account and will be used to send out redeems as well as to process deposits. If you would like to change it in the future, please contact support.`}, (status) => {
            if (status) {
                this.settingsService.verifyCashapp(address, retype).subscribe(data => {
                    try {
                        if (data.status) {
                            this.popupComponent.showPopupSuccess({text: data.message});
                            this.setCashappVerified();
                        } else {
                            this.popupComponent.showPopupError({text: data.message});
                        }
                    } catch (e) {
                        this.loaderService.hideAndShowTryAgain();
                    }
                }, () => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                })
            }
        });
    }

    setCashappVerified() {
        this.cashappVerified = true;
        if (this.isPaypal) {
            this.onVerify.emit(this.paypalForm.controls.email.value);
        } else {
            this.onVerify.emit(this.cashappForm.controls.address.value);
        }
    }

    onSubmitPaypalForm() {
        const email = this.paypalForm.controls.email.value;
        const retype = this.paypalForm.controls.retype.value;

        this.popupComponent.showPopupConfirmCancel({text: `The Email address ${email} will be now associated with your account and will be used to send out redeems as well as to process deposits. If you would like to change it in the future, please contact support.`}, (status) => {
            if (status) {
                this.settingsService.verifyPaypal(email, retype).subscribe(data => {
                    try {
                        if (data.status) {
                            this.popupComponent.showPopupSuccess({text: data.message});
                            this.setCashappVerified();
                        } else {
                            this.popupComponent.showPopupError({text: data.message});
                        }
                    } catch (e) {
                        this.loaderService.hideAndShowTryAgain();
                    }
                }, () => {
                    this.loaderService.hideAndShowTryAgain();
                }, () => {
                    this.loaderService.hide();
                })
            }
        });
    }
}
