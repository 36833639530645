import {Inject, Injectable, NgZone} from '@angular/core';
import {BehaviorSubject, timer} from 'rxjs';
import {HttpClient} from "@angular/common/http";
import {SCRATCH_OFF_CONFIG} from "../../config/api";
import {SettingsLoaderService} from "../settingsLoader.service";
import {mergeMap} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class ScratchOffService {
    private config = SCRATCH_OFF_CONFIG;

    constructor(private http: HttpClient) {}

    createRound() {
        return this.http.post<any>(this.config.createRound, {});
    }

    chooseItem(roundUuid, uuid) {
        // return this.http.post<any>(this.config.item, {hash});

        return this.http.post<any>(this.config.chooseItem, {roundUuid, uuid});

        // return timer(2000).pipe(
        //   mergeMap(() => this.http.post<any>(this.config.chooseItem, {roundUuid, uuid}))
        // );
    }
}
