import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {ScratchOffService} from "../../../../service/games/scratchOff.service";
import {LoaderService} from "../../../../service/loader.service";
import {PopupComponent} from "../../../../shared/popup/popup.component";
import {FireworkService} from "../../../../service/fireworks/firework.service";
import {ParticleService} from "../../../../service/fireworks/particle.service";
import {PIG_SOUND_TYPE} from "./pig.config";

declare global {
    interface Window { requestAnimFrame: any; }
}

window['requestAnimFrame'] = (() => {
    return 	window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window['mozRequestAnimationFrame'] ||
      function(callback) {
          window.setTimeout(callback, 1000 / 60);
      };
})();

@Component({
    selector: 'app-pig',
    templateUrl: './pig.component.html',
    styleUrls: ['./pig.component.scss']
})
export class PigComponent implements OnInit {
    @Output() isElementReady = new EventEmitter();
    @Output() close = new EventEmitter();

    popupVisible = false;
    roundUuid;
    defaultImage;
    private _showPopup: boolean;

    @Input() set showPopup(value: boolean) {
        this._showPopup = value;
        if (value && !this.popupVisible) {
            this.popupVisible = true;
            this.getButtonsArray();

            setTimeout(() => {
                this.initCanvas();

                setTimeout(() => {
                    this.loop();
                });
            });
        }
    }

    get showPopup() : boolean  {
        return this._showPopup;
    }

    activeClass = false;
    imagesForLoading = 3;

    showGamePopup = false;
    showGamePopupNoLuck = false;
    showGamePopupWinning = false;
    popupPrizeImage = '';
    hidePopupTimeout;
    hidePopupAutoAfterSec = 1.5;
    winAmount = 0;


    buttons = [];
    PARTICLE_COUNT = 100;
    fireworkInterval;

    responseKeys = {
        getList: {
            buttonsList: 'elements',
        },
        getItem: {
            isWinning: 'winning',
            winningImage: 'result',
            prizeValue: 'prize',
            canPlay: 'canPlay',
            restImages: 'restImages'
        }
    };

    additionalBtnKeys = {
        winningImage: 'winningImage',
        animate: 'animate',
        noLuck: 'noLuck'
    };


// Alpha level that canvas cleanup iteration removes existing trails.
// Lower value increases trail duration.
    CANVAS_CLEANUP_ALPHA = 0.15;
// Hue change per loop, used to rotate through different firework colors.
    HUE_STEP_INCREASE = 0.5;
// Minimum number of ticks per manual firework launch.
    TICKS_PER_FIREWORK_MIN = 1;
// Minimum number of ticks between each automatic firework launch.
    TICKS_PER_FIREWORK_AUTOMATED_MIN = 20;
// Maximum number of ticks between each automatic firework launch.
    TICKS_PER_FIREWORK_AUTOMATED_MAX = 80;


    canvas;
    context;
    fireworks = [];
    particles = [];
// Mouse coordinates.
    mouseX;
    mouseY;
    isMouseDown = false;
// Initial hue.
    hue = 120;
// Track number of ticks since automated firework.
    ticksSinceFireworkAutomated = 0;
// Track number of ticks since manual firework.
    ticksSinceFirework = 0;

    @ViewChildren('secretBtn') secretBtns: QueryList<ElementRef>;

    constructor(private scratchOffService: ScratchOffService,
                private popupComponent: PopupComponent,
                private loaderService: LoaderService) {

    }

    ngOnInit() {
        // this.getButtonsArray();
        // this.initCanvas();
        //
        // setTimeout(() => {
        //     this.loop();
        // });
    }

    ngOnDestroy() {
        this.popupVisible = false;
        this.buttons = [];
    }

    initCanvas() {
        this.canvas = document.getElementById('canvas');
        this.context = (this.canvas as HTMLCanvasElement).getContext('2d');
        (this.canvas as HTMLCanvasElement).width = window.innerWidth;
        (this.canvas as HTMLCanvasElement).height = window.innerHeight;
    }

    getButtonsArray() {
        this.loaderService.show();
        this.scratchOffService.createRound().subscribe(data => {
            this.setButtons(data);
        }, () => {
            this.loaderService.hideAndShowTryAgain('bordered');
        }, () => {
            this.loaderService.hide();
        });
    }

    setButtons(response) {
        if(response.status === true){
            response[this.responseKeys.getList.buttonsList].forEach(el => {
                this.buttons.push({hash: el});
            });

            this.buttons.forEach(btn => {
                response.openedElements.forEach(openedItem => {
                    if (btn.hash === openedItem.uuid) {
                        btn[this.additionalBtnKeys.winningImage] = openedItem.image;
                    }
                });
            });

            this.roundUuid = response.roundUuid;
            this.defaultImage = response.defaultImage;
        }else if (response.status === false) {
            this.popupComponent.showPopupError({text: response.message}, 'btn-ok');
            this.close.emit();
        } else {
            this.loaderService.hideAndShowTryAgain('bordered');
            this.close.emit();
        }
    }

    chooseItem(target, hash) {
        const item = target.querySelector('img');
        const rect = item.getBoundingClientRect();
        const x = rect.left + rect.width/2;
        const y = rect.top + rect.height/2;
        const itemWidthAnimation = item.width*.5;
        const itemHeightAnimation = item.height*.5;

        this.canvas.classList.add('is-animated');

        this.fireFirework(x, y, itemWidthAnimation, itemHeightAnimation);
        this.fireworkInterval = setInterval(() => {
            this.fireFirework(x, y, itemWidthAnimation, itemHeightAnimation);
        }, 500);

        this.scratchOffService.chooseItem(this.roundUuid, hash).subscribe(data => {
            setTimeout(() => {
                this.updateButton(data, hash);
            }, 500);
        }, () => {
            this.loaderService.hideAndShowTryAgain('bordered');
        }, () => {
            clearInterval(this.fireworkInterval);
            this.isMouseDown = false;
            setTimeout(() => {
                this.canvas.classList.remove('is-animated');
            }, 1000);
        });
    }

    fireFirework(x, y, itemWidthAnimation, itemHeightAnimation) {
        const rect = (this.canvas as HTMLCanvasElement).getBoundingClientRect();
        this.mouseX = ((x - rect.left) * (this.canvas as HTMLCanvasElement).width / rect.width) + this.random(-itemWidthAnimation, itemWidthAnimation);
        this.mouseY = ((y - rect.top) * (this.canvas as HTMLCanvasElement).height / rect.height) + this.random(-itemHeightAnimation, itemHeightAnimation);
        this.isMouseDown = true;
    }

    updateButton(response, hash) {
        if(response.status === true){
            // image.setAttribute('src', response.result);
            this.buttons.forEach(el => {
                if (el.hash === hash) {
                    el[this.additionalBtnKeys.winningImage] = response.element.image;
                }
            });

            this.winAmount = response.winAmount;

            this.showResultPopup(response);
        }else if (response.status === false) {
            this.popupComponent.showPopupError({text: response.message}, 'btn-ok');
        } else {
            this.loaderService.hideAndShowTryAgain('bordered');
        }
    }

    showResultPopup(response) {
        this.popupPrizeImage = response.element.image;
        if (response.type) {
            this.showWinningPopup(response.winAmount);
        } else {
            this.showNoLuckPopup();
            this.hidePopupTimeout = setTimeout(() => {
                console.log("test_test: 25");
                this.hideGamePopup();
            }, this.hidePopupAutoAfterSec * 1000);
        }
        this.checkGameIsOver(response);
    }

    hideGamePopup() {
        this.showGamePopup = false;
        this.showGamePopupNoLuck = false;
        this.showGamePopupWinning = false;
        clearTimeout(this.hidePopupTimeout);
    }

    showWinningPopup(prize) {
        this.showGamePopup = true;
        this.showGamePopupWinning = true;
        this.playWinSound();
    }

    showNoLuckPopup() {
        this.showGamePopup = true;
        this.showGamePopupNoLuck = true;
    }

    checkGameIsOver(response) {
        if (!response.attempts) {
            this.secretBtns.forEach(el => el.nativeElement.classList.add('no-events'));
            const restImages = response.elements;

            setTimeout(() => {
                if (restImages && restImages.length) {
                    restImages.forEach(rest => {
                        this.buttons.forEach(btn => {
                            if (rest.uuid === btn.hash) {
                                btn[this.additionalBtnKeys.noLuck] = rest.image;
                                btn[this.additionalBtnKeys.animate] = true;
                            }
                        });
                    });
                }
            }, 800);

        }
    }

    getResultImage(item) {
        if (item[this.additionalBtnKeys.winningImage]) {
            return item[this.additionalBtnKeys.winningImage];
        } else if (item[this.additionalBtnKeys.noLuck]) {
            return item[this.additionalBtnKeys.noLuck];
        } else {
            return this.defaultImage;
        }
    }

    createParticles(x, y) {
        // Set particle count.
        // Higher numbers may reduce performance.
        let particleCount = this.PARTICLE_COUNT;
        while(particleCount--) {
            // Create a new particle and add it to particles collection.
            this.particles.push(new ParticleService(x, y));
        }
    }

    cleanCanvas() {
        // Set 'destination-out' composite mode, so additional fill doesn't remove non-overlapping content.
        this.context.globalCompositeOperation = 'destination-out';
        // Set alpha level of content to remove.
        // Lower value means trails remain on screen longer.
        this.context.fillStyle = `rgba(0, 0, 0, ${this.CANVAS_CLEANUP_ALPHA})`;
        // Fill entire canvas.
        this.context.fillRect(0, 0, (this.canvas as HTMLCanvasElement).width, (this.canvas as HTMLCanvasElement).height);
        // Reset composite mode to 'lighter', so overlapping particles brighten each other.
        this.context.globalCompositeOperation = 'lighter';
    }

    random(min, max) {
        return Math.random() * (max - min) + min;
    }

    launchAutomatedFirework() {
        // Determine if ticks since last automated launch is greater than random min/max values.
        if(this.ticksSinceFireworkAutomated >= this.random(this.TICKS_PER_FIREWORK_AUTOMATED_MIN, this.TICKS_PER_FIREWORK_AUTOMATED_MAX)) {
            // Check if mouse is not currently clicked.
            if(!this.isMouseDown) {
                // Set start position to bottom center.
                let startX = (this.canvas as HTMLCanvasElement).width / 2;
                let startY = (this.canvas as HTMLCanvasElement).height;
                // Set end position to random position, somewhere in the top half of screen.
                let endX = this.random(0, (this.canvas as HTMLCanvasElement).width);
                let endY = this.random(0, (this.canvas as HTMLCanvasElement).height / 2);
                // Create new firework and add to collection.
                this.fireworks.push(new FireworkService(startX, startY, endX, endY));

                // Reset tick counter.
                this.ticksSinceFireworkAutomated = 0;
            }
        } else {
            // Increment counter.
            this.ticksSinceFireworkAutomated++;
        }
    }

    launchManualFirework() {
        // Check if ticks since last firework launch is less than minimum value.
        if(this.ticksSinceFirework >= this.TICKS_PER_FIREWORK_MIN) {
            // Check if mouse is down.
            if(this.isMouseDown) {
                // Set start position to bottom center.
                let startX = (this.canvas as HTMLCanvasElement).width / 2;
                let startY = (this.canvas as HTMLCanvasElement).height;
                // // Set end position to current mouse position.
                let endX = this.mouseX;
                let endY = this.mouseY;

                // Create new firework and add to collection.
                this.fireworks.push(new FireworkService(startX, startY, endX, endY));
                // Reset tick counter.
                this.ticksSinceFirework = 0;
            }
        } else {
            // Increment counter.
            this.ticksSinceFirework++;
        }
    }

    // Update all active fireworks.
    updateFireworks() {
        // Loop backwards through all fireworks, drawing and updating each.
        for (let i = this.fireworks.length - 1; i >= 0; --i) {
            this.fireworks[i].draw(this.context, this.hue);
            this.fireworks[i].update(i, this.fireworks, this.createParticles.bind(this));
        }
    }

// Update all active particles.
    updateParticles() {
        // Loop backwards through all particles, drawing and updating each.
        for (let i = this.particles.length - 1; i >= 0; --i) {
            this.particles[i].draw(this.context);
            this.particles[i].update(i, this.particles);
        }
    }

// === END APP HELPERS ===

// Primary loop.
    loop() {
        // Smoothly request animation frame for each loop iteration.

        const $this = this;

        if ($this) {
            window.requestAnimFrame(this.loop.bind(this));

            // Adjusts coloration of fireworks over time.
            this.hue += this.HUE_STEP_INCREASE;

            // Clean the canvas.
            this.cleanCanvas();

            // Update fireworks.
            this.updateFireworks();

            // Update particles.
            this.updateParticles();

            // Launch manual fireworks.
            this.launchManualFirework();
        }


    }

    btnClickHandler(e, hash) {
        this.chooseItem(e.currentTarget, hash);
    }

    buttonImage(index, button) {
        return button.image;
    }

    showGame() {
        this.isElementReady.emit(true);
        setTimeout(() => {
            this.activeClass = true;
        }, 0);
    }

    onImageLoaded() {
        if (this.imagesForLoading) {
            this.imagesForLoading -= 1;
        } else {
            this.showGame();
        }
    }

    playWinSound() {
        const audio = new Audio(PIG_SOUND_TYPE.win.src);
        audio.play();
    }

}
