<div class="page page--quaternary long-content" *ngIf="!frame" [class.page--blured]="showNFTPopup || iframePopup.show">
    <div class="page__inner">
        <a [routerLink]="getBackLink()" class="page__back page__back--mobile page__back page__back--mobile-text">
            <span class="visually-hidden">Back</span>
        </a>
        <app-logo-bg></app-logo-bg>
        <h1 class="page__title">ADD FUNDS</h1>

        <div class="page__scrolled">
            <div class="page__form">
                <form [formGroup]="sellForm" class="form-simple form-simple--shrink form-simple--primary">

                    <div class="saved">

                        <div class="saved__title">SAVED PAYMENT METHODS</div>

                        <div class="saved__list" #scrolledEl>
                            <div *ngFor="let method of methods" class="form-simple__item">
                                <input *ngIf="isCard(getPaymentMethod(method))" formControlName="method"
                                       [id]="'card-'+getPaymentMethod(method)+getMethodId(method)"
                                       [type]="'radio'"
                                       class="form-simple__radio"
                                       [value]="method"
                                       [attr.data-id]="getMethodId(method)"
                                       name="email"
                                       [checked]="activeMethod === method"
                                       (change)="changeMethod(method)"
                                       autocomplete="off">

                                <label *ngIf="isCard(getPaymentMethod(method))" [for]="'card-'+getPaymentMethod(method)+getMethodId(method)">{{getPaymentName(method)}}</label>

                                <input *ngIf="isPaypal(getPaymentMethod(method))" formControlName="method"
                                       [id]="'email-'+getPaymentMethod(method)+getMethodId(method)"
                                       [type]="'radio'"
                                       class="form-simple__radio"
                                       [value]="method"
                                       [attr.data-id]="getMethodId(method)"
                                       name="email"
                                       [checked]="activeMethod === method"
                                       (change)="changeMethod(method)"
                                       autocomplete="off">
                                <label *ngIf="isPaypal(getPaymentMethod(method))" [for]="'email-'+getPaymentMethod(method)+getMethodId(method)">{{getPaymentName(method)}}</label>
                            </div>

                        </div>
                    </div>

                    <table class="transactions">
                        <tr class="transactions__head">
                            <th class="transactions__type">TYPE</th>
                            <th class="transactions__date">PRICE</th>
                            <th class="transactions__amount">QUANTITY</th>
                            <th class="transactions__amount">SUBTOTAL</th>
                        </tr>
                        <tr *ngFor="let item of nftItems" class="transactions__item js-prizes-item">
                            <td class="transactions__type">{{item.type}}</td>
                            <td class="transactions__amount">{{item.price | currency : 'USD' : 'symbol'}}</td>
                            <td class="transactions__type">{{item.quantity}}</td>
                            <td class="transactions__amount">{{item.subtotal | currency : 'USD' : 'symbol'}}</td>
                        </tr>
                    </table>

                    <div class="amount-block">
                        <div class="amount-block__head">
                            Purchase amount: {{purchaseAmount | currency : 'USD' : 'symbol'}}
                        </div>
                        <div class="amount-block__body">
                            <button class="amount-block__button"
                                    type="button"
                                    *ngFor="let btn of depositSteps"
                                    (click)="increasePurchaseAmount(btn)">
                                ${{btn}}
                            </button>
                            <button class="amount-block__button amount-block__button--clear"
                                    type="button"
                                    (click)="clearPurchaseAmount()">
                                CLEAR
                            </button>
                        </div>
                    </div>

                    <div class="form-simple__btns form-simple__btns--middle">
                        <button class="js-submit-btn button snake-btn is-notouched"
                                (click)="onSubmit()"
                                [disabled]="purchaseAmount <= 0">
                            BUY NFT(<span style="text-transform: lowercase">s</span>)
                            <svg><rect></rect></svg>
                        </button>
                    </div>
                </form>
            </div>
            <a [routerLink]="getBackLink()" class="button page__back page__back--desktop" >
                Back
                <svg><rect></rect></svg>
            </a>
        </div>

    </div>
</div>


<div *ngIf="frame" class="frame">
    <iframe [src]="frameUrl" #iframe></iframe>
</div>

<button *ngIf="frame" (click)="closeFrame()" class="close">
    <span class="visually-hidden">Close</span>
    <svg class="close__icon" width="34" height="34">
        <use xlink:href="../../../../assets/images/sprite.svg#close"></use>
    </svg>
</button>

<app-investments-nft-popup  *ngIf="showNFTPopup && NFTPopupData"
                            [popupData]="NFTPopupData.data"
                            [popupType]="NFTPopupData.type"
                            (close)="onPopupNFTClose($event)"></app-investments-nft-popup>

<app-iframe-popup *ngIf="iframePopup.show"
                  [url]="iframePopup.url"
                  (onClose)="hideIframePopup($event)"></app-iframe-popup>
