import { NgZone } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { BehaviorSubject } from "rxjs";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "./loader.service";
import * as i3 from "@angular/common/http";
import * as i4 from "./testing/frontendTestSettings.service";
var PartnerService = /** @class */ (function () {
    function PartnerService(ngZone, route, loaderService, http, frontendTestSettingsService) {
        this.ngZone = ngZone;
        this.route = route;
        this.loaderService = loaderService;
        this.http = http;
        this.frontendTestSettingsService = frontendTestSettingsService;
        this.currentPartnerTemplate = new BehaviorSubject('');
        this.storage = sessionStorage;
    }
    PartnerService.prototype.getLogo = function () {
        return '../../../assets/images/mynftking.png';
    };
    PartnerService.prototype.storageGetCashapTag = function () {
        return this.storage.getItem('currentPartnerCashappTag');
    };
    PartnerService.prototype.getShortName = function () {
        // if (this.storage.getItem('currentPartnerShortName')) {
        //     return this.storage.getItem('currentPartnerShortName');
        // }
        return 'default';
    };
    PartnerService.prototype.getBusinessName = function () {
        // if (this.storage.getItem('currentPartnerBusinessName')) {
        //     return this.storage.getItem('currentPartnerBusinessName');
        // }
        return 'NFT';
    };
    PartnerService.prototype.getPartnerDomain = function () {
        // if (this.storage.getItem('currentPartnerDomain')) {
        //     return this.storage.getItem('currentPartnerDomain');
        // }
        return '';
    };
    PartnerService.prototype.getName = function () {
        //currentPartner.name
        return 'NFT';
    };
    PartnerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PartnerService_Factory() { return new PartnerService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.ActivatedRoute), i0.ɵɵinject(i2.LoaderService), i0.ɵɵinject(i3.HttpClient), i0.ɵɵinject(i4.FrontendTestSettingsService)); }, token: PartnerService, providedIn: "root" });
    return PartnerService;
}());
export { PartnerService };
