import {Component, ElementRef, HostBinding, HostListener, OnInit, ViewChild} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {Router} from "@angular/router";
import {AddressesService} from "../../../service/settings/addresses.service";
import {AddressesModel, AddressesResultItem} from "../../../models/services/addresses/addresses.model";
import {ServerResponse} from "../../../models/responses/response.model";
import {StylingService} from "../../../service/styling.service";

@Component({
    selector: 'app-addresses-settings',
    templateUrl: './addresses-settings.component.html',
    styleUrls: ['./addresses-settings.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class AddressesSettingsComponent implements OnInit {
    addresses: Array<AddressesResultItem>;

    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;

    constructor(private popupComponent: PopupComponent,
                private router: Router,
                private loaderService: LoaderService,
                private stylingService: StylingService,
                private addressesService: AddressesService) {

    }


    ngOnInit() {
        this.getAccountsList();
    }

    getAccountsList() {
        this.addressesService.getList().subscribe(data => {
            this.checkAccountsList(data);
        })
    }

    checkAccountsList(response: AddressesModel) {
        this.addresses = [];
        if(response.status === true){
            this.addresses = response.result;
        } else if (response.status === undefined){
            this.loaderService.hideAndShowTryAgain();
        }
    }

    askDelete(address) {
        if (address.id && address.address && address.address.trim()) {
            this.popupComponent.showPopupAgreement({
                text: `<b class="js-popup-title">Please confirm deleting Address</b> 
                        </br></br> 
                        <span class="js-popup-subtitle">${address.address}</span>`
            }, (status) => {
                if (status) {
                    this.delete(address.id);
                }
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }

    }

    delete(id) {
        if (id && id != 'undefined') {
            this.addressesService.deleteAddress(id).subscribe(data => {
                this.checkDeleteRequestResult(data);
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }

    }

    checkDeleteRequestResult(response: ServerResponse) {
        if(response.status === true){
            this.popupComponent.showPopupSuccess({text: response.message});
            this.getAccountsList();
        }  else if (response.status === false) {
            this.popupComponent.showPopupError({text: response.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    edit(address) {
        if (address.id) {
            this.router.navigate(['/home/settings/addresses/edit', address.id]);
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }
}
