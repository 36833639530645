import { EventEmitter, OnInit } from '@angular/core';
import { Validators } from "@angular/forms";
var VerifyCashappTagComponent = /** @class */ (function () {
    function VerifyCashappTagComponent(formBuilder, loaderService, popupComponent, settingsService) {
        this.formBuilder = formBuilder;
        this.loaderService = loaderService;
        this.popupComponent = popupComponent;
        this.settingsService = settingsService;
        this.isPaypal = false;
        this.onVerify = new EventEmitter();
        this.cashappVerified = false;
    }
    Object.defineProperty(VerifyCashappTagComponent.prototype, "verifyStatus", {
        set: function (val) {
            this.cashappVerified = val;
        },
        enumerable: true,
        configurable: true
    });
    ;
    VerifyCashappTagComponent.prototype.ngOnInit = function () {
        this.cashappForm = this.formBuilder.group({
            address: [null, Validators.required],
            retype: [null, Validators.required],
        });
        this.paypalForm = this.formBuilder.group({
            email: [null, Validators.required],
            retype: [null, Validators.required],
        });
    };
    VerifyCashappTagComponent.prototype.onSubmitCashappForm = function () {
        var _this = this;
        var address = this.cashappForm.controls.address.value;
        var retype = this.cashappForm.controls.retype.value;
        this.popupComponent.showPopupConfirmCancel({ text: "The CashApp address " + address + " will be now associated with your account and will be used to send out redeems as well as to process deposits. If you would like to change it in the future, please contact support." }, function (status) {
            if (status) {
                _this.settingsService.verifyCashapp(address, retype).subscribe(function (data) {
                    try {
                        if (data.status) {
                            _this.popupComponent.showPopupSuccess({ text: data.message });
                            _this.setCashappVerified();
                        }
                        else {
                            _this.popupComponent.showPopupError({ text: data.message });
                        }
                    }
                    catch (e) {
                        _this.loaderService.hideAndShowTryAgain();
                    }
                }, function () {
                    _this.loaderService.hideAndShowTryAgain();
                }, function () {
                    _this.loaderService.hide();
                });
            }
        });
    };
    VerifyCashappTagComponent.prototype.setCashappVerified = function () {
        this.cashappVerified = true;
        if (this.isPaypal) {
            this.onVerify.emit(this.paypalForm.controls.email.value);
        }
        else {
            this.onVerify.emit(this.cashappForm.controls.address.value);
        }
    };
    VerifyCashappTagComponent.prototype.onSubmitPaypalForm = function () {
        var _this = this;
        var email = this.paypalForm.controls.email.value;
        var retype = this.paypalForm.controls.retype.value;
        this.popupComponent.showPopupConfirmCancel({ text: "The Email address " + email + " will be now associated with your account and will be used to send out redeems as well as to process deposits. If you would like to change it in the future, please contact support." }, function (status) {
            if (status) {
                _this.settingsService.verifyPaypal(email, retype).subscribe(function (data) {
                    try {
                        if (data.status) {
                            _this.popupComponent.showPopupSuccess({ text: data.message });
                            _this.setCashappVerified();
                        }
                        else {
                            _this.popupComponent.showPopupError({ text: data.message });
                        }
                    }
                    catch (e) {
                        _this.loaderService.hideAndShowTryAgain();
                    }
                }, function () {
                    _this.loaderService.hideAndShowTryAgain();
                }, function () {
                    _this.loaderService.hide();
                });
            }
        });
    };
    return VerifyCashappTagComponent;
}());
export { VerifyCashappTagComponent };
