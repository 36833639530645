import {Component, OnInit} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {ActivatedRoute} from "@angular/router";
import {TransactionsService} from "../../../service/transactions/transactions.service";

@Component({
    selector: 'app-transaction-processing',
    templateUrl: './transaction-processing.component.html',
    styleUrls: ['./transaction-processing.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': ''}
})
export class TransactionProcessingComponent implements OnInit {
    message = 'Transaction in processing';

    constructor() {
    }

    ngOnInit() {
    }
}

