import { environment } from "../../../environments/environment";
import * as i0 from "@angular/core";
var FrontendTestSettingsService = /** @class */ (function () {
    function FrontendTestSettingsService() {
        this.isTestMode = environment.test_front;
        this.isResetAllSettings = false;
        this.terminalOn = false;
        this.mascotSinglePageOn = false;
        this.clientOn = true;
        this.partnerAdditionInfo = true;
        this.terminalSettings = {
            noConnectedTerminal: false,
            isStationMascot: true,
            displayGames: true,
            displayCrypto: true,
        };
        if (this.isTestModeOn()) {
            console.log('Frontend Test mode is on'.toLocaleUpperCase());
        }
        else {
            console.log('Frontend Test mode is off'.toLocaleUpperCase());
        }
    }
    FrontendTestSettingsService.prototype.isTestModeOn = function () {
        if (this.isTestMode && !this.isResetAllSettings) {
            return true;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isHideForTesting = function () {
        if (this.isTestModeOn()) {
            return false;
        }
        return true;
    };
    FrontendTestSettingsService.prototype.isMascotSinglePage = function () {
        if (this.isTestModeOn()) {
            return this.mascotSinglePageOn;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isClient = function () {
        if (this.isTestModeOn()) {
            return this.isTestModeOn() && this.clientOn;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isTerminal = function () {
        if (this.isTestModeOn()) {
            return this.terminalOn;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isTerminalDisplayGames = function () {
        if (this.isTestModeOn()) {
            return this.isTerminal() && this.terminalSettings.displayGames;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isTerminalDisplayCrypto = function () {
        if (this.isTestModeOn()) {
            return this.isTerminal() && this.terminalSettings.displayCrypto;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isNoTerminal = function () {
        if (this.isTestModeOn()) {
            return this.isTerminal() && this.terminalSettings.noConnectedTerminal;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isPartnerAdditionInfo = function () {
        if (this.isTestModeOn()) {
            return this.partnerAdditionInfo;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isTerminalStationMascot = function () {
        if (this.isTestModeOn()) {
            return this.isTerminal() && this.terminalSettings.isStationMascot;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.isTerminalExist = function () {
        if (this.isTestModeOn()) {
            return this.isTerminal() && !this.terminalSettings.noConnectedTerminal;
        }
        return false;
    };
    FrontendTestSettingsService.prototype.setRewardsData = function () {
        return {
            "status": true,
            "result": 0,
            "providers": [
                {
                    "id": 2,
                    "name": "MASCOT GAMES",
                    "short_name": "mascot",
                    "image": "mascot.png",
                    "description": "<span>MASCOT</span>: The social games developed by Mascot are up-to-date, diverse, non-trivial, attractive and entertaining for everyone. ",
                    "odr": 0,
                    "status": 0,
                    "image_link": "https://api.dev.eboxenterprises.com/images/game_providers/mascot.png?n=1655978723",
                    "error_message": "",
                    "keno_game_id": ""
                },
                {
                    "id": 1,
                    "name": "EBOX",
                    "short_name": "river",
                    "image": "ebox.jpg",
                    "description": "<span>ebox</span>: A great selection of the most entertaining Fish Games and proven classical Games, posed to please anyone.",
                    "odr": 0,
                    "status": 0,
                    "image_link": "https://api.dev.eboxenterprises.com/images/games/default.png?n=1655978723",
                    "error_message": "",
                    "keno_game_id": ""
                },
                {
                    "id": 4,
                    "name": "GOLDEN DRAGON",
                    "short_name": "golden_dragon",
                    "image": "golden_dragon.jpg",
                    "description": "<span>GOLDEN DRAGON</span>: A great selection of the most entertaining Fish Games and proven classical Games, posed to please anyone.",
                    "odr": 2,
                    "status": 0,
                    "image_link": "https://api.dev.eboxenterprises.com/images/game_providers/golden_dragon.jpg?n=1655978723",
                    "error_message": "",
                    "keno_game_id": ""
                }
            ],
            "games": [],
            "riverpayId": "",
            "goldenDragonMobileId": "",
            "goldenDragonPassword": ""
        };
    };
    FrontendTestSettingsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FrontendTestSettingsService_Factory() { return new FrontendTestSettingsService(); }, token: FrontendTestSettingsService, providedIn: "root" });
    return FrontendTestSettingsService;
}());
export { FrontendTestSettingsService };
