import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    MAT_DATE_FORMATS,
    MAT_FORM_FIELD_DEFAULT_OPTIONS,
    MatButtonModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatRadioModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask';

import {APP_DATE_FORMATS, SharedModule} from '../shared/shared.module';
import {PagesModule} from './pages-routing.module';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { MainComponent } from './main/main.component';

import {LoginComponent} from "./login/login.component";

import {MatSliderModule} from '@angular/material/slider';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {FocusMonitor} from "@angular/cdk/a11y";
import {Platform} from "@angular/cdk/platform";

import { NgxCurrencyModule } from "ngx-currency";
import {HomeComponent} from "./home/home.component";

import {PrizesComponent} from "./prizes/prizes.component";
import {DatepickerCustomHeaderExample, ExampleHeader} from "../shared/datapicker-header/datapicker-header";
import {DemoMaterialModule} from "../material.module";
import {InstallComponent} from "./install/install.component";
import {RewardsComponent} from "./rewards/rewards.component";
import {SettingsComponent} from "./settings/settings.component";
import {CardComponent} from "./settings/card/card.component";
import {NewCardComponent} from "./settings/card/new-card/new-card.component";
import {CardMenuComponent} from "./settings/card/card-menu/card-menu.component";
import {EditCardComponent} from "./settings/card/edit-card/edit-card.component";
import {VerifyCardComponent} from "./settings/card/verify-card/verify-card.component";
import {AddAddressComponent} from "./settings/addresses-settings/add-address/add-address.component";
import {AddressesSettingsComponent} from "./settings/addresses-settings/addresses-settings.component";
import {FundsComponent} from "./settings/funds/funds.component";
import {RewardsFormComponent} from "./rewards/rewards-form/rewards-form.component";
import {ContactInfoComponent} from "./settings/contact-info/contact-info.component";
import {InvestmentsComponent} from "./investments/investments.component";
import {EditAddressComponent} from "./settings/addresses-settings/edit-address/edit-address.component";
import {MatSelectModule} from '@angular/material/select';
import {InvestmentsPopupConfirmComponent} from "./investments/investments-popup-confirm/investments-popup-confirm.component";
import {ParticlesModule} from "angular-particle";
import {TermsAndConditionsComponent} from "./terms-and-conditions/terms-and-conditions.component";
import {MascotSlidersComponent} from "./gamesProviders/mascot-sliders/mascot-sliders.component";
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import {SlickCarouselModule} from "ngx-slick-carousel";
import { NgxGlideModule } from 'ngx-glide';
import {PigComponent} from "./games/scratch-off/pig/pig.component";
import {SpinnerComponent} from "./games/spinner/spinner.component";
import {ScratchOffRulesComponent} from "./games/scratch-off/scratch-off-rules/scratch-off-rules.component";
import {InvestmentsNftPopupComponent} from "./investments/investments-nft-popup/investments-nft-popup.component";
import {MascotCardComponent} from "./gamesProviders/mascot-card/mascot-card.component";
import {ChartsModule} from "ng2-charts";
import {HelpSettingsComponent} from "./settings/help-settings/help-settings.component";
import {TransactionProcessingComponent} from "./transactions/transaction-processing/transaction-processing.component";
import {PaypalPaymentsSuccessComponent} from "./paypal/payments/success/paypal-payments-success.component";
import {PaypalPaymentsUnsuccessComponent} from "./paypal/payments/unsuccess/paypal-payments-unsuccess.component";

export const DEFAULT_SWIPER_CONFIG = {
    direction: 'horizontal',
    slidesPerView: 'auto',
    loop: true,
    loopedSlides: 100,
    observer: true,
    observeParents: true,
    preventClicks: false,
    initialSlide: 0,
    simulateTouch: true,
    resistanceRatio: 0.6,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    watchOverflow: true,
};

@NgModule({
    declarations: [
        MainComponent,
        HomeComponent,

        LoginComponent,

        RewardsComponent,
        RewardsFormComponent,

        PrizesComponent,

        SettingsComponent,
        ContactInfoComponent,

        InvestmentsComponent,
        InvestmentsPopupConfirmComponent,
        InvestmentsNftPopupComponent,

        PaypalPaymentsSuccessComponent,
        PaypalPaymentsUnsuccessComponent,

        CardComponent,
        NewCardComponent,
        CardMenuComponent,
        EditCardComponent,
        VerifyCardComponent,

        AddressesSettingsComponent,
        AddAddressComponent,
        EditAddressComponent,

        FundsComponent,

        HelpSettingsComponent,

        DatepickerCustomHeaderExample,
        ExampleHeader,
        InstallComponent,

        TermsAndConditionsComponent,
        MascotSlidersComponent,
        MascotCardComponent,

      PigComponent,
      ScratchOffRulesComponent,
      SpinnerComponent,
      TransactionProcessingComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        PagesModule,
        MatInputModule,
        MatIconModule,
        MatButtonModule,
        MatRadioModule,
        MatDialogModule,
        SharedModule,
        NgxMaskModule.forRoot(),
        SweetAlert2Module.forRoot(),
        MatSliderModule,
        ChartsModule,
        NgxCurrencyModule,
        DemoMaterialModule,
        MatSelectModule,
        ParticlesModule,
        SwiperModule,
        SlickCarouselModule,
        NgxGlideModule,
    ],
    providers: [
        FocusMonitor,
        Platform,
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS},
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        }
    ],
    exports: [
        LoginComponent,
        RewardsComponent,
        DatepickerCustomHeaderExample,
        ExampleHeader,
        InstallComponent
    ],
    entryComponents: [
        LoginComponent,
        RewardsComponent,
        DatepickerCustomHeaderExample, ExampleHeader
    ]
})
export class ChatQuizModule { }
