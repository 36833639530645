import {Injectable} from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class RememberUserService {

    constructor() {}

    checkSaveUser(phoneControl, rememberControl) {
        if (localStorage.phone && this.isUserSaved()) {
            phoneControl.setValue(localStorage.phone);
            rememberControl.setValue(!!this.isUserSaved());
        }
    }

    setSaveUser(phone, country, remember) {
        localStorage.phone = remember ? phone : '';
        localStorage.country = remember ? country : '';
        localStorage.remember_user = remember;
    }

    isUserSaved() {
        return localStorage.remember_user;
    }
}
