import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PartnerService} from "../../service/partner.service";

@Component({
    selector: 'app-logo-bg',
    templateUrl: './logo-bg.component.html',
    styleUrls: ['./logo-bg.component.scss']
})
export class LogoBgComponent implements OnInit {
    partnerLogo = null;
    constructor(private partnerService: PartnerService) {
        this.partnerLogo = this.partnerService.getLogo();
    }


    ngOnInit() {}
}
