import {Component, OnInit, HostListener, ElementRef, ViewChild,} from '@angular/core';
import {routerTransition, slideToLeft, slideToRight} from "../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {PAYMENT_TYPES, PAYMENT_TYPES_ICONS} from "../../config/payment_types";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {PrizesService} from "../../service/prizes/prizes.service";
import {PrizesModel} from "../../models/services/prizes/prizes.model";
import {StylingService} from "../../service/styling.service";
import {PaginationDataModel, SetPaginationDataModel} from "../../models/scrollPagination/paginationData.model";
import {PaginationData} from "../../models/scrollPagination/paginationData";
import {PaginationScrollService} from "../../service/pageScroll/paginationScroll.service";
import {BREAKPOINTS} from "../../config/breakpoints";
import {RouterPaths} from "../../config/router.paths.model";
import {PRIZES_METHODS} from "./prizes.config";
import {PROJECT_MESSAGES} from "../../config/project";
import {formatCurrency, formatNumber} from "@angular/common";
import {CRYPTO_AMOUNT_MASK} from "../../config/crypto_settings";
import {PrizesMethodModel} from "../../models/prizes/prizesMethod.model";
import {GamesProviderService} from "../../service/games/gamesProvider.service";
import {SettingsService} from "../../service/settings/settings.service";
import {PopupConfirmInfoModel} from "../../shared/popup-prizes-confirm/popupConfirmInfo.model";
import {GAME_PROVIDER_ERRORS_CODE} from "../../service/games/gameProviderErrorsCode.config";

@Component({
    selector: 'app-prizes',
    templateUrl: './prizes.component.html',
    styleUrls: ['./prizes.component.scss'],
    animations: [window.innerWidth < 600 ? slideToRight : slideToLeft],
    host: window.innerWidth < 600 ? {'[@slideToRight]': ''} : {'[@slideToLeft]': ''}
})
export class PrizesComponent implements OnInit {
    dropdownClosed = true;

    prizesInfo: PrizesModel;

    PAYMENT_TYPES = PAYMENT_TYPES;
    PAYMENT_TYPES_ICONS = PAYMENT_TYPES_ICONS;
    ROUTER_PATHS = RouterPaths;

    menu = false;
    availableBalance = 0;

    prevChildTopOffset = 0;
    methodUnavailableMsg;
    availableMethods: Array<PrizesMethodModel> = [];
    prizesData: PaginationDataModel = new PaginationData('prizes', '.js-prizes-item', '.js-prizes-parent', 'credit');

    PRIZES_METHODS = PRIZES_METHODS;
    workingTimeMessage = 'Redemption Operating hours are Monday thru Friday from 9am to 5pm EST.';

    @ViewChild('userTel', {static: false}) userTel: ElementRef;

    @ViewChild('dropBody', {static: false}) dropBody: ElementRef;
    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;

    // FORM
    form: FormGroup;

    selectedMethod;

    games = [];
    cashappVerified = {
        handle: '',
        status: false
    };
    paypalVerified = {
        handle: '',
        status: false
    };

    activeProvider;
    gameVendorName = this.gamesProviderService.getActiveProviderName();

    // cryptoData = {
    //     items: [],
    //     loading: false
    // };

    popupFormType;
    popupFormData;

    popupConfirmInfo: PopupConfirmInfoModel = {show: false, totalDebit: 0};

    dailyInfo = {
        list: [],
        total: 0
    };
    constructor(private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private eRef: ElementRef,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private stylingService: StylingService,
                private paginationScrollService: PaginationScrollService,
                private gamesProviderService: GamesProviderService,
                private settingsService: SettingsService,
                private prizesService: PrizesService) {
    }


    ngOnInit() {
        this.setForms();
        this.getPrizesInfo();
    }

    setForms() {
        this.form = this.formBuilder.group({
            method: [null, Validators.required],
            amount: [null, Validators.required]
        });
    }

    getPrizesInfo(noGameChange = false){
        this.prizesService.getPrizesInfo(1).subscribe(data => {
            try {
                this.setPrizesInfo(data, noGameChange)
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setGamesProviders(providers, noGameChange: boolean) {
        this.games = providers;
        this.gamesProviderService.setProviders(this.games);

        if (!noGameChange) {
            this.activeProvider = this.gamesProviderService.getActiveProvider(this.games);
            this.gameVendorName = this.gamesProviderService.getActiveProviderName();

            if(this.activeProvider === undefined){
                if(providers[0]){
                    this.onProviderChange(providers[0]);
                }
            }else{
                this.selectedMethod = this.activeProvider;
                this.form.controls.method.setValue(this.selectedMethod);
            }
        }
    }

    setPrizesInfo(data: PrizesModel, noGameChange: boolean) {
        this.prizesInfo = data;
        this.availableBalance = this.prizesInfo.totalDebit;

        if (data.games.length) {
            this.setGamesProviders(data.games, noGameChange);

        }

        this.availableMethods = data.methods;
        // this.availableMethods = [{shortName: 'crypto', display: 1, disable: 1}, {shortName: 'mailCheck', display: 0, disable: 1}, {shortName: 'paypal', display: 1, disable: 0}, {shortName: 'venmo', display: 1, disable: 0}, {shortName: 'cashapp', display: 1, disable: 0}];
        this.methodUnavailableMsg = data.methodUnavailableMsg;
        this.setCashAppTagStatus(data);
        this.setPaypalStatus(data);
        this.setDalyList(data);
        const prizesDataSettings: SetPaginationDataModel = {
            itemsList: data.credit,
            scrollLimit: data.limit,
            total: data.total
        };

        this.initScrolledData(this.prizesData, prizesDataSettings);
    }

    setCashAppTagStatus(data) {
        // data.cashappTag = '$eboxjen';
        // data.cashappTagStatus = true;

        this.cashappVerified.status = data.cashappTagStatus;
        this.cashappVerified.handle = data.cashappTag;
    }

    setPaypalStatus(data) {
        // data.paypalEmail = 'test@gmal.com';
        // data.paypalEmailStatus = true;

        this.paypalVerified.status = data.paypalEmailStatus;
        this.paypalVerified.handle = data.paypalEmail;
    }

    setDalyList(data) {
        // data.dailyList = [{name: "Mascot", amount: "20.00"}, {name: "Golden Dragon", amount: "1.00"}];
        // data.dailyTotal = 0;

        this.dailyInfo = {
            list: data.dailyList,
            total: data.dailyTotal
        }
    }

    initScrolledData(paginationDataName: PaginationDataModel, settings: SetPaginationDataModel) {
        paginationDataName = this.paginationScrollService.initScrolledData(paginationDataName, settings);
    }


    getPaymentIcon(id){
        const type = Object.keys(this.PAYMENT_TYPES).find(key => this.PAYMENT_TYPES[key] === id);
        let icon;

        if (type) {
            icon = this.PAYMENT_TYPES_ICONS[type];
        }
        return icon;
    }

    getDate(date) {
        if(date == null){
           return '';
        }

        return date.replace(/\s/g, 'T');
    }

    toggleDropdown() {
        this.dropdownClosed = !this.dropdownClosed;

        // SCROLL TO END OF PAGE
        if (!this.dropdownClosed && window.innerWidth < BREAKPOINTS.mobile_max) {

            setTimeout(() => {
                const pageScroll = (<HTMLElement>document.querySelectorAll('.page__inner')[0]).scrollHeight - (<HTMLElement>document.querySelectorAll('.page__inner')[0]).offsetHeight;

                (<HTMLElement>document.querySelectorAll('.page__inner')[0]).scrollIntoView({behavior: 'smooth'});
                (<HTMLElement>document.querySelectorAll('.page__inner')[0]).scroll({
                    top: pageScroll,
                    left: 0,
                    behavior: 'smooth'
                });
            }, 350)
        }
    }

    showWorkingTimeMessage(ev, method) {
        if (this.isUnavailableMethod(method)) {
            this.popupComponent.showPopupError({text: this.methodUnavailableMsg,confirmButtonText:'OK'});
            ev.preventDefault();
            return true;
        }
    }

    isUnavailableMethod(method) {
        return this.checkMethodProperty(method, 'disable');
    }

    isDisplayMethod(method) {
        return this.checkMethodProperty(method, 'display');
    }

    checkMethodProperty(method, property) {
        if (this.availableMethods && this.availableMethods.length) {
            const checkMethod = this.availableMethods.find(methodItem => methodItem.shortName === method);
            if (checkMethod) {
                return checkMethod[property];
            }
        }
    }

    getActiveColor() {
        return this.stylingService.getPageColor();
    }

    ///////---SCROLLING PAGINATION FUNCTIONAL---///////

    // DETECTING SCROLL
    scroll(e, settings) {
        e.stopImmediatePropagation();
        e.stopPropagation();

        this.paginationScrollService.onScroll(e, settings, this.loadNewData.bind(this));
    }

    // REQUESTS ON ADDING NEW DATA
    loadNewData(page, step = 'end', settings):any {
        if (settings.name ===  'prizes') {
            this.prizesService.getPrizesInfo(page).subscribe(data => {
                this.paginationScrollService.dataAddingLogic(page, step, data, settings)
            }, () => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        }
    }

    onSubmitPayout(amount, handle) {
        const method = handle.short_name;
        const methodName = handle.name;

        if (!amount || !method) {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        } else {
            const text = `Send $${formatNumber(amount, 'en', CRYPTO_AMOUNT_MASK)}.00  to ${methodName}?`;
            this.popupComponent.showPopupConfirmCancel({text}, (status) => {
                if (status) {
                    this.sendPayout(method, amount);
                }
            });
        }
    }

    sendPayout(method, amount,) {
        this.prizesService.redeemViaGames(method, amount).subscribe(data => {
            this.displaySubmitPayoutResult(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        })
    }

    displaySubmitPayoutResult(data) {
        try {
            if(data.status){
                this.popupComponent.showPopupSuccess({text: data.message});
                this.resetForms();
                this.getPrizesInfo();
                this.closePopupConfirm();
            }else{
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    resetForms() {
        this.form.controls.amount.setValue(null);
    }

    onSubmitCashappForm(amount, handle) {
        if (amount && handle) {
            this.popupComponent.showPopupConfirmCancel({text: `You are about to redeem ${formatCurrency(amount, 'en','$')} to your CashApp tag ${handle}. Please Confirm`}, (status) => {
                if (status) {
                    this.prizesService.redeemViaCashApp(amount, handle).subscribe(data => {
                        this.displaySubmitPayoutResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }
            });
        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    getInputValue(form, field) {
        if (form.controls[field]) {
            return form.controls[field].value;
        }
    }

    showPopupConfirm(type) {
        document.body.classList.add('hide-menu');
        const handle = type === PRIZES_METHODS.games ? this.form.controls.method.value.name : type === PRIZES_METHODS.paypal ? this.paypalVerified.handle : this.cashappVerified.handle;
        const cashappVerified = type === PRIZES_METHODS.cashapp ? this.cashappVerified.status : null;
        const providers = this.games.filter(game => game !== this.activeProvider);
        this.popupConfirmInfo = {
            show: true,
            totalDebit: this.prizesInfo.totalDebit,
            handle: handle,
            type: type,
            providers: providers,
            cashappVerified: cashappVerified,
            paypalAddress: this.paypalVerified.status
        };
    }

    submitPopupConfirm(info) {
        if (info.type === PRIZES_METHODS.games) {
            this.onSubmitPayout(info.amount, this.popupConfirmInfo.handle);
        } else if (info.type === PRIZES_METHODS.paypal) {
            this.onSubmitPaypalForm(info.amount, this.popupConfirmInfo.handle);
        } else if (info.type === PRIZES_METHODS.cashapp) {
            this.onSubmitCashappForm(info.amount, this.popupConfirmInfo.handle);
        }
    }

    closePopupConfirm() {
        document.body.classList.remove('hide-menu');
        this.popupConfirmInfo = {
            show: false,
            totalDebit: this.prizesInfo.totalDebit
        };
    }

    changeHandlePopupConfirm(info) {
        this.popupConfirmInfo.handle = info.handle;
        if (info.type === PRIZES_METHODS.cashapp) {
            this.cashappVerified.status = true;
            this.cashappVerified.handle = info.handle;
        } else if (info.type === PRIZES_METHODS.paypal) {
            this.paypalVerified.status = true;
            this.paypalVerified.handle = info.handle;
        }

    }

    onSubmitPaypalForm(amount, handle) {
        if (amount && handle) {
            this.popupComponent.showPopupConfirmCancel({text: `You are about to redeem ${formatCurrency(amount, 'en','$')} to your PayPal account ${handle}. Please Confirm`}, (status) => {
                if (status) {
                    this.prizesService.paypalCreatePayout(null, handle, amount).subscribe(data => {
                        this.displaySubmitPayoutResult(data);
                    }, (error) => {
                        this.loaderService.hideAndShowTryAgain();
                    }, () => {
                        this.loaderService.hide();
                    });
                }
            });

        } else {
            this.popupComponent.showPopupError({text: 'Please fill all form fields'});
        }
    }

    onProviderChange(game) {
        this.loaderService.show();
        this.gamesProviderService.onGameChosen(game, this.checkSubmitGame.bind(this));
    }

    checkSubmitGame(data, gameId) {
        try {
            if(data.status === true){
                if (data.code) {
                    if (data.code === GAME_PROVIDER_ERRORS_CODE.gameDisabled) {
                        setTimeout(() => {
                            this.getPrizesInfo(true);
                        });
                    }
                } else {
                    setTimeout(() => {
                        this.getPrizesInfo();
                    });
                }
            } else if (data.status === false) {
                this.popupComponent.showPopupError({text:data.message});
            } else {
                this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }
}
