import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-rotate-popup',
    templateUrl: './rotate-popup.component.html',
    styleUrls: ['./rotate-popup.component.scss']
})
export class RotatePopupComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {}
}
