import {
    Component,
    ElementRef,
    OnInit,
    QueryList,
    ViewChildren, HostListener
} from '@angular/core';
import {FormArray, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthenticationService} from "../../modules/auth/_services/authentication.service";
import {Router} from "@angular/router";
import {PopupComponent} from "../../shared/popup/popup.component";
import {LoaderService} from "../../service/loader.service";
import {PHONE_MASK} from "../../config/country_phone_mask";
import {AppPromptService} from "../../service/app/appPrompt.service";
import {RouterPaths} from "../../config/router.paths.model";
import {PartnerService} from "../../service/partner.service";
import {RememberUserService} from "../../service/rememberUser.service";
import {GamesProviderService} from "../../service/games/gamesProvider.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss',
        '../../../../node_modules/simple-keyboard/build/css/index.css'],
})
export class LoginComponent implements OnInit {
    pinForm: FormGroup;
    phoneForm: FormGroup;

    phoneFormActive = true;

    phoneLastFour = ' ';
    numOfDigits = 4;

    phoneMask;
    PHONE_MASK_CONFIG = PHONE_MASK;

    // for INSTALL BUTTON
    deferredPrompt: any;
    IOSInstallBtn = false;
    ios_popup_text;
    androidInstallBtn = false;

    @ViewChildren('inputs') inputs: QueryList<ElementRef>;

    @HostListener('window:beforeinstallprompt', ['$event'])
    onbeforeinstallprompt(e) {
        e.preventDefault();
        // store install avaliable event
        this.deferredPrompt = e;

        // show button with display:block;
        this.androidInstallBtn = true;

        this.appPromptService.deferredPrompt = this.deferredPrompt;
    }

    constructor(
        private formBuilder: FormBuilder,
        private authenticationService: AuthenticationService,
        private loaderService: LoaderService,
        private router: Router,
        private appPromptService: AppPromptService,
        private partnerService: PartnerService,
        private gamesProviderService: GamesProviderService,
        private rememberUserService: RememberUserService,
        private popupComponent: PopupComponent) {

        this.formsInit();

        this.resetLogoutSettings();

        this.deferredPrompt = this.appPromptService.deferredPrompt;
    }

    ngOnInit() {
        this.setSavedUserInfo();
    }

    resetLogoutSettings() {
        this.authenticationService.logout();
        this.popupComponent.closePopup();
        document.body.classList.remove('hide-menu');
    }

    phoneFormInit(){
        this.phoneForm = this.formBuilder.group({
            phone: [null, Validators.required],
            remember_user: [false],
            country: ['us'],
        });
    }

    pinFormInit() {
        this.pinForm = this.formBuilder.group({
            digits: this.formBuilder.array([])
        });
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
    }

    formsInit() {
        this.phoneFormInit();
        this.pinFormInit();
    }

    setSavedUserInfo() {
        this.rememberUserService.checkSaveUser(this.phoneForm.controls.phone, this.phoneForm.controls.remember_user);
        this.setPhoneMask(this.phoneForm.controls.country.value);
    }

    resendSms() {
        this.onPhoneSubmit();
    }

    onPhoneSubmit() {
        if (this.phoneForm.invalid) {
            return;
        }

        const phone = this.phoneForm.controls.phone.value;
        const country = this.phoneForm.controls.country.value;
        const remember = this.phoneForm.controls.remember_user.value;

        this.authenticationService.checkPhoneAndSendPin(phone, country).subscribe(data => {
            try {
                if (data.status) {
                    this.rememberUserService.setSaveUser(phone, country, remember);

                    this.phoneLastFour = phone.substr(-4);
                    this.phoneFormActive = false;

                    setTimeout(() => {
                        this.inputs.toArray()[0].nativeElement.focus();
                    }, 0);
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    onSubmit() {
        if (this.pinForm.invalid) {
            return;
        }

        const country = this.phoneForm.controls.country.value;
        const phone = this.phoneForm.controls.phone.value;
        let pin = this.pinForm.controls.digits.value;
        pin = pin.join('');

        if (pin.length !== 4) {
            this.popupComponent.showPopupError({text: 'The Code should consist of 4 numerical characters'});
            return
        }

        if (phone && pin && (pin.length === 4) && country) {
            this.authenticationService.login(phone, pin, country).subscribe(data => {
                this.checkLoginSubmit(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    checkLoginSubmit(data) {
        try {
            if (data.status) {
                this.gamesProviderService.loginUser(data.userPointId, data.gameVendorShortName, data.groupId);
                this.router.navigate([RouterPaths.rewards.main]);
            } else {
                this.popupComponent.showPopupError({text: data.message});
            }
        } catch (e) {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    isPhoneClassActive() {
        if (this.phoneFormActive) {
            return true;
        }

        return false;
    }

    isPinClassActive() {
        if (!this.phoneFormActive) {
            return true;
        }

        return false;
    }

    check(index, field, event) {
        if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
            event.preventDefault();
        }

        if (field.value && event.key !== 'Backspace') {
            if (index < this.inputs.toArray().length - 1) {
                this.inputs.toArray()[index + 1].nativeElement.focus();
            }
        } else if (event.key === 'Backspace') {
            if (index > 0) {
                field.setValue(null)
                this.inputs.toArray()[index - 1].nativeElement.focus();
            } else {
                console.log('first field');
            }
        }
    }

    onRememberUser(e) {
        const checked = e.target.checked;
        this.phoneForm.controls.remember_user.setValue(!!checked);
    }

    setPhoneMask(country) {
        this.phoneMask = this.PHONE_MASK_CONFIG[country];
    }

    onCountryChange(e) {
        const value = e.target.value;
        this.setPhoneMask(value);
    }

    getPartnerName() {
        return 'NFT';
    }

    getPartnerLogo() {
        return this.partnerService.getLogo();
    }

    // Detects if device is in standalone mode
    isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator['standalone']);

    isIos() {
        let userAgent = window.navigator.userAgent.toLowerCase();
        return /iphone|ipad|ipod/.test(userAgent);
    }

    install() {
        if (this.isIos() && !this.isInStandaloneMode()) {
            let userAgent = window.navigator.userAgent.toLowerCase();
            this.IOSInstallBtn = true;
            let device = 'device';

            if (/iphone/.test(userAgent)) {
                device = 'IPhone';
            } else if (/ipad/.test(userAgent)) {
                device = 'IPad';
            }
            this.ios_popup_text = `Install this webapp on your ${device}: `;
        } else {
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then(choiceResult => {
                if (choiceResult.outcome === 'accepted') {
                    // user accept the prompt
                } else {
                    console.log('User dismissed the prompt');
                }
                //this.deferredPrompt = null;
            });
        }
    }

    goBackToLogin() {
        this.phoneFormActive = true;
        this.resetPinInput();
    }

    resetPinInput() {
        (this.pinForm.get('digits') as FormArray).clear();
        for (let i = 0; i < this.numOfDigits; i++) {
            (this.pinForm.get('digits') as FormArray).push(this.formBuilder.control(null, Validators.required))
        }
    }

    logout() {
        console.log("login logout()");

        this.authenticationService.logout();
        this.goBackToLogin();
        this.popupComponent.closePopup();

        if (this.phoneForm) {
            this.phoneForm.controls.phone.setValue(null);
        }else{
            this.phoneFormInit();
        }

        this.resetPinInput();
    }
}
