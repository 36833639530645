import {DOMAIN_API} from "./local_settings";
import {
    DOMAIN_DEMO_NAME, DOMAIN_DEMO_API,DOMAIN_INTERIM_NAME, DOMAIN_INTERIM_API,
    DOMAIN_INTERIM_NAME_MASCOT, DOMAIN_INTERIM_NAME_TERMINAL,
    DOMAIN_INTERIM_NAME_MASCOT1
} from "./demo_settings";
import {AuthConfig} from '../modules/auth/_services/auth-config';
import {NewUser} from "../models/new-user";
import {ExistingUser} from "../models/existing-user";
import {Prizes} from "../models/prizes";
import {UserStatus} from "../models/user-status";
import {UserCard} from "../models/user-card";
import {Paypal} from "../models/paypal";
import {Addresses} from "../models/addresses";
import {Funds} from "../models/funds";
import {UserContact} from "../models/user-contact";
import {AppVersion} from "../models/appVersion";
import {UserBalance} from "../models/user-balance";
import {Lending} from "../models/lending";
import {Games} from "../models/games";
import {GamesProvider} from "../models/gamesProvider";
import {Mascot} from "../models/mascot";
import {ScratchOffModel} from "../models/games/scratch-off.model";
import {SpinnerGameModel} from "../models/games/spinner-game.model";
import {NFTRequestsModel} from "../models/nft/nftRequests";
import {CommissionRequestModel} from "../models/commission/commissionRequestModel";
import {SupportModel} from "../models/support/support.model";
import {ReportsModel} from "../models/reports/reportsModel";
import {environment} from "../../environments/environment";

let domainApiUrl = DOMAIN_API;
if(window.location.hostname == DOMAIN_INTERIM_NAME || window.location.hostname == DOMAIN_INTERIM_NAME_MASCOT ||
    window.location.hostname == DOMAIN_INTERIM_NAME_TERMINAL || window.location.hostname == DOMAIN_INTERIM_NAME_MASCOT1){
    domainApiUrl = DOMAIN_INTERIM_API;
}

let API = domainApiUrl + '/client';
let API_KING = domainApiUrl + '/king';
let TERMINAL = domainApiUrl + '/terminal';
let GAMES_LINK = domainApiUrl + '/games';
if(window.location.hostname == DOMAIN_DEMO_NAME){
    API = DOMAIN_DEMO_API + '/client';
    API_KING = DOMAIN_DEMO_API + '/king';
    TERMINAL = DOMAIN_DEMO_API + '/terminal';
    GAMES_LINK = DOMAIN_DEMO_API + '/games';
}

const AUTH = API + '/users/session';
const USERS = API + '/users';
const GAMES = API + '/games';
const GAMES_MASCOT = GAMES + '/mascot';
const GAMES_SCRATCH_OFF = GAMES_LINK + '/scratchoff';
const GAMES_WHEEL = GAMES_LINK + '/wheel';
const GAMES_EBOX = GAMES_LINK + '/ebox';
const GAMES_YETI = GAMES_EBOX + '/yeti';

const NFT = API + '/nft';

export const AUTH_CONFIG: AuthConfig = {
    checkUser: USERS + '/register/check',
    register: USERS + '/register',
    login: API_KING + '/users/login/pin',
    logout: AUTH + '/logout',
    refresh: AUTH + '/refresh',
    current: AUTH + '/details',
    sendPin: USERS + '/pin/request',
    checkPhoneAndSendPin: API_KING + '/users/login/phone',
    newCustomer: USERS + '/phone/newCustomer',
    newCustomerVirtual: USERS + '/phone/newCustomerVirtual',
    findByAccountId: USERS + '/user/findByAccountId',
    registerTerminal: API + '/terminal/register',
};
if (environment.frontEndTest) {
    // AUTH_CONFIG.checkPhoneAndSendPin = API + '/jsons/find/file?name=success';
    // AUTH_CONFIG.login = API + '/jsons/find/file?name=kingLogin';
    // AUTH_CONFIG.login = AUTH + '/login'
}

export const PRIZES_CONFIG: Prizes = {
    data: API + '/balance/history',
    paypalEmails: API+'/paypal/emails',
    checkAddresses: API+'/check/address',
    mailCheckCreatePayout: API+'/balance/payout/check',
    paypalCreatePayout: API+'/balance/payout/paypal',
    checkAddAddress: API+'/check/address/add',
    paypalAddEmail: API+'/paypal/emails/add',

    usdcBalance: API+'/balance/prizes/available',
    usdcCreatePayout: API+'/transfers/games/usdc',

    cryptoList: API + '/payout/crypto/currencies',

    redeemViaCashApp: API + '/balance/payout/cashapp',
    redeemViaVenmo: API + '/balance/payout/shortname',
    cashAppBalance: API + '/cashapp/payout',
    venmoBalance: API + '/venmo/payout',

    gamesBalance: API + '/games/payout',
    redeemViaGames: API + '/games/payout/create',
};

export const USER_CONTACT_CONFIG: UserContact = {
    info: API+'/users/user/detail',
    save: API+'/users/contact/save',
    create: API+'/users/contact/create'
}

export const USER_CONFIG: UserStatus = {
    status: API+'/users/user/detail',
    freeze: API+'/users/user/freeze',
    activate: API+'/users/user/activate',
    delete: API+'/users/user/delete',
    settings: API+ '/users/settings'
}
if (environment.frontEndTest) {
    USER_CONFIG.settings = API + '/jsons/find/file?name=settings'
}

export const USER_CARD_CONFIG: UserCard = {
    cards: API+'/cards/list',
    card: API+'/cards/card',
    delete: API+'/cards/card/delete',
    verify: API+'/cards/card/verify',
    add: API+'/cards/card/add',
    addCashAppCard: API+'/cashapp/card/add',
    addPrepaidCard: API+'/prepaid/card/add',
    save: API+'/cards/card/save',
    checkVerify: API+'/cards/card/checkVerify',
    resendCode: API+'/cards/card/sendCode',
    states: API + '/cards/card/notification'
}

export const PAYPAL_CONFIG: Paypal = {
    accounts: API+'/paypal/emails/lists',
    delete: API+'/paypal/emails/delete',
    add: API+'/paypal/emails/add',
    paymentSuccess: API+'/payments/paypal/paymentSuccess',
    paymentUnsuccess: API+'/payments/paypal/paymentFail',
    edit: API+'/paypal/emails/edit',
    find: API+'/paypal/emails/find',
}

export const ADDRESSES_CONFIG: Addresses = {
    addresses: API+'/check/address',
    list: API+'/check/address/lists',
    delete: API+'/check/address/delete',
    add: API+'/check/address/add',
    edit: API+'/check/address/edit',
    find: API+'/check/address/find',
}

export const FUNDS_CONFIG: Funds = {
    payments: API_KING+'/payments/method/available',
    add: API+'/payments/create',
    getDepositInfo: API_KING + '/payments/nft/card',
    levels: API_KING + '/payments/deposit/levels'
}
if (environment.frontEndTest) {
    FUNDS_CONFIG.getDepositInfo = API + '/jsons/find/file?name=kingGetDepositInfo';
    FUNDS_CONFIG.payments = API + '/jsons/find/file?name=kingAddFundsInfo';
    FUNDS_CONFIG.levels = API + '/jsons/find/file?name=kingAddFundsLevels';
}

export const NEW_USER_CONFIG: NewUser = {
    save: API + '/users/user/createCustomer',
};

export const EXISTING_USER_CONFIG: ExistingUser = {
    getUsers: API + '/users/users',
    userInfo: API + '/users/user/paymentHistory',
    sell: API + '/users/user/deposit',
    freeze: API + '/users/user/freeze',
    delete: API + '/users/user/delete',
};

export const APP_VERSION_CONFIG: AppVersion = {
    version: API_KING+'/app/version',
};

export const USER_BALANCE_CONFIG: UserBalance = {
    balance: API_KING + '/games/providers',
}

export const LENDING_CONFIG: Lending = {
    info: API_KING+'/nft/list',
    usdcList: API+'/lending/usdc',
    usdList: API+'/lending/usd',
    cryptoList: API+'/lending/crypto',
    cryptoCart: API+'/lending/crypto/cart',
    cryptoSpend: API+'/lending/crypto/spend',
    tradingList: API + '/lending/trading/available',
    tradingCart: API + '/lending/trading/trade',
    tradingGetInfo: API + '/lending/trading/cart',
    tradingConfirm: API + '/lending/trading/exchange',

    investmentList: API + '/lending/trading/investment',
}
if (environment.frontEndTest) {
    LENDING_CONFIG.info = API + '/jsons/find/file?name=kingInvestmentsInfo'
}
export const GAMES_CONFIG: Games = {
    getGameUrl: API + '/games/game',
    buyGame: API + '/jsons/find/file?name=success',
}

export const GAMES_PROVIDER_CONFIG: GamesProvider = {
    chooseGame: API_KING + '/games/provider/connect',
    gamesList: API + '/games/providers/lists',
    connectRiver: API + '/games/provider/connectRiver'
}

export const MASCOT_CONFIG: Mascot = {
    getIcons: GAMES_MASCOT + '/icons',
    getBalance: GAMES_LINK + '/mascot/balance',
    updatesLast: GAMES_LINK + '/mascot/updates/last'
};


export const SCRATCH_OFF_CONFIG: ScratchOffModel = {
    createRound:  GAMES_SCRATCH_OFF + '/rounds/create',
    chooseItem:  GAMES_SCRATCH_OFF + '/rounds/play',

    paymentInfo: API + '/jsons/find/file?name=mascotGameFindPrizeTransaction',
    buyGame: API + '/jsons/find/file?name=success'
};

export const SPINNER_GAME_CONFIG: SpinnerGameModel = {
    init: GAMES_WHEEL + '/play/free'
};


export const CRYPTO_TRANSACTIONS_CONFIG = {
    redeem: API + '/payout/crypto/redeem',
    founds:API+'/payments/crypto',
    buyCrypto: API + '/cryptocurrency/trade/buy',
    sellCrypto: API + '/cryptocurrency/trade/sell',
    investmentSellCrypto: API + '/cryptocurrency/investment/sell',
    investmentBuyCrypto: API + '/cryptocurrency/investment/buy'
};

export const NFT_CONFIG: NFTRequestsModel = {
    generateWallet: NFT + '/wallets/generate',
    mintNFT: NFT + '/library/mint',
};

export const COMMISSION_CONFIG: CommissionRequestModel = {
    data: API + '/resellers/reseller',
    refer: API + '/resellers/reseller/refer',
    payout: API + '/resellers/reseller/payout'
};

export const SUPPORT_CONFIG: SupportModel = {
    request: API + '/supports/support'
};

export const REPORTS_CONFIG: ReportsModel = {
    weekly: API + '/mascot/reports'
};

export const SETTINGS_CONFIG = {
    textMe: API + '/payments/cashapp/sendAddress',
    verifyCashapp: API + '/payments/cashapp/add',
    verifyPaypal: API + '/users/paypal/add'
}

export const TRANSACTIONS_CONFIG = {
    confirmation: API + '/payments/threedsecure',
}
