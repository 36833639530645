<div class="page page--quaternary" [class.page--blured]="menu">
    <div class="page__inner">
        <app-logo-bg></app-logo-bg>

        <h1 class="page__title">SETTINGS</h1>

        <div class="page__scrolled">
            <div class="page__head">
                <div class="page__left">
                    <div *ngIf="userStatus === USER_STATUS.active" class="page__status gl-title">ACCOUNT ACTIVE</div>
                    <div *ngIf="userStatus !== USER_STATUS.active" class="page__status gl-title">ACCOUNT FROZEN</div>
                </div>
                <div class="page__right">
                    <button (click)="menu = true" class="page__options js-open-menu">
                        <svg viewBox="0 0 29 7" xmlns="http://www.w3.org/2000/svg">
                            <use xlink:href="../../../../assets/images/sprite.svg#menu-dots"></use>
                        </svg>
                    </button>
                </div>
                <div *ngIf="userStatus === USER_STATUS.frozen" class="page__status-info">User Frozen</div>
                <div *ngIf="userStatus === USER_STATUS.blocked" class="page__status-info">Admin Frozen</div>

                <div class="page__status-info page__status-info--left gl-subtitle">You are depositing for {{gameVendorName}}</div>
            </div>

            <div class="page__body">

                <app-group-bordered>
                    <h2>SUBJECT TO LIMITATIONS</h2>
                    <h4>{{subjectLimitationTxt}}</h4>
                    <div class="page__form group-bordered-inner">
                        <div class="info-block">
                            <h2></h2>
                        </div>
                        <div class="navigation">
                            <a id="{{item.id}}"
                               *ngFor="let item of navigation"
                               [hidden]="item.hidden"
                               (click)="isAvailble(item.id,item.link)"
                               class="navigation__button button snake-btn is-notouched {{background(item.id)}}"
                               style="cursor: pointer;">
                                {{item.title}}
                                <svg><rect></rect></svg>
                            </a>
                        </div>
                    </div>
                </app-group-bordered>

                <div class="navigation">
                    <div class="navigation__col">
                        <a id="funds_btn" [routerLink]="getLinkToFunds()" class="navigation__button navigation__button--rounded is-notouched">
                            <span class="navigation__button-text">ADD <br>FUNDS</span>
                            <svg width="86" height="86" viewBox="0 0 86 86" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M85 43C85 19.804 66.196 1 43 1M1 43C1 66.196 19.804 85 43 85C54.6712 85 65.2305 80.2394 72.8421 72.5542" stroke="#DCBCFF" stroke-width="2"/>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="addition-menu addition-menu--top" [class.is-active]="menu">
    <ul class="addition-menu__list">
        <li class="addition-menu__item">
            <button class="js-delete-account" (click)="askDeleteUser()">Delete</button>
        </li>
    </ul>
    <button (click)="menu = false" class="addition-menu__btn js-close-menu">Cancel</button>
</div>
