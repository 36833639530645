import {Component, OnInit, ElementRef, ViewChild, HostBinding,} from '@angular/core';
import {slideToLeft} from "../../../animations/router.animations";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {PopupComponent} from "../../../shared/popup/popup.component";
import {LoaderService} from "../../../service/loader.service";
import {FundsService} from "../../../service/settings/funds.service";
import {PROJECT_CONFIG, PROJECT_MESSAGES, PROVIDERS_GAME_STATUS} from "../../../config/project";
import {FundsResultItem} from "../../../models/services/funds/funds.model";
import {RouterPaths} from "../../../config/router.paths.model";
import {ScrollToService} from "../../../service/scrollTo.service";
import {NFT_POPUP_TYPE} from "../../investments/investments-nft-popup/investments-nft-popup.config";
import {environment} from "../../../../environments/environment";
import {NftTableItemsModel} from "../../../models/nft/nftTableItems.model";
import {NftLevelsService} from "../../../service/nftLevels.service";

@Component({
    selector: 'app-funds',
    templateUrl: './funds.component.html',
    styleUrls: ['./funds.component.scss'],
    animations: [slideToLeft],
    host: {'[@slideToLeft]': '',
        '[class.is-menu]': 'frame',}
})
export class FundsComponent implements OnInit {
    sellForm: FormGroup;
    methods: Array<FundsResultItem> = [];
    frame = false;
    frameUrl;
    activeMethod;

    sweepsStatus = {
        status: null,
        error: ''
    };

    showNFTPopup = false;
    NFTPopupData;

    purchaseAmount = 0;

    @ViewChild('iframe', {static: false}) iframe: ElementRef;

    @HostBinding('class') isAppClass = '';
    @ViewChild('scrolledEl', {static: false}) scrolledEl: ElementRef;

    iframePopup = {
        show: false,
        url: null
    }

    nftItems: Array<NftTableItemsModel> = [];
    depositSteps = [];

    constructor(private formBuilder: FormBuilder,
                private loaderService: LoaderService,
                private popupComponent: PopupComponent,
                private fundsService: FundsService,
                private nftLevelsService: NftLevelsService,
                private scrollToService: ScrollToService) {
    }

    ngOnInit() {
        this.getPaymentMethods();

        this.sellForm = this.formBuilder.group({
            amount: [null],
            method: [null, Validators.required],
        });
    }

    showIframePopup(url) {
        document.body.classList.add('hide-menu');
        this.iframePopup = {
            show: true,
            url: url
        }
    }

    hideIframePopup(result) {
        document.body.classList.remove('hide-menu');
        this.iframePopup = {
            show: false,
            url: null
        }
        if (result) {
            if (result === 'error') {
                this.loaderService.hideAndShowTryAgain();
            } else {
                if (result.status) {
                    this.popupComponent.showPopupSuccess({text: result.message});
                    this.clearPurchaseAmount();
                } else {
                    this.popupComponent.showPopupError({text: result.message, confirmButtonText: 'OK'})
                }
            }
        }
    }

    getPaymentMethods(){
        this.fundsService.getPayments().subscribe(data => {
            this.setMethods(data);
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }

    setMethods(data) {
        try {

            this.sweepsStatus.status = data.game_status;
            this.sweepsStatus.error = data.game_error_message;

            if (data.status === true) {
                this.methods = data.result;
                this.depositSteps = data.depositSteps;
                this.setCheckedMethod();
            } else if (data.status === undefined) {
              this.loaderService.hideAndShowTryAgain();
            }
        } catch (e) {

        }
    }

    setNftItems(levels) {
        this.nftItems = this.nftLevelsService.groupLevels(levels);
    }

    setCheckedMethod(method?) {
        if (!this.methods.length) {
            this.sellForm.controls.method.setValue(null);
            this.activeMethod = null;
        } else if (method && this.methods.find(el => el === method)) {
            this.sellForm.controls.method.setValue(method);
            this.activeMethod = method;
        } else {
            let method = this.methods[this.methods.length-1];
            this.sellForm.controls.method.setValue(method);
            this.activeMethod = method;
        }
        if (this.methods && this.methods.length > 1) {
            this.scrollToLastMethod();
        }
    }

    getInputValue(field) {
        if (this.sellForm.controls[field]) {
            return this.sellForm.controls[field].value;
        }
    }

    onSubmit() {
        let method = this.getInputValue('method');

        if (this.sweepsStatus.status === PROVIDERS_GAME_STATUS.notAvailable) {
            this.popupComponent.showPopupError({text: this.sweepsStatus.error});
            return;
        }

        if (!this.methods.length) {
            this.popupComponent.showPopupError({text: 'Please enter your payment method'});
        } else {
            if (this.methods.length > 1 && method === null) {
                this.popupComponent.showPopupError({text: 'Please select the payment method to use'});
            } else {
                if (this.activeMethod.verify !== undefined && this.activeMethod.verify === 0) {
                   this.showNoVerifyErrorMessage();
                } else {
                    this.showConfirmMessage();
                }
            }
        }
    }


    showNoVerifyErrorMessage() {
        if (this.activeMethod && this.activeMethod.last_four) {
            const message = this.activeMethod.description ?
              `Please verify ${this.activeMethod.description} - ${this.activeMethod.last_four} first.` :
              `Please verify card ending in ${this.activeMethod.last_four}.`;

            this.popupComponent.showPopupError({text: message});
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.unknownError});
        }
        return;
    }

    showConfirmMessage() {
        const name = this.getPaymentName(this.activeMethod);
        const amount = this.purchaseAmount;

        if (name && amount) {
            this.fundsService.getDepositInfo(amount).subscribe(data => {
                try {
                    if (data.status) {
                        this.onShowNFTPopup(data);
                    } else {
                        this.popupComponent.showPopupError({
                            text: data.message,
                            confirmButtonText:'OK'
                        });
                    }
                } catch (e) {
                    this.loaderService.hideAndShowTryAgain();
                }
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });

        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.unknownError});
        }
    }

    getPaymentName(data){
        if(data && this.isCard(data.payment_method)){
            return data.description+' - '+data.last_four;
        }else if(data && this.isPaypal(data.payment_method)){
            return 'PayPal - '+data.email;
        }

        return '';
    }

    changeMethod(method){
        if (method) {
            this.activeMethod = method;
        }
    }

    submit() {
        let amount = this.purchaseAmount;
        const id = this.activeMethod ? this.activeMethod.id : undefined;
        let paymentMethod = this.activeMethod ? this.activeMethod.payment_method : undefined;

        if (id && paymentMethod) {
            this.fundsService.addFunds(id, paymentMethod, amount).subscribe(data => {
                this.checkSubmitResult(data);
            }, (error) => {
                this.loaderService.hideAndShowTryAgain();
            }, () => {
                this.loaderService.hide();
            });
        } else {
            this.popupComponent.showPopupError({text: PROJECT_MESSAGES.notAllFieldsAreFilled});
        }

    }

    checkSubmitResult(data) {
        let paymentMethod = this.activeMethod ? this.activeMethod.payment_method : undefined;
        // data.status = true;
        // data.threeDSecure = true;
        // data.threeDSecureUrl = 'http://192.168.0.102:4200/threedsecure?payment_intent=pi_3L15bHAe9lbG4VLq1TzNQdIC&payment_intent_client_secret=pi_3L15bHAe9lbG4VLq1TzNQdIC_secret_RzDMXw4dRTIFnLGPA56hsxQ3m&source_redirect_slug=test_YWNjdF8xQ3B6RzJBZTlsYkc0VkxxLF9MaVdlQ2NndjBmNHNHcXd5TTA5NkhZMHdaVXpIOVFK0100UZXgXXzS';
        // data.threeDSecureUrl = 'http://192.168.0.102:8888/ebox-client/threedsecure.html?payment_intent=pi_3L15bHAe9lbG4VLq1TzNQdIC&payment_intent_client_secret=pi_3L15bHAe9lbG4VLq1TzNQdIC_secret_RzDMXw4dRTIFnLGPA56hsxQ3m&source_redirect_slug=test_YWNjdF8xQ3B6RzJBZTlsYkc0VkxxLF9MaVdlQ2NndjBmNHNHcXd5TTA5NkhZMHdaVXpIOVFK0100UZXgXXzS';
        if(data.status === true){
            if (data.threeDSecure) {
                if (data.threeDSecureUrl) {
                    this.showIframePopup(data.threeDSecureUrl);
                } else {
                    this.loaderService.hideAndShowTryAgain();
                }
            } else {
                if (this.isCard(paymentMethod)){
                    this.clearPurchaseAmount();
                    this.popupComponent.showPopupSuccess({text:data.message});
                } else if(this.isPaypal(paymentMethod)){
                    this.clearPurchaseAmount();

                    var win = window.open(data.link, '_blank');
                    win.focus();
                }
            }
        } else if (data.status === false) {
            this.popupComponent.showPopupError({text:data.message});
        } else {
            this.loaderService.hideAndShowTryAgain();
        }
    }

    showFrame() {
        this.frame = true;
    }

    closeFrame() {
        this.frame = false;
    }

    isCard(method){
        if(PROJECT_CONFIG.paymentMethodCard == method){
            return true;
        }
        return false;
    }

    isPaypal(method){
        if(PROJECT_CONFIG.paymentMethodPaypal == method){
            return true;
        }
        return false;
    }

    getBackLink() {
        return RouterPaths.settings.home;
    }

    getPaymentMethod(method) {
        const el = new FundsResultItem(method);
        return el.payment_method;
    }

    getMethodId(method) {
        const el = new FundsResultItem(method);
        return el.id;
    }

    canSubmitSweeps() {
        return !+this.sellForm.controls.amount.value;
    }

    scrollToLastMethod() {
        this.scrollToService.scrollToEnd('.saved__list');
    }

    onShowNFTPopup(data) {
        const description = this.activeMethod.description;
        const number = this.activeMethod.last_four;

        data.cardInfo = {
            description,
            number
        }

        this.showNFTPopup = true;
        this.NFTPopupData = {
            type: NFT_POPUP_TYPE.funds,
            data: {
                element: data
            }
        }
    }

    onPopupNFTClose($event) {
        this.showNFTPopup = false;
        if ($event) {
            this.submit();
        }
    }

    increasePurchaseAmount(value) {
        this.purchaseAmount += value;
        this.getLevels();
    }

    clearPurchaseAmount() {
        this.purchaseAmount = 0;
        this.nftItems = [];
    }

    getLevels() {
        this.fundsService.getLevels(this.purchaseAmount).subscribe(data => {
            try {
                if (data.status) {
                    this.setNftItems(data.result);
                } else {
                    this.popupComponent.showPopupError({text: data.message});
                }
            } catch (e) {
                this.loaderService.hideAndShowTryAgain();
            }
        }, (error) => {
            this.loaderService.hideAndShowTryAgain();
        }, () => {
            this.loaderService.hide();
        });
    }
}

